<template>
  <div :id="divId"
       class="swiper-container mySwiper swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events"
       :class="{'banner-frame':(divId=='homeBanner'),'album-banner-frame':(divId=='albumIntroduceImages'||divId=='goodsBanner')}"
  >
    <div class="swiper-wrapper"
         aria-live="off"
         style="transition-duration: 0ms; transform: translate3d(0px, 0px, 0px);">
      <div class="swiper-slide swiper-slide-active" role="group"
           v-for="(el,index) in banners" :key="divId+'_banner'+el.id"
           :aria-label="(index+1)+ '/'+ banners.length">
        <div class="album-cover-img-box image-tblr-center"
             style="position:relative;"
             :style="{'backgroundImage':((el.thumb||el.url)?'url(\''+(el.thumb||el.url)+'\')':'unset'),
             'filter':((el.thumb||el.url)?'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\''+(el.thumb||el.url)+'\',sizingMethod=scale)':'unset'),
             '-ms-filter':((el.thumb||el.url)?'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\''+(el.thumb||el.url)+'\',sizingMethod=scale)':'unset')}"
             @click="tipBannerItem(el)"
        >
          <div class="album-limited-offer-icon" style="width:25%;"
               v-if="el.hasOwnProperty('time_limit_discount_status')&&el.time_limit_discount_status==1">
            <img :src="limitedOfferIcon" alt="限时优惠图标">
          </div>
          <!--<div class="album-limited-offer-info"
               v-if="el.hasOwnProperty('time_limit_discount_status')&&el.time_limit_discount_status==1&&el.hasOwnProperty('time_limit_discount_end_time')&&el.time_limit_discount_end_time">
            <span class="limited-offer-description-left"
                  style="font-size:12px;color:#ffffff;">
              优惠时间：
              <span>{{el.time_limit_discount_start_time.substring(0,16)||''}}</span>
              {{el.time_limit_discount_start_time&&el.time_limit_discount_start_time!=''?'至':''}}
              <span>{{el.time_limit_discount_end_time.substring(0,16)||''}}</span>
            </span>
          </div>-->
        </div>
      </div>
    </div>
    <div
        class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
          <span class="swiper-pagination-bullet"
                v-for="(el,index) in banners" :key="'pagination'+el.id"
                :class="{'swiper-pagination-bullet-active':(index==0)}"
                tabindex="0" role="button"
                :aria-label="'Go to slide'+ (index+1)">
          </span>
    </div>
    <span class="swiper-notification" aria-live="assertive"
          aria-atomic="true"></span>
  </div>
</template>

<script lang="ts">
  import {Options, Vue} from "vue-class-component";
  import {Prop, Watch} from "vue-property-decorator";

  @Options({})
  export default class BannerList extends Vue {
    @Prop(String) divId!: string;
    @Prop(Array) banners!: any[];
    @Prop(Object) params!: any;
    name: string = "banner-list";
    // limitedOfferIcon: string = './static/img/icon_limited_offer1.png';
    limitedOfferIcon: any = require('@/assets/img/icon_limited_offer1.png').default;

    created(): void {
    }

    @Watch('banners', {immediate: true, deep: true})
    onChangeBannerList(val: any, oldValue: any): void {
      if (this.banners.length > 1) {
        this.$nextTick((): void => {
          let options: any = {
            centeredSlides: true,
            speed: 500,
            preventLinksPropagation: false,   // 阻止点击事件冒泡
          };
          if (this.params.hasOwnProperty('isLoop') && this.params.isLoop) {
            Object.assign(options, {
              loop: true,
            });
          }
          if (this.params.hasOwnProperty('isAutoPlay') && this.params.isAutoPlay) {
            Object.assign(options, {
              autoplay: {
                disableOnInteraction: false,
                delay: 2500,
              },
              pagination: {
                el: ".swiper-pagination",
                clickable: true,
              },
            });
          }
          let swiper: any = new Swiper('#' + this.divId, options);

          // var swiper = new Swiper("#" + this.divId, {
          //   // spaceBetween: 30,
          //   centeredSlides: true,
          //   speed: 500,
          //   loop: true,
          //   autoplay: {
          //     disableOnInteraction: false,
          //     delay: 2500,
          //   },
          //   pagination: {
          //     el: ".swiper-pagination",
          //     clickable: true,
          //   },
          //   preventLinksPropagation: false,   // 阻止点击事件冒泡
          //   // navigation: {
          //   //   nextEl: ".swiper-button-next",
          //   //   prevEl: ".swiper-button-prev",
          //   // },
          // });
        });
      }
    }

    tipBannerItem(item: any): void {
      this.$emit('clickBannerItem', item);
    }
  }
</script>

<style scoped>

</style>

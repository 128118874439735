const date = {

  /**
   * Safari浏览器时间格式转换，yyyy-MM-dd hh:mm:ss 转为 yyyy/MM/dd hh:mm:ss
   * @param date yyyy-MM-dd hh:mm:ss
   * @returns {string} yyyy/MM/dd hh:mm:ss
   */
  timestampToSafari: (date: string): string => {
    date = date ? date : '';
    if (typeof date === 'string') {
      date = date.replace(/-/g, '/');
    }
    return date;
  },

  /**
   * 设置数字几位数显示；例console.log(fix(5, 2));输出结果为05
   * @param num
   * @param length
   * @returns {string}
   */
  fix: (num: any, length: number = 1): string => {
    return String(num).padStart(length, '0');
  },

  /**
   * 获取当前系统时间或传入时间戳，并按照标准格式返回
   * @param timestamp 时间戳或字符串格式的时间
   * @returns {string} format{yyyy-MM-dd HH:mm:ss}
   */
  getCurDatetime: function (timestamp?: any): string {
    timestamp = this.timestampToSafari(timestamp);
    const date: any = timestamp ? new Date(timestamp) : new Date();
    const year = this.fix(date.getFullYear(), 4);
    const month = this.fix(parseInt(date.getMonth() + 1), 2);
    const day = this.fix(date.getDate(), 2);
    const hours = this.fix(date.getHours(), 2);
    const minutes = this.fix(date.getMinutes(), 2);
    const seconds = this.fix(date.getSeconds(), 2);
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':'
        + seconds;
  },

  /**
   * 获取n天后的日期，并返回标准格式
   * @param _date 日期
   * @param n 天数
   * @returns {string} format{yyyy-MM-dd}
   */
  getAfterDay: function (_date: any, n: number = 0): string {
    _date = this.timestampToSafari(_date);
    const date: any = new Date(new Date(_date).getTime() + ((24 * 60 * 60 * 1000) * n));
    const year = this.fix(date.getFullYear(), 4);
    const month = this.fix(parseInt(date.getMonth() + 1), 2);
    const day = this.fix(date.getDate(), 2);
    return year + '-' + month + '-' + day;
  },

  /**
   * 获取n年后的日期，并返回标准格式
   * @param _date 日期
   * @param n 年数
   * @returns {string} format{yyyy-MM-dd HH:mm:ss}
   */
  getAfterYear: function (_date: any, n: number = 0): string {
    _date = this.timestampToSafari(_date);
    let date = new Date(new Date(_date).getTime())
    const year = this.fix(date.getFullYear() + n, 4);
    const month = this.fix(date.getMonth() + 1, 2);
    const day = this.fix(date.getDate(), 2);
    const hours = this.fix(date.getHours(), 2);
    const minutes = this.fix(date.getMinutes(), 2);
    const seconds = this.fix(date.getSeconds(), 2);
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':'
        + seconds;
  },

  /**
   * @param {Number} timeStamp 判断时间戳格式是否是毫秒
   * @returns {Boolean}
   */
  isMillisecond: (timeStamp: number): boolean => {
    const timeStr = String(timeStamp);
    return timeStr.length > 10;
  },

  /**
   * 时间戳转换，当天返回 hh:mm，一天以前返回 n天前
   * @param timestamp yyyy-MM-dd hh:mm:ss
   * @returns {string}
   */
  toChatFormatTimeDiff: function (timestamp: any) {
    timestamp = this.timestampToSafari(timestamp);
    // 获取时间戳对应的日期时间，格式 yyyy-MM-dd hh:mm:ss
    const lasttime: string = this.getCurDatetime(timestamp);
    // 获取当前系统日期时间，格式 yyyy-MM-dd hh:mm:ss
    const curtime: string = this.getCurDatetime();

    const lastDate: any = new Date(lasttime);
    const curDate: any = new Date(curtime);

    const dateDifference = curDate.getTime() - lastDate.getTime(); // 时间差的毫秒数
    const days = Math.floor(dateDifference / (24 * 3600 * 1000)); // 计算出相差天数
    // 计算出小时数
    const leave1 = dateDifference % (24 * 3600 * 1000);    // 计算天数后剩余的毫秒数
    const hours = Math.floor(leave1 / (3600 * 1000));
    // 计算相差分钟数
    const leave2 = leave1 % (3600 * 1000);        // 计算小时数后剩余的毫秒数
    const minutes = Math.floor(leave2 / (60 * 1000));
    // 计算相差秒数
    const leave3 = leave2 % (60 * 1000);      // 计算分钟数后剩余的毫秒数
    const seconds = Math.round(leave3 / 1000);
    let retDate = days + '天 ' + hours + '小时 ' + minutes + '分钟 ' + seconds + '秒';

    if (days > 0) {
      if (days >= 7) {
        retDate = this.getCurDatetime(lastDate).substr(0, 10);
      } else {
        retDate = days + '天前';
      }
    } else {
      if (lasttime.substr(0, 10) != curtime.substr(0, 10)) {
        retDate = '昨天 ' + lasttime.substr(11, 5);
      } else {
        retDate = lasttime.substr(11, 5);
      }
    }
    return retDate;
  },

  /**
   * 根据开始时间与时间差，计算几天前/几天后的具体时间
   * @param startDate : 格式为 yyyy-MM-dd hh:mm:ss
   * @param difference : 格式为数值，可为负，为负时，计算该开始时间之前日期
   * @returns {string} : 格式为 yyyy-MM-dd hh:mm:ss
   */
  getAnotherDatetimeBy: function (startDate: any, difference: number): string {
    startDate = this.timestampToSafari(startDate);
    const dateObj: any = new Date(startDate);
    dateObj.setDate(dateObj.getDate() + difference);
    const year = this.fix(dateObj.getFullYear(), 4);
    const month = this.fix(parseInt(dateObj.getMonth() + 1), 2);
    const day = this.fix(dateObj.getDate(), 2);
    const hours = this.fix(dateObj.getHours(), 2);
    const minutes = this.fix(dateObj.getMinutes(), 2);
    const seconds = this.fix(dateObj.getSeconds(), 2);

    return year + '-' + month + '-' + day + ' ' + hours + ':'
        + minutes + ':' + seconds;
  },

  /**
   * 获取某天的年月日
   * @param date 时间戳或字符串格式的时间
   * @param split 年月日中间间隔字符样式：默认-，可为/
   * @returns {string} 格式：yyyy-mm-dd或yyyy/mm/dd
   */
  formatDate: function (date: any, split: string = '-'): string {
    date = this.timestampToSafari(date);
    const dateObj: any = new Date(date);
    const endyear = this.fix(dateObj.getFullYear(), 4);
    const endmonth = this.fix(parseInt(dateObj.getMonth() + 1), 2);
    const endday = this.fix(dateObj.getDate(), 2);
    return (endyear + split + endmonth + split + endday);
  },

  /**
   * 获取当年某月的天数
   * @param myMonth 月份
   * @returns {number}
   */
  getMonthDays: function (myMonth: number): number {
    const now: any = new Date(); // 当前日期
    const nowYear = now.getYear(); // 当前年
    const monthStartDate = new Date(nowYear, myMonth, 1);
    const monthEndDate = new Date(nowYear, myMonth + 1, 1);
    const dateDifference = monthEndDate.getTime() - monthStartDate.getTime(); // 时间差的毫秒数
    return dateDifference / (1000 * 60 * 60 * 24);
  },

  /**
   * 获取某年某月的天数
   * @param myYear 年份
   * @param myMonth 月份
   * @returns {number} 天数
   */
  getMonthDaysByYear: function (myYear: number, myMonth: number): number {
    myMonth = myMonth - 1;
    const monthStartDate = new Date(myYear, myMonth, 1);
    const monthEndDate = new Date(myYear, myMonth + 1, 1);
    const dateDifference = monthEndDate.getTime() - monthStartDate.getTime(); // 时间差的毫秒数
    return dateDifference / (1000 * 60 * 60 * 24);
  },

  /**
   * 获取当前季度的开始月份
   * @returns {number}
   */
  getQuarterStartMonth: function (): number {
    const now: any = new Date(); // 当前日期
    const nowMonth = now.getMonth(); // 当前月
    let quarterStartMonth = 0;
    if (nowMonth < 3) {
      quarterStartMonth = 1;
    }
    if (2 < nowMonth && nowMonth < 6) {
      quarterStartMonth = 4;
    }
    if (5 < nowMonth && nowMonth < 9) {
      quarterStartMonth = 7;
    }
    if (nowMonth > 8) {
      quarterStartMonth = 10;
    }
    return quarterStartMonth;
  },

  /**
   * @param {Number} timeStamp 传入的时间戳
   * @param {Number} currentTime 当前时间时间戳
   * @returns {Boolean} 传入的时间戳是否早于当前时间戳
   */
  isEarly: (timeStamp: number, currentTime: number): boolean => {
    return timeStamp < currentTime;
  },

  /**
   * 时间转换为 yyyy-MM
   * @param date 时间戳或字符串格式的时间
   * @returns {string}
   */
  dateToMonth: function (date: any): string {
    date = this.timestampToSafari(date);
    const end: any = new Date(date);
    const endyear = this.fix(end.getFullYear(), 4);
    const endmonth = this.fix(parseInt(end.getMonth() + 1), 2);
    return endyear + '-' + endmonth;
  },

  /**
   * 时间转换为 yyyy年MM月dd日
   * @param date 时间戳或字符串格式的时间
   * @returns {string}
   */
  dateToDateString: function (date: any): string {
    date = this.timestampToSafari(date);
    const end: any = new Date(date);
    const endyear = this.fix(end.getFullYear(), 4);
    const endmonth = this.fix(parseInt(end.getMonth() + 1), 2);
    const endday = this.fix(end.getDate(), 2);
    console.log(endyear, endmonth, endday)
    return endyear + '年' + endmonth + '月' + endday + '日';
  },

  /**
   * 时间转换为 HH:mm
   * @param date 时间戳或字符串格式的时间
   * @returns {string}
   */
  dateToTime: function (date: any): string {
    date = this.timestampToSafari(date);
    const end: any = new Date(date);
    const endhours = this.fix(end.getHours(), 2);
    const endminutes = this.fix(end.getMinutes(), 2);
    return endhours + ':' + endminutes;
  },
  /**
   * 传入秒数，返回秒数所代表的~天~小时~分钟~秒
   * @param duration 要转换的时间秒数
   * @param split 分隔符，默认中文分割，可'英文格式分割
   */
  durationFormat: function (duration: number, split?: string): string {
    // @ts-ignore
    // const _this:any = this;
    let res: string = '';
    const days = Math.floor(duration / (24 * 60 * 60)); // 计算出相差天数
    // 计算出小时数
    const leave1 = duration % (24 * 60 * 60);    // 计算天数后剩余的毫秒数
    const hours = Math.floor(leave1 / (60 * 60));
    // 计算相差分钟数
    const leave2 = leave1 % (60 * 60); // 计算小时数后剩余的毫秒数
    const minutes = Math.floor(leave2 / 60);
    // 计算相差秒数
    const leave3 = leave2 % 60; // 计算分钟数后剩余的毫秒数
    const seconds = Math.round(leave3);
    switch (split) {
      case '\'':
        res = (days > 0 ? days + 'D' : '') + (hours > 0 ? hours + 'H' : '') + (minutes > 0 ? minutes + '\'' : '') + (seconds > 0 ? seconds + '"' : '');
        break;
      case ':':
        res = (days > 0 ? this.fix(days, 2) + ':' : '') + (hours > 0 ? this.fix(hours, 2) + ':' : '') + (minutes > 0 ? this.fix(minutes, 2) + ':' : '00:') + (seconds > 0 ? this.fix(seconds, 2) : '00');
        break;
      default:
        res = (days > 0 ? days + '天' : '') + (hours > 1 ? hours + '小时' : '') + (minutes > 0 ? minutes + '分钟' : '') + (seconds > 0 ? seconds + '秒' : '');
        break;
    }
    return res;
  },
  /**
   * 获取当前时间距离传参date时间还有多少天/时/分/秒
   * @param date
   * @returns {object} *天*时*分*秒
   */
  getCutOffTimeTo: function (date: any): any {
    date = this.timestampToSafari(date);
    let res: any = {
      day: '00',
      hour: '00',
      minute: '00',
      second: '00',
    };
    const lastDate: any = new Date(date);
    const curDate: any = new Date();

    const dateDifference = lastDate.getTime() - curDate.getTime(); // 时间差的毫秒数
    const days = Math.floor(dateDifference / (24 * 3600 * 1000)); // 计算出相差天数
    // 计算出小时数
    const leave1 = dateDifference % (24 * 3600 * 1000);    // 计算天数后剩余的毫秒数
    const hours = Math.floor(leave1 / (3600 * 1000));
    // 计算相差分钟数
    const leave2 = leave1 % (3600 * 1000);        // 计算小时数后剩余的毫秒数
    const minutes = Math.floor(leave2 / (60 * 1000));
    // 计算相差秒数
    const leave3 = leave2 % (60 * 1000);      // 计算分钟数后剩余的毫秒数
    const seconds = Math.round(leave3 / 1000);
    // let retDate = days + '天 ' + hours + '小时 ' + minutes + '分钟 ' + seconds + '秒';
    return {
      day: this.fix(days, 2),
      hour: this.fix(hours, 2),
      minute: this.fix(minutes, 2),
      second: this.fix(seconds, 2),
    };
  },
};
export default date;

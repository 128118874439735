import StorageUtils from "@/js/utils/storage.ts";
import Http from "@/js/utils/http.ts";
import WechatUtils from "@/js/utils/wechat.ts";
import Utils from "@/js/utils/utils.ts";
import StringUtils from "@/js/utils/string.ts";

const Common: any = {
  /**
   * 获取用户信息
   * @param context
   * @param successHandler
   * @param is_refresh 是否从服务器重新拉取最新的用户信息，默认false
   */
  getUserInfo: (context: any, successHandler: any = null, is_refresh: boolean = false): void => {
    const openId: any = StorageUtils.getByModule('user.openid');
    const userInfo: any = StorageUtils.getByModule('user.info');
    if (!userInfo || is_refresh) {
      const data: any = {
        openid: openId ? openId : sessionStorage.getItem('user.openid'),
      };
      const errorInfo: any = (res: any): void => {
        if (successHandler != null && typeof successHandler == "function") {
          successHandler(userInfo);
        }
      };
      const successInfo: any = (res: any): void => {
        const resData: any = res.data || {};
        StorageUtils.updateByModule('user.info', resData);
        if (successHandler != null && typeof successHandler == "function") {
          successHandler(resData);
        }
      };
      const params: any = {
        url: Http.kInterfacePaths.wechat.userInfo,
        data: data,
        warn_info: '获取用户信息失败！',
        success: successInfo,
        error: errorInfo,
      };
      Http.ajax(context, params);
    } else {
      if (successHandler != null && typeof successHandler == "function") {
        successHandler(userInfo);
      }
    }
  },
  /**
   * 获取客服号信息列表
   * @param context
   * @param successHandler
   */
  getServeWechatInfo: function (context: any, successHandler: any = null) {
    const openId: any = StorageUtils.getByModule('user.openid');
    if (openId == null) {
      if (typeof successHandler == "function") {
        successHandler(null);
      }
    } else {
      const data: any = {
        openid: openId,
      };
      const successInfo: any = (res: any): void => {
        const resData: any = res.data || null;
        if (typeof successHandler == "function") {
          successHandler(resData);
        }
      };
      const params: any = {
        url: Http.kInterfacePaths.server.list,
        data: data,
        warn_info: '获取客服号信息失败！',
        success: successInfo,
      };
      Http.ajax(context, params);
    }
  },
  // 获取微信签名信息
  getWechatSign: function (context: any): void {
    const shebei: string = Utils.checkAndroidOrIos();
    const data: any = {
      url: shebei == 'ios' ? window.location.href.split('#')[0] : window.location.href,
    };
    // const data: any = {
    //   url: window.location.href,
    // };
    const successPay: any = (res: any): void => {
      const resData: any = res.data || {};
      WechatUtils.config({
        appId: resData.appid || '', // 必填，公众号的唯一标识
        timestamp: resData.timestamp || 0, // 必填，生成签名的时间戳
        nonceStr: resData.noncestr || '', // 必填，生成签名的随机串
        signature: resData.signature || '',// 必填，签名
      });
    };
    const params: any = {
      url: Http.kInterfacePaths.wechat.signature,
      data: data,
      warn_info: '获取签名失败！',
      success: successPay,
    };
    Http.ajax(context, params);
  },
  // 设置微信分享
  setWechatShare: function (context: any, temp: any = null, giftInviteId: number = -1) {
    // 获取签名并设置微信
    const shebei: string = Utils.checkAndroidOrIos();
    const data: any = {
      url: shebei == 'ios' ? window.location.href.split('#')[0] : window.location.href,
    };
    const successPay: any = (res: any): void => {
      const resData: any = res.data || {};
      WechatUtils.config({
        appId: resData.appid || '', // 必填，公众号的唯一标识
        timestamp: resData.timestamp || 0, // 必填，生成签名的时间戳
        nonceStr: resData.noncestr || '', // 必填，生成签名的随机串
        signature: resData.signature || '',// 必填，签名
      });
    };
    const sign_params: any = {
      url: Http.kInterfacePaths.wechat.signature,
      data: data,
      warn_info: '获取签名失败！',
      success: successPay,
    };
    const noNeedWxSignature: boolean = (temp && temp.hasOwnProperty('noNeedSignature') && temp.noNeedSignature);
    if (!noNeedWxSignature || shebei != 'ios') {
      Http.ajax(context, sign_params);
    }
    // 分享设置
    const curPagePath: any = StorageUtils.getByModule('page.new');
    const openId: any = StorageUtils.getByModule('user.openid');
    const userInfo: any = StorageUtils.getByModule('user.info');
    const audioOpenObj: any = StorageUtils.getByModule('audioPlay.open');

    const gotoFun: any = (id: any = -1): void => {
      const params: any = {
        openid: openId,
        from: 'invite-friends',
        source: curPagePath || '/',
        uid: userInfo && userInfo.id ? userInfo.id : -1,
        giftInvite: giftInviteId,
        data: temp || {},
        // audioPlay: audioOpenObj,
        audioPlayId: id,// 分享的数据存到数据库的id
      };
      StorageUtils.deleteByModule('audioPlay.open');
      let str: string = Utils.encode('base64', StringUtils.toStr(params));
      // console.log('要分享的invite值为：', str);
      // console.log('解密后的字符串为：', Utils.decode('base64', str));
      str = str.replace("+", "%2B");
      const shareUrl: string = Http.getDomain() + Http.kInterfacePaths.inviter.share + '?invite=' + str;
      let shareTitle: string = '快乐听故事 轻松长知识';
      let shareDesc: string = '快来加入阅读计划';
      let shareImg: string = Http.getDomainUrl() + 'static/img/logo.jpg';
      // console.log('audioPlay.open value====', audioOpenObj)
      if (audioOpenObj != null) {
        // 音频播放器
        shareTitle = temp && temp.hasOwnProperty('title') && temp.title ? ('这集《' + temp.title + '》讲的真好，不信你听...') : shareTitle;
        shareDesc = temp && temp.hasOwnProperty('subtitle') && temp.subtitle ? temp.subtitle : shareDesc;
        shareImg = temp && temp.hasOwnProperty('thumb') && temp.thumb ? temp.thumb : shareImg;
      } else {
        switch (curPagePath) {
          case '/album':
            shareTitle = temp && temp.hasOwnProperty('title') && temp.title ? ('这部《' + temp.title + '》太赞了，快来跟我一块听呀！') : shareTitle;
            shareDesc = temp && temp.hasOwnProperty('subtitle') && temp.subtitle ? temp.subtitle : shareDesc;
            shareImg = temp && temp.hasOwnProperty('thumb') && temp.thumb ? temp.thumb : shareImg;
            break;
          case '/gift_invite':
            shareTitle = '亲，我马上就能领到了，快来帮帮忙呀！';
            shareDesc = '好友助力，免费领取';
            shareImg = temp && temp.hasOwnProperty('thumb') && temp.thumb ? temp.thumb : shareImg;
            break;
          case '/vip_invite':
            shareTitle = temp && temp.hasOwnProperty('nickname') && temp.nickname ? (temp.nickname + '的阅读圈') : shareTitle;
            shareDesc = '我也有阅读圈喽，里面有好多好听的故事呐，要不要一起来呀';
            break;
        }
      }
      const shareParams: any = {
        title: shareTitle,
        desc: shareDesc,
        link: encodeURI(shareUrl),
        imgUrl: shareImg,
      };
      // 等到200毫秒后设置分享信息
      setTimeout((): void => {
        // 分享朋友前的信息设置
        WechatUtils.shareFriends(shareParams, null, null);
        WechatUtils.shareFriendsSpace(shareParams, null, null);
        // 增加监听是否点击了右上角分享按钮
        WechatUtils.onMenuShareFriends(shareParams, null);
        WechatUtils.onMenuShareFriendSpace(shareParams, null);
        WechatUtils.onMenuShareQQFriends(shareParams, null);
        WechatUtils.onMenuShareQQZone(shareParams, null);
        WechatUtils.onMenuShareTxWeibo(shareParams, null);
      }, 200);
    }
    // 播放器页面的数据存到数据库
    if (audioOpenObj && audioOpenObj != null) {
      const temp_data = {
        content: StringUtils.toStr(audioOpenObj),
      };
      const successTemp: any = (res: any): void => {
        const resData: any = res.data || {};
        gotoFun(resData.id);
      };
      const temp_params: any = {
        url: Http.kInterfacePaths.jump_temp_data.add,
        data: temp_data,
        warn_info: '保存数据失败！',
        success: successTemp,
      };
      Http.ajax(context, temp_params);
    } else {
      gotoFun();
    }
  },
  // 将订单信息增加至微信公众号系统中
  addWechatOrder: function (context: any, params: any, successHandler: any = null, errorHandler: any = null) {
    const userInfo: any = StorageUtils.getByModule('user.info') || {};
    let data: any = {
      wid: userInfo.hasOwnProperty('id') ? userInfo.id : -1,
      org_money: params.hasOwnProperty('org_money') ? parseFloat(params.org_money) : 0,// 优惠前价格
      money: params.hasOwnProperty('money') ? parseFloat(params.money) : 0,// 实际价格
      cover: params.hasOwnProperty('cover') ? params.cover : '',
      type: params.hasOwnProperty('type') ? params.type : '',
      title: params.hasOwnProperty('title') ? params.title : '',
      content: params.hasOwnProperty('content') ? params.content : '',
      order: params.hasOwnProperty('order') ? params.order : '',
      num: params.hasOwnProperty('amount') ? params.amount : 1,//数量
      amount: params.hasOwnProperty('amount') ? params.amount : 1,//数量
      address_id: params.hasOwnProperty('address_id') ? params.address_id : -1,// 地址id
      goods_id: params.hasOwnProperty('goods_id') ? params.goods_id : -1,//物品id
      coupon_id: params.hasOwnProperty('coupon_id') ? parseInt(params.coupon_id) : -1,//使用优惠券的id
      status: params.hasOwnProperty('status') ? params.status : 'notPay',//金额为0 传payed
      rid: params.hasOwnProperty('rid') ? params.rid : -1,
      aid: params.hasOwnProperty('aid') ? params.aid : -1,
      expire_time: params.hasOwnProperty('expire_time') ? params.expire_time : '',//vip有效年数
    };
    if (params.hasOwnProperty('info') && params.info != '') {
      Object.assign(data, {
        info: params.info,
      });
    }
    const successAdd: any = (res: any): void => {
      if (successHandler != null && typeof successHandler == "function") {
        successHandler(res);
      }
    };
    const params_add: any = {
      url: Http.kInterfacePaths.order.add,
      data: data,
      warn_info: '添加订单信息失败！请联系管理员',
      success: successAdd,
    };
    console.log('order.add==', data)
    Http.ajax(context, params_add);
  },
  // 将订单增加至本系统中
  addSystemOrder: function (params: any, successHandler: any = null) {
    const data: any = params.data || {};
    const handlerAfterShare = (res: any): void => {
      if (res.code == 0) {
        const resDataStr: string = res.data || "";
        const resData: any = StringUtils.toJson(resDataStr);
        WechatUtils.pay({
          appId: resData.appId || '',
          timestamp: resData.timeStamp || 0, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: resData.nonceStr || '', // 支付签名随机串，不长于 32 位
          package: resData.package || '', // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: resData.signType || 'MD5', // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: resData.paySign || '',
        }, (result: any): void => {
          if (typeof successHandler == "function") {
            successHandler(result);
          }
        });
      } else {
        let contactDialog = window.f7.dialog.alert('出错了' + res.code + '，请联系管理员！');
        Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
          opened: true,
          dialog: contactDialog,
        });
      }
    };

    // console.log(data);
    const errorPay: any = (res: any): void => {
      handlerAfterShare(res);
    };
    const successPay: any = (res: any): void => {
      handlerAfterShare(res);
    };
    const postParams: any = {
      url: Http.kInterfacePaths.wechat.order,
      data: data,
      // warn_info: '支付失败',
      success: successPay,
      error: errorPay,
    };
    Http.ajax(this, postParams);
  },
  // 通知更新用户信息
  updateUserMemberInfo: function (params: any, successHandler: any = null): void {
    const data: any = {
      id: params.uid || -1,
    };
    const successUpdate: any = (res: any): void => {
      Utils.fireEvent(this, Utils.kEvents.updateIsMemberInfo, true);
      Utils.fireEvent(this, Utils.kEvents.updatePersonUserInfo, true);
      let contactDialog = window.f7.dialog.alert('恭喜您，已经成为快乐听吧VIP会员', (): void => {
        // Utils.load(this, '/', {activePage: 'vip'}, {reloadAll: true});
        Utils.back(this, '/my_order', {}, {force: true, reloadPrevious: true});
      });
      Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
        opened: true,
        dialog: contactDialog,
      });
      if (typeof successHandler == "function") successHandler(res);
    };
    const postParams: any = {
      url: Http.kInterfacePaths.wechat.update,
      data: data,
      warn_info: '更新用户信息失败！请立即联系管理员',
      success: successUpdate,
    };
    // Http.ajax(this, postParams);
    successUpdate();
  },
  // 通知更新专辑详情信息（是否已购买等信息）
  updateAlbumUserInfo: function (params: any, successHandler: any = null): void {
    const data: any = {
      uid: params.uid || -1,
      aid: params.aid || -1,
    };
    const successUpdate: any = (res: any): void => {
      if (typeof successHandler == "function") {
        successHandler(res);
      } else {
        Utils.fireEvent(this, Utils.kEvents.updateAlbumQuery, {
          id: params.aid || -1,
          // update: true,
          is_buy: true,
        });
        // Utils.back(this, '/album', {}, {force: true, ignoreCache: true});
        Utils.back(this, '/my_order', {}, {force: true, reloadPrevious: true});
      }
    };
    const postParams: any = {
      url: Http.kInterfacePaths.album.updateAfterBuy,
      data: data,
      warn_info: '更新专辑购买信息失败！请立即联系管理员，客服微信号：' + (params.wxkf || 'shizilaoba04'),
      success: successUpdate,
    };
    // Http.ajax(this, postParams);
    successUpdate();
  }
};
export default Common;

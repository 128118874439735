
/**
 * 字符串相关操作
 */
const String = {

  /**
   * json转为字符串
   * @param {json} obj json
   * @param {string} def 默认返回值（可选），不传则默认为 '{}'
   * @returns {string} 字符串
   */
  toStr: (obj: object, def = '{}'): string => {
    let res: string = def;
    try {
      res = JSON.stringify(obj);
    } catch (error) {
    }
    return res;
  },

  /**
   * 字符串转为json
   * @param {string} str 字符串
   * @param {object} def 默认返回值（可选），不传则默认为 {}
   * @returns {object} json
   */
  toJson: (str: string, def = {}): object => {
    let res: object = def;
    str = str.trim();
    if (str != '') {
      try {
        res = JSON.parse(str);
      } catch (error) {
      }
    }
    return res;
  },

  /**
   * 字符串转为 int
   * @param {string} str 字符串
   * @param {number} def 默认返回值（可选），不传则默认为 0
   * @returns {number} int
   */
  toInt: (str: string, def = 0): number => {
    let num: number = def;
    try {
      num = parseInt(str);
    } catch (error) {
    }
    if (isNaN(num)) {
      num = def;
    }
    return num;
  },

  /**
   * 字符串转为 double
   * @param {string} str 字符串
   * @param {number} def 默认返回值（可选），不传则默认为 0
   * @returns {number} float
   */
  toDouble: (str: string, def = 0): number => {
    let num: number = def;
    try {
      num = parseFloat(str);
    } catch (error) {
    }
    if (isNaN(num)) {
      num = def;
    }
    return num;
  },


};

export default String;

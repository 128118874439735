import Utils from "@/js/utils/utils";
import StorageUtils from "@/js/utils/storage";

let cordovaApp = {
  f7: null,
  /*
  This method hides splashscreen after 2 seconds
  */
  handleSplashscreen: function () {
    let f7 = cordovaApp.f7;
    // @ts-ignore
    if (!window.navigator.splashscreen || f7.device.electron) return;
    setTimeout(() => {
      // @ts-ignore
      window.navigator.splashscreen.hide();
    }, 2000);
  },
  /*
  This method prevents back button tap to exit from app on android.
  In case there is an opened modal it will close that modal instead.
  In case there is a current view with navigation history, it will go back instead.
  */
  handleAndroidBackButton: function () {
    let f7 = cordovaApp.f7;
    // @ts-ignore
    const $ = f7.$;
    // @ts-ignore
    if (f7.device.electron) return;
    let lastBackTimestamp = 0;

    document.addEventListener('backbutton', function (e) {
      if ($('.actions-modal.modal-in').length) {
        // @ts-ignore
        f7.actions.close('.actions-modal.modal-in');
        e.preventDefault();
        return false;
      }
      if ($('.dialog.modal-in').length) {
        // @ts-ignore
        f7.dialog.close('.dialog.modal-in');
        e.preventDefault();
        return false;
      }
      if ($('.sheet-modal.modal-in').length) {
        // @ts-ignore
        f7.sheet.close('.sheet-modal.modal-in');
        e.preventDefault();
        return false;
      }
      if ($('.popover.modal-in').length) {
        // @ts-ignore
        f7.popover.close('.popover.modal-in');
        e.preventDefault();
        return false;
      }
      if ($('.popup.modal-in').length) {
        if ($('.popup.modal-in>.view').length) {
          // @ts-ignore
          const currentView = f7.views.get('.popup.modal-in>.view');
          if (currentView && currentView.router && currentView.router.history.length > 1) {
            currentView.router.back();
            e.preventDefault();
            return false;
          }
        }
        // @ts-ignore
        f7.popup.close('.popup.modal-in');
        e.preventDefault();
        return false;
      }


      let audioPlay = StorageUtils.getByModule('audioPlay.isOpened') || false;
      if (audioPlay) {
        Utils.fireEvent(Utils.kEvents.updateAudioPlayMinStatus, {is_min: true});
        e.preventDefault();
        return false;
      }

      if ($('.login-screen.modal-in').length) {
        // @ts-ignore
        f7.loginScreen.close('.login-screen.modal-in');
        e.preventDefault();
        return false;
      }

      if ($('.page-current .searchbar-enabled').length) {
        // @ts-ignore
        f7.searchbar.disable('.page-current .searchbar-enabled');
        e.preventDefault();
        return false;
      }

      if ($('.page-current .card-expandable.card-opened').length) {
        // @ts-ignore
        f7.card.close('.page-current .card-expandable.card-opened');
        e.preventDefault();
        return false;
      }

      if ($('.panel.panel-in').length) {
        // @ts-ignore
        f7.panel.close('.panel.panel-in');
        e.preventDefault();
        return false;
      }

      // @ts-ignore
      const currentView = f7.views.current;
      if (currentView && currentView.router && currentView.router.history.length > 1) {
        currentView.router.back();
        e.preventDefault();
        return false;
      } else {
        if (new Date().getTime() - lastBackTimestamp < 2000) {
          lastBackTimestamp = new Date().getTime();
          // @ts-ignore
          if (window.navigator.app && window.navigator.app.exitApp) {
            // @ts-ignore
            window.navigator.app.exitApp();
          }
        } else {
          window.f7.toast.create({
            text: '再次点击退出应用',
            closeTimeout: 2000,
            position: "center",
          }).open();
          lastBackTimestamp = new Date().getTime();
        }
      }

    }, false);
  },
  /*
  This method does the following:
    - provides cross-platform view "shrinking" on keyboard open/close
    - hides keyboard accessory bar for all inputs except where it required
  */
  handleKeyboard: function () {
    let f7 = cordovaApp.f7;
    // @ts-ignore
    if (!window.Keyboard || !window.Keyboard.shrinkView || f7.device.electron) return;
    // @ts-ignore
    let $ = f7.$;
    // @ts-ignore
    window.Keyboard.shrinkView(false);
    // @ts-ignore
    window.Keyboard.disableScrollingInShrinkView(true);
    // @ts-ignore
    window.Keyboard.hideFormAccessoryBar(true);
    window.addEventListener('keyboardWillShow', () => {
      // @ts-ignore
      f7.input.scrollIntoView(document.activeElement, 0, true, true);
    });
    window.addEventListener('keyboardDidShow', () => {
      // @ts-ignore
      f7.input.scrollIntoView(document.activeElement, 0, true, true);
    });
    window.addEventListener('keyboardDidHide', () => {
      if (document.activeElement && $(document.activeElement).parents('.messagebar').length) {
        return;
      }
      // @ts-ignore
      window.Keyboard.hideFormAccessoryBar(false);
    });
    window.addEventListener('keyboardHeightWillChange', (event) => {
      // @ts-ignore
      let keyboardHeight = event.keyboardHeight;
      if (keyboardHeight > 0) {
        // Keyboard is going to be opened
        document.body.style.height = `calc(100% - ${keyboardHeight}px)`;
        $('html').addClass('device-with-keyboard');
      } else {
        // Keyboard is going to be closed
        document.body.style.height = '';
        $('html').removeClass('device-with-keyboard');
      }

    });
    // @ts-ignore
    $(document).on('touchstart', 'input, textarea, select', function (e) {
      let nodeName = e.target.nodeName.toLowerCase();
      let type = e.target.type;
      let showForTypes = ['datetime-local', 'time', 'date', 'datetime'];
      if (nodeName === 'select' || showForTypes.indexOf(type) >= 0) {
        // @ts-ignore
        window.Keyboard.hideFormAccessoryBar(false);
      } else {
        // @ts-ignore
        window.Keyboard.hideFormAccessoryBar(true);
      }
    }, true);
  },
  // @ts-ignore
  init: function (f7) {
    // Save f7 instance
    cordovaApp.f7 = f7;

    // Handle Android back button
    cordovaApp.handleAndroidBackButton();

    // Handle Splash Screen
    cordovaApp.handleSplashscreen();

    // Handle Keyboard
    cordovaApp.handleKeyboard();
  },
};

export default cordovaApp;

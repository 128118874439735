/**
 * ajax 请求相关
 */
import Utils from "@/js/utils/utils";

const Http = {
  // version: '200',
  /**
   * 是否开启debug
   */
  debug: false,

  /**
   * 是为 app 否为 微信公众号
   */
  isApp: false,

  /**
   * 请求服务器地址
   * @returns {string}
   */
  getDomain: (): string => {
    // const locDomain: string = 'http://cwmk.jnaw.top';
    const locDomain: string = 'http://test.kuailetingba.cn/liontest/';
    const serveDomain: string = 'http://www.kuailetingba.cn/lion/';
    return Http.debug ? locDomain : serveDomain;
  },
  getDomainUrl: (): string => {
    return Http.debug ? 'http://test.kuailetingba.cn/' : 'http://www.kuailetingba.cn/';
  },
  type: {
    get: 'GET',
    post: 'POST',
    put: 'PUT',
    delete: 'DELETE',
  },

  kInterfacePaths: {
    jump_temp_data: {
      add: 'jump_temp_data/add',
      query: 'jump_temp_data/query',
    },
    // 文章
    album: {
      list: 'article/list',
      query: 'article/query',
      add: 'article/add',
      update: 'article/update',
      del: 'article/del',
      updateAfterBuy: 'article/user/add',
      sort: 'article/sort',
      batch: 'article/batchAdd',
      studyTimes: 'article/updateStudyTimes',
    },
    file: {
      list: 'file/getCycleList', // 不动图的列表
      addRichText: 'file/addRichText', // 富文本图片上传
      addFile: 'file/addFile', // 文件上传
      addImage: 'file/addImage', // 图片上传
      addMp3: 'file/addMp3', // 音频上传
    },
    gift: {
      list: 'gift/list',
      query: 'gift/query',
      add: 'gift/add',
      del: 'gift/del',
      update: 'gift/update',
      check: 'gift/check',
      receiveRequest: 'gift_user_relation/add',//提交礼物兑换请求
      checkExchange: 'gift_user_relation/queryIsReceiveOrNot',//查询是否已领取该礼物
      inviteProgress: 'gift_user_relation/list',//查询邀请的好友列表
      receive: 'gift_user_relation/receive',//领取已满足条件的礼物
      getNotHelpList: 'gift_user_relation/getNotHelpList',//未助力的人员列表
    },
    exchange: {
      list: 'exchange/list',
      query: 'exchange/query',
      add: 'exchange/add',
      del: 'exchange/del',
      update: 'exchange/update',
    },
    service: {
      list: 'wechat/serviceNumber/list',
      query: 'wechat/serviceNumber/query',
      add: 'wechat/serviceNumber/add',
      del: 'wechat/serviceNumber/del',
      last: 'wechat/serviceNumber/last',
      update: 'wechat/serviceNumber/update',
    },
    // 历史收听
    history: {
      list: 'wechat/historySound/list',
      query: 'wechat/historySound/query',
      add: 'wechat/historySound/add',
      del: 'wechat/historySound/del',
      needAdd: 'article/playLog',
      last: 'wechat/historySound/getLast',
    },
    // 分享
    inviter: {
      add: 'inviter/add', // inviter邀请人id，be_inviter被邀请人id
      update: 'inviter/update', // id
      list: 'inviter/list', // 查询当前邀请人列表接口，page,size,inviter邀请人id
      share: 'wxPay', // 参数invite，包含邀请人openid及是哪个分享活动的区分
    },
    // 联系我们
    contact: {
      list: 'contact/list',
      query: 'contact/query',
      add: 'contact/add',
      del: 'contact/del',
      update: 'contact/update',
    },
    // web评论回复
    replay: {
      list: 'replay/list',
      query: 'replay/query',
      add: 'replay/add',
      del: 'replay/del',
    },
    // web用户管理
    user: {
      login: 'user/login',
      captcha: 'user/captcha',
      add: 'user/add',
      query: 'user/query',
      update: 'user/update',
      constellation: 'user/queryConstellationList',// 获取星座列表
    },
    order: {
      'add': 'wechat/order/add',
      'list': 'wechat/order/list',
      'query': 'wechat/order/query',
      // 'update':'wechat/order/update',
      'delete': 'wechat/order/delete',
    },
    wechat: {
      userInfo: 'wechat/user/query', // 获取用户信息，传参openid
      userList: 'wechat/user/list', // 所有关注者列表
      signature: 'wechat/signature', // 获取签名信息，需传递当前页面的全url地址，参数名为：url
      order: 'wxPay/webPay',
      update: 'wechat/user/update', // 更新会员状态，参数id,系统用户的id
    },
    server: {
      add: 'wechat/serviceNumber/add', // number
      update: 'wechat/serviceNumber/update', // id,number
      list: 'wechat/serviceNumber/list',
    },
    coupon: {
      list: 'coupon/myCouponList', // 我的优惠券列表
      query: 'coupon/query', // 优惠券详情
      canUse: 'coupon/canUseCouponList', // 可用优惠券列表
      add: 'coupon/addCouponToUser', // 用户添加优惠券
      search: 'coupon/queryByTimeAndType',//按赠送日期、类型查询优惠券
    },
    goods: {
      list: 'goods/list',
      query: 'goods/query',
      delete: 'goods/del',
      add: 'goods/add',
      update: 'goods/update',
    },
    address: {
      list: 'address/list',
      query: 'address/query',
      queryOne: 'address/queryOne',
      add: 'address/add',
      update: 'address/update',
      delete: 'address/del',
    },
    log: {
      error: 'errorLog/add', // 错误反馈给后台，wid微信用户id;platform【ios/android/harmony】;error错误信息说明对象
    },
  },

  /**
   * 发送 ajax 请求
   * @param params
   */
  ajax: (context: any, params: any): void => {
    // console.log(params);
    // console.log('\n\n\n=====');
    try {
      const domain: string = params.domain || '';
      const type = params.type || Http.type.post;
      const url = params.url || '';
      const data = params.data || {};

      if (typeof domain == "boolean") {
        axios.defaults.baseURL = '';
      } else if (typeof domain == "string") {
        axios.defaults.baseURL = domain != '' ? domain : Http.getDomain();
      } else {
        axios.defaults.baseURL = domain ? domain : Http.getDomain();
      }
      // axios.defaults.baseURL = domain ? domain : Http.getDomain();
      axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
      const successFuc = (params.success && typeof params.success == 'function')
          ? params.success : null;
      const errorFuc = (params.error && typeof params.error == 'function')
          ? params.error : null;

      axios({
        method: type,
        url: url,
        params: (type == Http.type.post) ? null : data,
        data: data,
        responseType: 'json',
        withCredentials: true,
        timeout: 20000, // 设置请求超时时间20s
      })
      .then((response: any): void => {
        let res: any = response.data ? response.data : {};
        res = Object.assign(res, {
          requestUrl: domain + url,
          interfaceUrl: url,
        });
        Http.handleHttpResponse(context, res, params);
      })
      .catch((err: any): void => {
        let status: any = -1;
        if (err.response != undefined) {
          status = err.response.status;
        } else {
          status = -1;
        }

        const res: any = {
          result: status,
          data: err.message,
          requestUrl: domain + url,
          interfaceUrl: url,
        };
        Http.handleHttpResponse(context, res, params);
      });
    } catch (error) {
      console.log(error);
    }
  },
  handleHttpResponse: (context: any, xhr: any, params: any): void => {
    const successFunc: any = params.success && typeof params.success == 'function' ? params.success : null;
    const errorFunc: any = params.error && typeof params.error == 'function' ? params.error : null;
    const warnInfo: string = params.warn_info ? params.warn_info : '';

    const status: any = xhr.result;
    const statusText: any = xhr.data;

    if (status == 0) {
      successFunc(xhr);
      return;
    }

    if (null != errorFunc && undefined != errorFunc) {
      errorFunc(xhr);
      return;
    }

    setTimeout((): void => {
      let warn_info: string = '';
      switch (status) {
        case 21:
          // '不能邀请自己！'
          warn_info = warnInfo != '' ? warnInfo : '不能邀请自己！';
          break;
        case 26:
          warn_info = warnInfo != '' ? warnInfo : '您已加入阅读圈，快去听好故事吧！';
          break;
        case 400:
          // '请求错误！'
          warn_info = warnInfo != '' ? warnInfo : '请求错误！';
          break;
        case 401:
          // '未授权，请登录！'
          warn_info = warnInfo != '' ? warnInfo : '未授权，请登录！';
          break;
        case 403:
          // '拒绝访问！'
          warn_info = warnInfo != '' ? warnInfo : '拒绝访问！';
          break;
        case 404:
          // xhr.interfaceUrl + '，请求地址出错！'
          warn_info = warnInfo != '' ? warnInfo : xhr.interfaceUrl + '，请求地址出错！';
          break;
        case 408:
          // '请求超时！'
          warn_info = warnInfo != '' ? warnInfo : '请求超时！';
          break;
        case 500:
          // '服务器内部错误！'
          warn_info = warnInfo != '' ? warnInfo : '服务器内部错误！';
          break;
        case 501:
          // '服务未实现！'
          warn_info = warnInfo != '' ? warnInfo : '服务未实现！';
          break;
        case 502:
          // '后台服务正在更新，稍后再试'
          warn_info = warnInfo != '' ? warnInfo : '后台服务正在更新，稍后再试！';
          break;
        case 503:
          // '服务不可用！'
          warn_info = warnInfo != '' ? warnInfo : '服务不可用！';
          break;
        case 504:
          // '网关超时！'
          warn_info = warnInfo != '' ? warnInfo : '网关超时！';
          break;
        case 505:
          // 'HTTP版本不受支持！'
          warn_info = warnInfo != '' ? warnInfo : 'HTTP 版本不受支持！';
          break;
        default:
          if (statusText.search('timeout') != -1) {
            // '请求超时，请稍后尝试！'
            warn_info = warnInfo != '' ? warnInfo : '请求超时，请稍后尝试！';
          } else if (statusText.search('Network Error') != -1) {
            // '网络断开，请检查网络！'
            warn_info = warnInfo != '' ? warnInfo : '网络断开，请检查网络！！';
          } else {
            // '数据异常，请核对数据是否正常'
            warn_info = warnInfo != '' ? warnInfo : '数据异常，请核对数据是否正常！';
          }
          break;
      }
      // context.$f7.dialog.alert(warn_info);
      let contactDialog = window.f7.dialog.alert(warn_info);
      Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
        opened: true,
        dialog: contactDialog,
      });
      if (errorFunc != null) {
        errorFunc(xhr);
      }
    }, 600);
  },
  // f7ajax_post: (context: any, params: any): void => {
  //   try {
  //     const domain: string = params.domain || Http.getDomain();
  //     const type = params.type || Http.type.post;
  //     const url = params.url || '';
  //     const data = params.data || {};
  //     const async: boolean = params.hasOwnProperty('async') ? params.async : true;
  //
  //     // axios.defaults.baseURL = domain ? domain : Http.getDomain();
  //     // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  //
  //     // const successFuc = (params.success && typeof params.success == "function")
  //     //   ? params.success : null;
  //     // const errorFuc = (params.error && typeof params.error == "function")
  //     //   ? params.error : null;
  //
  //     context.$f7.request({
  //       url: domain + url,
  //       async: async,
  //       data: data,
  //       method: type,
  //       contentType: 'application/json',
  //       dataType: 'json',
  //       timeout: 10000,
  //       success: (data: any, status: any, xhr: any): void => {
  //         console.log(data);
  //         let res: any = data.data ? data.data : {};
  //         res = Object.assign(res, {
  //           requestUrl: domain + url,
  //           interfaceUrl: url,
  //         });
  //         Http.handleHttpResponse(context, res, params);
  //       },
  //       error: (xhr: any, status: any, message: any): void => {
  //         let status_code: any = -1;
  //         if (xhr.response != undefined) {
  //           status_code = status;
  //         } else {
  //           status_code = -1;
  //         }
  //
  //         const res: any = {
  //           result: status_code,
  //           data: message,
  //           requestUrl: domain + url,
  //           interfaceUrl: url,
  //         };
  //         Http.handleHttpResponse(context, res, params);
  //       },
  //     });
  //
  //     // context.$f7.request.setup({
  //     //   async: false,
  //     // });
  //     //
  //     // context.$f7.request.post(domain + url, data)
  //     // .then((response: any): void => {
  //     //   let res: any = response.data ? response.data : {};
  //     //   res = Object.assign(res, {
  //     //     requestUrl: domain + url,
  //     //     interfaceUrl: url,
  //     //   });
  //     //   Http.handleHttpResponse(context, res, params);
  //     // }).catch((err: any): void => {
  //     //   let status: any = -1;
  //     //   if (err.response != undefined) {
  //     //     status = err.response.status;
  //     //   } else {
  //     //     status = -1;
  //     //   }
  //     //
  //     //   const res: any = {
  //     //     result: status,
  //     //     data: err.message,
  //     //     requestUrl: domain + url,
  //     //     interfaceUrl: url,
  //     //   };
  //     //   Http.handleHttpResponse(context, res, params);
  //     // });
  //
  //   } catch (e) {
  //     console.log(e);
  //   }
  // },
};

export default Http;


  import {Options, Vue} from "vue-class-component";
  import {Prop} from "vue-property-decorator";
  import StorageUtils from "@/js/utils/storage.ts";
  import Utils from "@/js/utils/utils.ts";
  import Http from "@/js/utils/http.ts";

  @Options({})
  export default class HistoryList extends Vue {
    // 父组件传值接收
    @Prop(String) title!: string;
    @Prop(Boolean) isMedia!: boolean;
    @Prop(Array) list!: object[];
    @Prop(Object) userInfo!: any;

    // 子组件的data
    name: string = 'historyList';

    created(): void {
    }

    tipAlbumPage(item: any): void {
      if (item.type == 'sold_out') {
        let contactDialog = window.f7.dialog.confirm('该专辑已下架，是否删除该条历史记录？', (): void => {
          this.deleteHistoryItem(item);
        }, (): void => {
        });
        Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
          opened: true,
          dialog: contactDialog,
        });
      } else {
        // 收费的且不是试听的 没买 且不是会员了
        if (item.audio && item.audio.is_free == false && !item.is_buy && !(this.userInfo.member_code && this.userInfo.is_expire == 0)) {
          let contactDialog = window.f7.dialog.confirm('您的vip会员已到期，待您续费或购买专辑后，方能继续收听', (): void => {
            const data: any = {
              id: (item.apid || -1),
              title: item.title || "",
              from: 'home',
            };
            StorageUtils.updateByModule('album.query', data);
            Utils.load(this, '/album');
          }, (): void => {
          });
          Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
            opened: true,
            dialog: contactDialog,
          });
        } else {
          const data: any = {
            from: 'history',
            is_buy: item.is_buy,
            is_member: this.userInfo.member_code != null,
            startPlayTime: item.audio.currentTime ? parseFloat(item.audio.currentTime) : 0,
            album: {
              id: item.apid || -1,
              title: item.title || '',
              cover: item.cover || [],
              is_free: item.is_free || false,
              is_cycle: item.is_cycle || false,
              is_buy: item.is_buy,
              money: item.money,
              pid: item.pid || 0,
              type: item.type || '',
              subtitle: item.subtitle || '',
            },
            audio: item.audio,
            id: item.audio.id || -1,
            title: item.audio.title || "",
            ind: item.index - 1,
          };
          StorageUtils.updateByModule('album.play', data);

          StorageUtils.updateByModule('audioDom.audioPlaying', true)
          Utils.fireEvent(Utils.kEvents.changeAlbumPlayer, {
            isFixed: false,
            album: data.album,
            audio: item.audio,
            playData: data,
          });
          Utils.fireEvent(Utils.kEvents.updatePopupShow, true);
        }
      }
    }

    sureDeleteHistoryItem(item: any): void {
      if (item.is_soldout == true) {
        window.f7.toast.create({
          text: '专辑已下架，删除后，您将无法收听了哦~~~',
          closeTimeout: 2000,
          position: "center",
        }).open();
        return;
      }

      let contactDialog = window.f7.dialog.confirm('确定删除该条历史记录？', (): void => {
        this.deleteHistoryItem(item);
      }, (): void => {
      });
      Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
        opened: true,
        dialog: contactDialog,
      });
    }

    deleteHistoryItem(item: any): void {
      if (!item || !item.hasOwnProperty('id')) return;
      const data: any = {
        ids: [item.id]
      };
      const successDelete: any = (res: any): void => {
        this.$emit('updateHistoryList', true);
      };
      const params: any = {
        url: Http.kInterfacePaths.history.del,
        data: data,
        warn_info: '删除历史记录失败！请稍后尝试',
        success: successDelete,
      };
      Http.ajax(this, params);
    }
  }

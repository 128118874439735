
  // import {ref, onMounted} from 'vue';
  import {getDevice} from 'framework7/lite-bundle';
  import cordovaApp from '../js/cordova-app';
  import {Options, Vue} from "vue-class-component";
  import {Watch} from "vue-property-decorator";
  import AudioPlayer from "@/components/audio-player.vue";
  import routes from '../js/routes';
  import store from '../js/store';
  import StorageUtils from '@/js/utils/storage';
  import CommonUtils from '@/js/common/common';
  import Utils from '@/js/utils/utils';
  import Http from "@/js/utils/http";

  @Options({
    components: {
      AudioPlayer,
    }
  })
  export default class App extends Vue {
    isApp: boolean = Http.isApp;
    device: any = getDevice();
    // Framework7 Parameters
    f7params: any = {
      name: '快乐听吧', // App name
      theme: 'auto', // Automatic theme detection

      id: 'cn.kuailetingba.szlb', // App bundle ID
      // App store
      store: store,
      // App routes
      routes: routes,

      // Input settings
      input: {
        scrollIntoViewOnFocus: this.device.cordova && !this.device.electron,
        scrollIntoViewCentered: this.device.cordova && !this.device.electron,
      },
      // Cordova Statusbar settings
      statusbar: {
        iosOverlaysWebView: true,
        androidOverlaysWebView: false,
      },
      // Toast settings
      toast: {
        closeTimeout: 2000,
      },
      // Touch settings
      touch: {
        // tapHold: true, // enable tap hold events (Long Tap)
        mdTouchRipple: false, // 点击 波纹效果
      },
      // Dialog default settings
      dialog: {
        title: '快乐听吧',
        buttonOk: '确定',
        buttonCancel: '取消',
      },
      // Calendar settings
      calendar: {
        toolbarCloseText: '确定',
        // formatValue: function (values) {
        // len = values.length;
        // switch (len) {
        //   case 1:
        //     return 'yyyy-mm-dd';
        //     break;
        //   case 2:
        //     // return 'yyyy-mm-dd 至 yyyy-mm-dd';
        //     break;
        // }
        // }
      },
    };

    // TODO.start
    historyUrl: any[] = [];// 历史页面 url
    curPagePath: string = '/';// 当前页面 url 地址
    urlDataByHttp: string = '';//初始打开界面时 url 所带的data参数的值
    albumInfo: any = {}; // 要播放的音频的所属专辑信息
    audioInfo: any = {}; // 要播放音频的音频信息
    audioPlayData: any = {}; // 要播放音频的附带信息
    playerAudioInfo: any = null; // 要播放的音频所有信息
    audioOpenStatus: boolean = false;//音频是否打开的状态
    audioOpenMinStatus: boolean = false;// 音频处于最小化状态
    audioPlayListStatus: boolean = false;// 播放页的播放列表页是否被打开
    pageHomeActiveTab: string = 'home'; // home页展示的tab页面
    isToolbarShow: boolean = false; // 底部是否有操作栏
    isHasPlayingAudio: boolean = false; // 是否正在播放的音频
    intervalActive: any = null; // 息屏后的定时激活对象
    userOpenId: string = ''; // 用户openid
    userInfo: any = {}; // 用户详细信息
    updateAlbumQuery: any = {}; // 用户

    //TODO.end

    mounted() {
      if (this.isApp) {
        this.curPagePath = '/login'
      }
      // Utils.load(this, '/my_coupon')
      this.urlDataByHttp = StorageUtils.getByModule('url.app_data') || '';
      this.userOpenId = StorageUtils.getByModule('user.openid') || '';
      // console.log('+++++++url data by http====', this.urlDataByHttp);

      window.f7ready(() => {
        // Init cordova APIs (see cordova-app.js)
        if (this.device.cordova) {
          cordovaApp.init(window.f7);
        }
      });

      // 上次未提交保存成功的历史收听记录，一进入时，提交一次
      const updateHistoryObj: any = StorageUtils.getByModule('update.history');
      if (updateHistoryObj != null) {
        this.updateHistoryPreviousResponse(updateHistoryObj.hasOwnProperty('data') ? updateHistoryObj.data : null);
      }

      // 浏览器返回按钮的监听
      pushHistory();
      window.addEventListener('popstate', (e) => {
        // 我监听到了浏览器的返回按钮事件啦，根据自己的需求实现自己的功能


        this.onHomeBackKeyDown();
      }, false);

      function pushHistory() {
        let state = {
          title: 'title',
          url: '#'
        };
        window.history.pushState(state, '', '#');

        history.pushState(null, '', document.URL);
        window.addEventListener('popstate', function () {
          history.pushState(null, '', document.URL);
        });
      }

      // TODO.start 监听
      window.f7.views.main.router.on('routeChange', this.onRouteChange); // 监听路由的变化
      window.f7.views.main.router.on('routeChanged', this.onRouteChanged); // 监听路由的变化后
      Utils.bindEvent(Utils.kEvents.updateHomePageName, this.onUpdateHomePage); // 监听用户当前是否在home的tab页
      Utils.bindEvent(Utils.kEvents.updatePopupShow, this.onUpdatePopupShow); // 监听用户是否打开了播放器
      Utils.bindEvent(Utils.kEvents.changeAlbumPlayer, this.onUpdateAudioPlay); // 监听用户是否更改了播放的音频
      Utils.bindEvent(Utils.kEvents.updateIsMemberInfo, this.onUpdateUserInfo); // 监听用户是否购买了会员
      Utils.bindEvent(Utils.kEvents.updateAlbumQuery, this.onUpdateAlbumQuery); // 监听用户是否购买专辑
      Utils.bindEvent(Utils.kEvents.updateAudioPlayMinStatus, this.onUpdateAudioPlayMinStatus); // 监听当前音乐播放器的大小状态
      Utils.bindEvent(Utils.kEvents.updateAlertIsShow, this.onUpdateAlertIsShow); // 监听是否有alert弹框显示
      Utils.bindEvent(Utils.kEvents.updateHomeBottomView, this.onChangeUserOpenId); // 主页底部显示历史最新记录
      // TODO.end 监听

      // // 判断本地版本号是否与当前设置代码一致，不一致刷新当前页面，一致不进行任何操作
      // const localVersion: any = StorageUtils.getByModule('app.version');
      // if (localVersion != null) {
      //   if (localVersion != Http.version) {
      //     // 刷新
      //     StorageUtils.clear();
      //     StorageUtils.updateByModule('app.version', Http.version);
      //     window.location.reload(true);
      //   }
      // } else {
      //   // 刷新
      //   StorageUtils.clear();
      //   StorageUtils.updateByModule('app.version', Http.version);
      //   window.location.reload(true);
      // }

      // 若退出时音频正在播放，计算重新进入后的音频播放进度
      const playingAudioObj: any = StorageUtils.getByModule('album.playing');
      if (playingAudioObj != null) {
        this.isHasPlayingAudio = true;
        const preTime: any = playingAudioObj.time;// 上次保存时时间戳
        const curTime: any = (new Date()).getTime(); // 当前时间戳
        // console.log('pre time===', preTime)
        // console.log('cur time===', curTime)
        // console.log('经历了多少毫秒=========', curTime - preTime)
        const diffSeconds: number = (curTime - preTime) / 1000; // 相差秒数
        let item: any = playingAudioObj.data;
        item.preStartPlayTime = item.startPlayTime;
        item.startPlayTime = item.startPlayTime + (diffSeconds ? diffSeconds : 0);
        // console.log('album.play.data=========', item)
        StorageUtils.updateByModule('album.play', item);
        this.onUpdateAudioPlay(playingAudioObj.params);
        StorageUtils.deleteByModule('album.playing');
      }

      // 保持屏幕息屏后，程序处于激活状态
      window.addEventListener('visibilitychange', () => {
        // 通过这个方法来获取当前标签页在浏览器中的激活状态。
        // let intervalActive: any = null;
        if (document.hidden) {
          this.intervalActive = setInterval((): void => {
            // window.alert(document.visibilityState);
            // window.focus();
            // window.f7.toast.create({
            //   text: '息屏',
            //   closeTimeout: 500,
            //   position: 'center',
            // }).open();
          }, 1000);
        } else {
          if (this.intervalActive != null) {
            clearInterval(this.intervalActive);
          }
          setTimeout(() => {
            if (this.intervalActive != null) {
              clearInterval(this.intervalActive);
            }
          }, 2000);
        }
      });
    }

    // @Watch('userOpenId', {immediate: true, deep: true})
    onChangeUserOpenId(value: any, oldValue: any): void {
      let _this: any = this;
      // console.log('onChangeUserOpenId', this.userOpenId)
      if (_this.curPagePath == '/login' || _this.curPagePath == '/register') {
        return;
      }
      CommonUtils.getUserInfo(this, (user_info: any): void => {
        _this.userInfo = user_info;
        let data: any = {
          page: 1,
          size: 1,
          wid: user_info.id || -1,
        };
        let errorList: any = (res: any): void => {
        };
        let successList: any = (res: any): void => {
          let item: any = res.hasOwnProperty('data') ? res.data : null;
          if (item == null) return;
          if (item.type != 'sold_out') {
            const data: any = {
              from: 'history',
              is_buy: item.is_buy,
              is_member: user_info.member_code != null,
              startPlayTime: item.audio.currentTime ? parseFloat(item.audio.currentTime) : 0,
              album: {
                id: item.apid || -1,
                title: item.title || '',
                cover: item.cover || [],
                is_buy: item.is_buy,
                is_free: item.is_free || false,
                is_cycle: item.is_cycle || false,
                money: item.money,
                pid: item.pid || 0,
                type: item.type || '',
                subtitle: item.subtitle || '',
              },
              audio: {
                id: item.audio.id || -1,
                pid: item.audio.pid || -1,
              },
              ind: item.index - 1,
              id: item.audio.id || -1,
              title: item.audio.title || "",
            };
            StorageUtils.updateByModule('album.play', data);
            if (!_this.isHasPlayingAudio) {
              setTimeout(function () {
                _this.onUpdateAudioPlay({
                  isFixed: true,
                  album: data.album,
                  audio: {
                    id: item.audio.id || -1,
                    pid: item.audio.pid || -1,
                  },
                  playData: data,
                });
              }, 10)
            }
          }
        };
        let params: any = {
          url: Http.kInterfacePaths.history.last,
          data: data,
          warn_info: '获取历史最新记录失败！',
          success: successList,
          error: errorList,
        };
        Http.ajax(this, params);
      }, true);
    }

    get curAlbumIsTry(): boolean {
      let is_member: boolean = (this.userInfo.hasOwnProperty('member_code') && this.userInfo.member_code && this.userInfo.member_code != '' &&
          this.userInfo.hasOwnProperty('is_expire') && this.userInfo.is_expire == 0);// 会员在有效期内
      let albumInfo: any = this.playerAudioInfo && this.playerAudioInfo.hasOwnProperty('album') && this.playerAudioInfo.album ? this.playerAudioInfo.album : {};
      let is_free: boolean = (albumInfo.hasOwnProperty('is_free') && albumInfo.is_free);
      let is_buy: boolean = (albumInfo.hasOwnProperty('is_buy') && albumInfo.is_buy);
      if (this.updateAlbumQuery.hasOwnProperty('id') && albumInfo.hasOwnProperty('id') && this.updateAlbumQuery.id == albumInfo.id) {
        is_buy = this.updateAlbumQuery.hasOwnProperty('is_buy') && this.updateAlbumQuery.is_buy ? this.updateAlbumQuery.is_buy : is_buy;
      }

      console.log('is_buy', is_buy, albumInfo);
      console.log('curAlbumIsTry=======================', !(is_member || is_buy
          || is_free));
      return !(is_member || is_buy || is_free);
    }

    onHomeBackKeyDown() {
      let _this = (this as any);
      // if (_this.model == 'wxPay' && _this.historyUrl.length == 2) {
      //   Utils.load(_this, '/', _this.urlDataByHttp, {reloadAll: true});
      // }
      // console.log(_this.historyUrl.length);
      // console.log('is can return custom=', _this.isCanReturnCustom);

      // if (!_this.isCanReturnCustom) {
      //   return;
      // }

      // TODO.start Popup 需要关闭的地方在此处先行关闭
      const warnSharePopup: any = window.f7.popup.get('#warnSharePopup');
      if (warnSharePopup && warnSharePopup.opened) {
        window.f7.popup.close('#warnSharePopup');
        return;
      }
      const goodsNumberSheet: any = window.f7.sheet.get('#goods-number-sheet-id');
      if (goodsNumberSheet && goodsNumberSheet.opened) {
        window.f7.sheet.close('#goods-number-sheet-id');
        return;
      }
      const giftInviteWarnSharePopup: any = window.f7.popup.get('#giftInviteWarnSharePopup');
      if (giftInviteWarnSharePopup && giftInviteWarnSharePopup.opened) {
        window.f7.popup.close('#giftInviteWarnSharePopup');
        return;
      }
      const vipInviteWarnSharePopup: any = window.f7.popup.get('#vipInviteWarnSharePopup');
      if (vipInviteWarnSharePopup && vipInviteWarnSharePopup.opened) {
        window.f7.popup.close('#vipInviteWarnSharePopup');
        return;
      }
      const receiverAddressPopup: any = window.f7.popup.get('#receiverAddressPopup');
      if (receiverAddressPopup && receiverAddressPopup.opened) {
        window.f7.popup.close('#receiverAddressPopup');
        return;
      }
      const newUserCouponPopup: any = window.f7.popup.get('#new-user-coupon-popup');
      if (newUserCouponPopup && newUserCouponPopup.opened) {
        window.f7.popup.close('#new-user-coupon-popup');
        return;
      }
      const festivalCouponPopup: any = window.f7.popup.get('#festival-coupon-popup');
      if (festivalCouponPopup && festivalCouponPopup.opened) {
        window.f7.popup.close('#festival-coupon-popup');
        return;
      }
      const exclusiveCouponPopup: any = window.f7.popup.get('#exclusive-coupon-popup');
      if (exclusiveCouponPopup && exclusiveCouponPopup.opened) {
        window.f7.popup.close('#exclusive-coupon-popup');
        return;
      }
      const buyUserPopup: any = window.f7.popup.get('#buy-user-popup');
      if (buyUserPopup && buyUserPopup.opened) {
        window.f7.popup.close('#buy-user-popup');
        return;
      }
      const buyUserPopupGoods: any = window.f7.popup.get('#buy-user-popup-goods');
      if (buyUserPopupGoods && buyUserPopupGoods.opened) {
        window.f7.popup.close('#buy-user-popup-goods');
        return;
      }
      const buyUserPopupAlbum: any = window.f7.popup.get('#buy-user-popup-albun');
      if (buyUserPopupAlbum && buyUserPopupAlbum.opened) {
        window.f7.popup.close('#buy-user-popup-albun');
        return;
      }

      const coupon_sheet_vip: any = window.f7.sheet.get('#coupon_sheet_vip');
      if (coupon_sheet_vip && coupon_sheet_vip.opened) {
        window.f7.sheet.close('#coupon_sheet_vip');
        return;
      }
      const coupon_sheet_album: any = window.f7.sheet.get('#coupon_sheet_album');
      if (coupon_sheet_album && coupon_sheet_album.opened) {
        window.f7.sheet.close('#coupon_sheet_album');
        return;
      }
      const coupon_sheet_goods: any = window.f7.sheet.get('#coupon_sheet_goods');
      if (coupon_sheet_goods && coupon_sheet_goods.opened) {
        window.f7.sheet.close('#coupon_sheet_goods');
        return;
      }
      const audio_player_times: any = window.f7.sheet.get('#audio_player_times');
      if (audio_player_times && audio_player_times.opened) {
        window.f7.sheet.close('#audio_player_times');
        return;
      }
      const album_lists: any = window.f7.sheet.get('#album_lists');
      if (album_lists && album_lists.opened) {
        window.f7.sheet.close('#album_lists');
        return;
      }
      const my_sheet_sex: any = window.f7.sheet.get('#my_sheet_sex');
      if (my_sheet_sex && my_sheet_sex.opened) {
        window.f7.sheet.close('#my_sheet_sex');
        return;
      }
      const my_sheet_xz: any = window.f7.sheet.get('#my_sheet_xz');
      if (my_sheet_xz && my_sheet_xz.opened) {
        window.f7.sheet.close('#my_sheet_xz');
        return;
      }
      // TODO.end
      // alert[联系我们]弹框开启时，先关闭
      if (_this.dialogObject != null && _this.dialogObject.opened) {
        _this.dialogObject.close();
        _this.dialogObject = null;
        return;
      }

      if (_this.historyUrl.length <= 1) {
        // 播放列表的popup处于开启状态时，先关闭
        if (_this.audioPlayListStatus) {
          _this.audioPlayListStatus = false;
          return;
        }

        // 如果当前音频最大化状态，点击返回按钮则先缩小
        if (!_this.audioOpenMinStatus) {
          _this.audioOpenMinStatus = true;
          // console.log(_this.audioOpenStatus, 'audio open status');
          // console.log(_this.audioOpenMinStatus, 'audio open min status');
          return;
        }

        // console.log('current home page name=', _this.pageHomeActiveTab);
        // 首页非home页时，返回至home页
        if (_this.pageHomeActiveTab != 'home') {
          Utils.fireEvent(Utils.kEvents.changeHomeActiveTab, {active: 'home'});
          return;
        }

        history.pushState(null, '', document.URL);
        window.addEventListener('popstate', function () {
          history.pushState(null, '', document.URL);
        });
      } else {
        // 播放列表的popup处于开启状态时，先关闭
        if (_this.audioPlayListStatus) {
          _this.audioPlayListStatus = false;
          _this.pushStateToHistory();
          return;
        }

        // 如果当前音频最大化状态，点击返回按钮则先缩小
        if (!_this.audioOpenMinStatus) {
          _this.audioOpenMinStatus = true;
          _this.pushStateToHistory();
          return;
        }

        window.f7.views.main.router.back();
        // return;
      }
    }

    onRouteChange(newRoute: any, previousRoute: any, router: any): void {
      let _this = (this as any);
      StorageUtils.updateByModule('page.new', newRoute.path);
      _this.onRouteChanged(newRoute, previousRoute, router)
    }

    /**
     * 监听当前路由变化后执行的方法
     * @param newRoute 要前往的页面的 url 地址
     * @param previousRoute 从哪个页面 url 地址跳转过来的
     * @param router router 对象
     */
    onRouteChanged(newRoute: any, previousRoute: any, router: any) {
      let _this = (this as any);
      _this.curPagePath = newRoute.path;
      console.log('onRouteChanged=', _this.curPagePath)
      StorageUtils.updateByModule('page.current', _this.curPagePath);
      let historyRouterArr = router.hasOwnProperty('history')
      && router.history != null ? router.history : [];
      const historyRouterLen = historyRouterArr.length;
      _this.historyUrl = historyRouterArr;
      // console.log('History: ', _this.historyUrl);

      if (window.history && window.history.pushState) {

        // 判断当前页面底部是否显示toolbar
        window.f7.utils.nextTick((): void => {
          const curPageEl: any = document.getElementsByClassName('page-current')[0];
          if (curPageEl != null) {
            // const childIsExit: boolean = _this.getParentElement(curPageEl, 'toolbar-bottom');
            const childIsExit: boolean = Utils.getParentElement(curPageEl, 'toolbar-bottom');
            _this.isToolbarShow = !childIsExit;
          }
        });

        //判断当前页面是否处于专辑详情页，是设置音频页面最大化，否改为最小化
        if (_this.curPagePath != '/album') {
          _this.audioOpenMinStatus = true;
        }

        if (historyRouterLen < 2) {
          history.pushState(null, '', document.URL);
          window.addEventListener('popstate', function () {
            history.pushState(null, '', document.URL);
          });
        }
      }
      if (historyRouterLen > 1) {
        let preRoutePath = previousRoute.path;
        let isAddPage = false;
        for (let i in historyRouterArr) {
          if (historyRouterArr[i].indexOf(preRoutePath) > -1) {
            isAddPage = true;
          }
        }
        if (isAddPage) {
          let curLen = (historyRouterLen - 1).toLocaleString();
          let state = {
            page: curLen,
          };
          window.history.pushState(state, _this.curPagePath,
              '?page=' + curLen);
        } else {
        }
      } else {
        let curUrl = window.location.href;
        if (curUrl.indexOf('?page=') > -1) {
          window.history.pushState({}, _this.curPagePath,
              '/?data=' + _this.urlDataByHttp);
        }
      }
    }

    // 更新url链接的state值
    pushStateToHistory(): void {
      let _this = (this as any);
      let curLen: string = (_this.historyUrl.length - 1).toLocaleString();
      let state = {
        page: curLen,
      };
      window.history.pushState(state, _this.curPagePath, '?page=' + curLen);
    }

    /**
     * 监听到Popup已经打开
     */
    audioPlayOpened(): void {
      this.audioOpenStatus = true;
    }

    /**
     * 监听到Popup已经关闭
     */
    audioPlayClosed(): void {
      this.audioOpenStatus = false;
    }

    /**
     * 监听到home页面当前展示tab修改了
     * @param params
     */
    onUpdateHomePage(params: any): void {
      let _this = (this as any);
      _this.pageHomeActiveTab = params.hasOwnProperty('active') ? params.active : 'home';
      StorageUtils.updateByModule('home.tab', _this.pageHomeActiveTab);
    }

    onUpdatePopupShow(params: any): void {
      let _this = (this as any);
      // console.log('onUpdatePopupShow----------------------打开播放页');
      if (_this.playerAudioInfo) {
        _this.audioOpenStatus = true;
        _this.audioOpenMinStatus = false;
        StorageUtils.updateByModule('audioPlay.isOpened', true);
        if (!_this.audioOpenMinStatus) {
          let playAudioInfo: any = {
            isFixed: _this.audioOpenMinStatus,
            album: _this.playerAudioInfo.album,
            audio: _this.playerAudioInfo.audio,
            playData: _this.playerAudioInfo.playData || {},
          };
          StorageUtils.updateByModule('audioPlay.open', playAudioInfo);
          // console.log('playAudioInfo=========================================', playAudioInfo)
          const t: any = {
            id: (playAudioInfo.album.id || -1),
            title: playAudioInfo.audio.title || "",
            subtitle: playAudioInfo.album.subtitle || "",
            thumb: (playAudioInfo.album.cover && playAudioInfo.album.cover.length > 0) ? (playAudioInfo.album.cover[0].url || '') : '',
            from: 'audio-play',
            noNeedSignature: true,
          };
          CommonUtils.setWechatShare(this, t);
        }
      }
    }

    changePlayListStatus(params: any): void {
      let _this = (this as any);
      _this.audioPlayListStatus = params ? true : false;
    }

    /**
     * 监听播放的专辑与音频的更改
     * @param params
     */
    onUpdateAudioPlay(params: any): void {
      let _this = (this as any);
      _this.audioOpenMinStatus = (params.hasOwnProperty('isFixed') && params.isFixed);
      _this.playerAudioInfo = params;
      _this.audioOpenStatus = true;
      // console.log('_this.playerAudioInfo==', _this.playerAudioInfo)
      if (!_this.audioOpenMinStatus) {
        StorageUtils.updateByModule('audioPlay.open', params);
      } else {
        StorageUtils.deleteByModule('audioPlay.open');
      }
    }

    updateHistoryPreviousResponse(data: any): void {
      let _this = (this as any);
      if (data != null) {
        if ((data.hasOwnProperty('index') && data.index <= 0) || (data.hasOwnProperty('wid') && data.wid <= 0) || (data.hasOwnProperty('aid') && data.aid <= 0)) return;
        const errorUpdate: any = (res: any): void => {
          StorageUtils.deleteByModule('update.history');
        };
        const successUpdate: any = (res: any): void => {
          Utils.fireEvent(Utils.kEvents.changeHistoryList, true);
          _this.isUpdateHistory = false;
          StorageUtils.deleteByModule('update.history');
        };
        const params: any = {
          url: Http.kInterfacePaths.history.add,
          data: data,
          warn_info: '更新历史记录失败！',
          error: errorUpdate,
          success: successUpdate,
        };
        Http.ajax(_this, params);
      }
    }

    refreshChangeAudioInfo(params: any): void {
      let _this = (this as any);
      if (params != null) {
        // _this.audioInfo = params;
        _this.playerAudioInfo = {
          isFixed: _this.playerAudioInfo.isFixed,
          album: _this.playerAudioInfo.album,
          audio: params,
          playData: _this.playerAudioInfo.playData || {},
        };
      }
    }

    /**
     * 监听用户信息是否更改，如果更改，重新请求一遍用户信息并保存至本地
     * @param params
     */
    onUpdateUserInfo(params: any): void {
      let _this = (this as any);
      if (typeof params == "boolean" && params) {
        CommonUtils.getUserInfo(this, (user_info: any): void => {
          _this.userInfo = user_info;
        }, true);
      }
    }

    onUpdateAlbumQuery(params: any): void {
      let _this = (this as any);
      _this.updateAlbumQuery = params;
    }

    onUpdateAudioPlayMinStatus(params: any): void {
      let _this = (this as any);
      _this.audioOpenMinStatus = (params.hasOwnProperty('is_min') && params.is_min);
      StorageUtils.updateByModule('audioPlay.isOpened', false);
    }

    onUpdateAlertIsShow(params: any): void {
      let _this = (this as any);
      if (params.opened) {
        _this.dialogObject = params.dialog || null;
      }
    }

  }

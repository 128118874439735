<template>
  <f7-page>
    <f7-navbar title="Not found" back-link="Back"></f7-navbar>
    <f7-block strong>
      <p>Sorry</p>
      <p>Requested content not found.</p>
    </f7-block>
  </f7-page>
</template>
<script lang="ts">
  import {Options, Vue} from "vue-class-component";
  import CommonUtils from "@/js/common/common";

  @Options({})
  export default class NotFound extends Vue {
    name: string = 'not-found';

    created(): void {
      this.$nextTick((): void => {
        CommonUtils.setWechatShare(this);
      });
    }
  };
</script>

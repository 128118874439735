<template>
  <f7-page-content id="person" tab :tab-active="currentPageName=='person'"
                   @tab:show="changeCurrentPageName"
                   style="background-color: #f3f3f3;height: 100% !important;">
    <div class="person-info-head-frame">
      <div class="person-info-orange"></div>
      <f7-list media-list class="person-info-frame">
        <f7-list-item @click="tipVipOpenPage">
          <template #media>
            <img
                :src="userInfo.headimg_url?userInfo.headimg_url:''"
                width="70" style="border-radius:100%;" alt="头像"/>
          </template>
          <template #title>
            {{userInfo.nickname||''}}
            <span class="person-info-avatar">
              <img :src="vipImgUrl" alt="vip"/>
            </span>
            <span class="member_over"
                  v-if="userInfo.member_code && userInfo.is_expire==1">已到期</span>
          </template>
          <template #subtitle>
            <!--{{userInfo.invite_code?'会员邀请码：'+userInfo.invite_code:''}}-->
            {{userInfo.member_code?'会员码：'+userInfo.member_code:''}}
          </template>
          <template #root>
            <div style="padding:0px 16px"
                 v-if="userInfo.hasOwnProperty('member_code')&&userInfo.member_code && userInfo.is_expire==0">
              <div
                  style="width:100%;height:1px;background-color:#e5e5e5;"></div>
              <p style="line-height:3em;height:3em;font-size:14px;">
                会员期限：{{userInfo.expire_time&&userInfo.expire_time=='lifetime'?'永久有效':userInfo.expire_time}}</p>
            </div>
            <div style="width:100%;height:60px;position:relative;" @click="tipVipOpenPage"
                 v-if="!(userInfo.hasOwnProperty('member_code')&&userInfo.member_code && userInfo.is_expire==0)">
              <div class="person-decorate-gray"></div>
              <div class="person-decorate-yellow-box">
                <div class="person-decorate-yellow">
                <span class="person-info-avatar">
                  <img :src="vipGoldImgUrl" alt="vip"/>
                </span>
                </div>
              </div>
              <div class="person-decorate-black-box" style="overflow:hidden;">
                <div class="person-decorate-black"></div>
                <div class="person-decorate-black2">
                  <span>会员特权</span>
                  <f7-button fill round small>
                    立即开通
                  </f7-button>
                </div>
              </div>
            </div>
          </template>
        </f7-list-item>
      </f7-list>
      <f7-row class="person-vip-info">
        <f7-col class="person-vip-item text-center"
                v-for="el in vipInfoList"
                :key="el.id">
          <img :src="el.icon" :alt="el.title">
          <p>{{el.title}}</p>
        </f7-col>
      </f7-row>
      <h3>我的服务</h3>
      <f7-row class="person-vip-info">
        <f7-col width="50" class="person-vip-item text-center"
                v-for="(el,index) in serveList"
                :key="'serve_'+el.id"
                @click="tipMyServeItem(el.type)"
                :style="{'margin-bottom': ((index<serveList.length-2)?'16px': '0px')}">
          <span>
            <img :src="el.icon" :alt="el.title" :class="'img_'+el.type">
            <span
                style="line-height:50px;display:inline-block;">{{el.title}}</span>
          </span>
        </f7-col>
      </f7-row>
    </div>
  </f7-page-content>
</template>
<script lang="ts">
  import {Options, Vue} from "vue-class-component";
  import {Prop} from "vue-property-decorator";
  import Utils from "@/js/utils/utils.ts";
  import CommonUtils from "@/js/common/common.ts";
  import Http from "@/js/utils/http.ts";

  @Options({
    components: {},
  })
  export default class Person extends Vue {
    // 父组件传值接收
    @Prop(String) currentPageName!: string;
    userInfo: any = {};
    contactDialog: any = null;
    // vipImgUrl: string = './static/img/vip_orange.png';
    // vipGoldImgUrl: string = './static/img/vip_gold.png';
    vipImgUrl: any = require('@/assets/img/vip_orange.png').default;
    vipGoldImgUrl: any = require('@/assets/img/vip_gold.png').default;
    vipInfoList: any[] = [
      {
        id: 1,
        title: '无时限',
        icon: require('@/assets/img/my_wushixian.png').default,
      },
      {
        id: 2,
        title: '无广告',
        icon: require('@/assets/img/my_wuguanggao.png').default,
      },
      {
        id: 3,
        title: '全畅听',
        icon: require('@/assets/img/my_quanchangting.png').default,
      },
    ];
    serveList: any[] = [
      {
        id: 1,
        type: 'order',
        title: '我的订单',
        icon: require('@/assets/img/my_dingdan.png').default,
      },
      {
        id: 2,
        type: 'coupon',
        title: '优惠券',
        icon: require('@/assets/img/my_youhuiquan.png').default,
      },
      {
        id: 3,
        type: 'person',
        title: '个人信息',
        icon: require('@/assets/img/my_gerenxinxi.png').default,
      },
      {
        id: 4,
        type: 'server',
        title: '客服',
        icon: require('@/assets/img/my_kefu.png').default,
      },
    ];

    created(): void {
      CommonUtils.getUserInfo(this, (params: any): void => {
        this.userInfo = params;
      });
    }

    mounted(): void {
      Utils.bindEvent(Utils.kEvents.updatePersonUserInfo, this.onUpdateUserInfo); // 监听用户是否购买了会员
    }

    changeCurrentPageName(): void {
      this.$emit('changeActivePageName', 'person');
    }

    /**
     * 监听用户信息是否更改，如果更改，重新请求一遍用户信息并保存至本地
     * @param params
     */
    onUpdateUserInfo(params: any): void {
      let _this = (this as any);
      if (typeof params == "boolean" && params) {
        CommonUtils.getUserInfo(this, (user_info: any): void => {
          _this.userInfo = user_info;
        }, true);
      }
    }

    tipMyServeItem(type: string): void {
      switch (type) {
        case 'order':
          // 我的订单
          Utils.load(this, '/my_order');
          break;
        case 'coupon':
          // 优惠券
          Utils.load(this, '/my_coupon');
          break;
        case 'person':
          // 个人信息
          Utils.load(this, '/my');
          break;
        case 'server':
          // 客服
          this.getContactUs();
          break;
      }
    }

    getContactUs(): void {
      const data: any = {};
      const successLast: any = (res: any): void => {
        const resData: any = res && res.data ? res.data : {};
        // console.log(resData);
        if (this.contactDialog == null) {
          this.contactDialog = window.f7.dialog.create({
            closeByBackdropClick: true,
            title: resData.hasOwnProperty('title') && resData.title ? resData.title : '快乐听吧',
            text: '',
            content:
                `<div style="width:100%;padding-top:12px;" class="text-align-center">
                      <img src="` +
                (resData.image && resData.image.length > 0 ? resData.image[0].url : '')
                +
                `" alt="客服二维码" style="width:100%;display: inline-block;"/>
                      <p style="margin:0px;">长按识别二维码，联系客服</p>
                    </div>`,
            on: {
              opened: function () {
              }
            }
          });
          this.contactDialog.open();
          Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
            opened: true,
            dialog: this.contactDialog,
          });
        } else {
          this.contactDialog.open();
          Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
            opened: true,
            dialog: this.contactDialog,
          });
        }
      };
      const params: any = {
        url: Http.kInterfacePaths.service.last,
        data: data,
        warn_info: '获取客服信息失败，请稍后尝试！',
        success: successLast,
      };
      Http.ajax(this, params);
    }

    tipVipOpenPage(): void {
      // if (this.userInfo.hasOwnProperty('member_code') && this.userInfo.member_code) {
      //   Utils.load(this, '/vip');
      // }
      Utils.load(this, '/vip');
    }
  }
</script>


  import {Options, Vue} from "vue-class-component";
  import Http from "@/js/utils/http";
  import Utils from "@/js/utils/utils";
  import CommonUtils from "@/js/common/common";
  import {f7, f7ready} from 'framework7-vue';
  import {Watch} from "vue-property-decorator";
  import DateUtils from "@/js/utils/date";
  import StorageUtils from "@/js/utils/storage";
  import StringUtils from "@/js/utils/string";

  @Options({
    components: {}
  })
  export default class Login extends Vue {
    account: string = '';
    password: string = '';
    // logoUrl: string = './static/img/logo.jpg';
    // logo_wechat: string = './static/img/logo_wechat.png';
    logoUrl: any = require('@/assets/img/logo.png').default;
    logo_wechat: any = require('@/assets/img/logo_wechat.png').default;
    type: string = 'password'; // password code wechat
    code: string = '';
    codeText: string = '获取验证码';
    num: number = 60;

    mounted(): void {
    }

    created(): void {
      window.f7 = f7;
      window.f7ready = f7ready;
      this.$nextTick((): void => {
        CommonUtils.setWechatShare(this);
      });
    }

    wechatLogin(): void {
      window.f7.toast.create({
        text: '功能开发中...',
        closeTimeout: 2000,
        position: "center",
      }).open();
    }

    // 验证码
    getCode(): void {
      let _this = this;
      let codebtnval = _this.codeText;

      if (!(codebtnval == '获取验证码' || codebtnval == '重新发送')) {
        return;
      }
      if (_this.account == null || _this.account
          == "") {
        window.f7.dialog.alert('手机号不能为空！');
        return;
      }
      if (!_this.checkMobile(_this.account)) {
        window.f7.dialog.alert('手机号格式不正确');
        return;
      }

      if (codebtnval == '获取验证码' || codebtnval == '重新发送') {
        window.f7.preloader.show();
        let Data = {
          mobile: _this.account,
        };

        let params = {
          url: Http.kInterfacePaths.user.captcha,
          data: Data,
          warn_error: '验证码发送失败！',
          success: _successRegister
        };

        // Http.ajax(_this, params);

        function _successRegister(res: any) {
          window.f7.preloader.hide();
          //已发送，正在倒计时 //res.data.captcha
          _this.codeBtn_num();
        }

        _successRegister(1);
      }
    }

    codeBtn_num(): void {
      let _this = this;
      let num = _this.num;
      let str = '' + num + 's';
      _this.codeText = str;
      setTimeout(_this.setTimeOut, 1000);
    }

    setTimeOut(): void {
      let _this = this;
      let num = _this.num - 1;
      _this.num = num;
      if (num <= 0) {
        let str = '重新发送';
        _this.num = 60;
        _this.codeText = str
      } else {
        let str = '' + num + 's';
        _this.codeText = str;
        setTimeout(_this.setTimeOut, 1000);
      }
    }

    checkMobile(phone: string): boolean {
      //正则：验证电话号码手机号码，包含至今所有号段
      let reg = /^(1[3|4|5|6|7|8|9])[\d]{9}$/;
      if (!reg.test(phone)) {//alert("电话号码格式错误!");obj.value="";obj.focus();
        return false;
      } else {//alert("您的电话号码:"+phone);
        return true;
      }
    }

    loginType(): void {
      switch (this.type) {
        case 'password':
          this.type = 'code';
          break;
        case 'code':
          this.type = 'password';
          break;
        default:
          this.type = 'password';
          break;
      }
    }

    login(): void {
      const isAccountNull: boolean = (this.account == '');
      if (isAccountNull) {
        window.f7.dialog.alert('手机号不能为空！');
        return;
      }
      const data: any = {
        mobile: this.account,
        password: this.password,
      };
      const successLogin: any = (res: any): void => {
        Utils.load(this, '/', null, {reloadAll: true});
      };
      const params: any = {
        url: Http.kInterfacePaths.user.login,
        data: data,
        warn_info: '登录失败！',
        success: successLogin,
      };
      // Http.ajax(this, params);
      successLogin();
    }
  }

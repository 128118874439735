<template>
  <f7-page-content id="home" tab :tab-active="currentPageName=='home'"
                   @tab:show="changeCurrentPageName">
    <f7-block v-if="banners.length>0" class="home-block">
      <banner-list divId="homeBanner" :banners="banners"
                   :params="bannerOptions"
                   @clickBannerItem="onUpdateBannerClick"
      ></banner-list>
    </f7-block>
    <f7-block class="home-block">
      <f7-row no-gap>
        <f7-col width="25" v-for="(el,index) in modelList" :key="el.id"
                class="text-center" @click="tipModelItemBy(el.type)">
          <img :src="el.icon" :alt="el.title" class="home-model-img">
          <p class="home-model-title">{{el.title}}</p>
        </f7-col>
      </f7-row>
    </f7-block>
    <album-list title="本期推荐" :albums="albums"></album-list>
  </f7-page-content>
</template>
<script lang="ts">
  import {Options, Vue} from 'vue-class-component';
  import {Prop, Watch} from 'vue-property-decorator';
  import PullInfiniteAudiosPage from '@/pages/test_pull_down.vue';
  import AlbumList from "@/components/album-list.vue";
  import BannerList from "@/components/banner-list.vue";
  import StorageUtils from "@/js/utils/storage.ts";
  import Http from "@/js/utils/http";
  import Utils from "@/js/utils/utils";

  @Options({
    components: {
      PullInfiniteAudiosPage,
      AlbumList,
      BannerList,
    },
  })
  export default class Person extends Vue {
    // 父组件传值接收
    @Prop(String) currentPageName!: string;
    pageSize: number = 6;
    curPage: number = 1;
    totalFreeRow: number = 0;
    totalNotFreeRow: number = 0;
    albums: any [] = [];
    albums_free: any[] = [];
    banners: any [] = [];
    bannerOptions: any = {
      isLoop: true,
      isAutoPlay: true,
    };
    payAlbums: any[] = [];
    totalPayRow: number = 0;
    modelList: any[] = [
      {
        id: 1,
        type: 'sale',
        title: '限时优惠',
        icon: require('@/assets/img/home_xianshiyouhui.png').default,
      },
      {
        id: 2,
        type: 'vip',
        title: 'VIP会员',
        icon: require('@/assets/img/home_zhongshenhuiyuan.png').default,
      },
      {
        id: 3,
        type: 'goods',
        title: '心选好物',
        icon: require('@/assets/img/home_xinxuanhaowu.png').default,
      },
      {
        id: 4,
        type: 'album',
        title: '全部专辑',
        icon: require('@/assets/img/home_quanbuzhuanji.png').default,
      },
    ];

    @Watch('currentPageName', {immediate: true, deep: true})
    onTabIsShowChanged(val: any, oldVal: any): void {
      if (val == 'home') {
        this.getBannerList();
        // this.getAlbumListBy(this.curPage, 'fineGoods');
        // this.getAlbumListBy(this.curPage, 'free');
        this.getAlbumListBy(this.curPage, 'album');
      }
    }

    changeCurrentPageName(): void {
      this.$emit('changeActivePageName', 'home');
    }

    getAlbumListBy(cur_page: number, type: string): void {
      let data: any = {
        page: cur_page,
        size: this.pageSize,
        filter: [
          {
            type: type,
            pid: 0,
          },
          {
            is_soldout: false,
          }
        ],
        search: [{
          // keyWord: '',
          // logical:'',
        }],
      };
      let successList: any = (res: any): void => {
        let totalRows: number = res.hasOwnProperty('rows') ? res.rows : 0;

        let curResData = res.hasOwnProperty('data') ? res.data : [];
        switch (type) {
          case 'fineGoods':
            //精选好物
            this.payAlbums = curResData;
            this.totalPayRow = totalRows;
            break;
          case 'free':
            //免费
            this.albums_free = curResData;
            this.totalFreeRow = totalRows;
            break;
          case 'album':
            //付费专辑，会员精选
            this.albums = curResData;
            this.totalNotFreeRow = totalRows;
            break;
            // case 'audio':break;
        }
      };
      let params: any = {
        url: Http.kInterfacePaths.album.list,
        data: data,
        warn_info: '获取专辑记录失败！',
        success: successList,
      };
      Http.ajax(this, params);
    }

    tipAlbumPage(item: any, type: string): void {
      switch (item.appid) {
        case 1: //1为专辑
          const data: any = {
            id: type == 'banner' ? (item.mid || -1) : (item.id || -1),
            title: item.title || "",
            from: 'home',
          };
          StorageUtils.updateByModule('album.query', data);
          Utils.load(this, '/album');
          break;
        case 4: //4为心选好物
          item.id = item.mid;
          StorageUtils.updateByModule('goods.query', item);
          Utils.load(this, '/goods_detail', {
            id: item.id
          });
          break;
        default :
          break;
      }
    }

    getBannerList(): void {
      const data: any = {
        // type: "cycle",
      };
      let successList: any = (res: any): void => {
        const resData: any[] = res.data || [];
        this.banners = resData;
      };
      let params: any = {
        url: Http.kInterfacePaths.file.list,
        data: data,
        warn_info: '获取轮播图记录失败！',
        success: successList,
      };
      Http.ajax(this, params);
    }

    tipModelItemBy(type: string): void {
      switch (type) {
        case "sale":
          // 限时优惠
          Utils.load(this, '/limited_offer');
          break;
        case "vip":
          // VIP会员
          Utils.load(this, '/vip');
          break;
        case "goods":
          // 心选好物
          Utils.load(this, '/goods');
          break;
        case "album":
          // 全部专辑
          Utils.load(this, '/album_all');
          break;
      }
    }

    onUpdateBannerClick(params: any): void {
      this.tipAlbumPage(params, 'banner');
    }

  }
</script>


  import {Options, Vue} from "vue-class-component";
  import {Prop, Watch} from "vue-property-decorator";

  @Options({})
  export default class WarnShare extends Vue {
    @Prop({default: 'warnSharePopup'}) idName!: string;
    @Prop({default: false}) isShareWarn!: boolean;
    name: string = "warn-share";
    // arrowUpUrl: string = './static/img/arrow_warning.png';
    // wechatFriendsUrl: string = './static/img/wechat_friends.png';
    // wechatFriendCircleUrl: string = './static/img/wechat_friend_circle.png';
    arrowUpUrl: any = require('@/assets/img/arrow_warning.png').default;
    wechatFriendsUrl: any = require('@/assets/img/wechat_friends.png').default;
    wechatFriendCircleUrl: any = require('@/assets/img/wechat_friend_circle.png').default;

    @Watch('isShareWarn', {immediate: true, deep: true})
    onUpdateIsShareWarn(val: any, oldVal: any): void {
      console.log('====is show warn========', this.isShareWarn);
      if (val) {
        window.f7.popup.open('#' + this.idName);
      } else {
        window.f7.popup.close('#' + this.idName);
      }
    }

    changeShareWarnShowStatus(is_show: boolean): void {
      this.$emit('updateShowStatus', is_show);
    }
  }


  import {Options, Vue} from "vue-class-component";
  import CommonUtils from "@/js/common/common";

  @Options({})
  export default class NotFound extends Vue {
    name: string = 'not-found';

    created(): void {
      this.$nextTick((): void => {
        CommonUtils.setWechatShare(this);
      });
    }
  };

<template>
  <f7-block class="home-block">
    <h2 v-if="title&&title!=''">{{title}}</h2>
    <f7-row class="home-album-recommend-list" v-if="albums.length>0"
            style="padding-bottom:16px;">
      <f7-col width="33" v-for="item in albums"
              :key="item.id" @click="tipAlbumPage(item)">
        <div class="home-album-item-image image-tblr-center"
             :style="{'backgroundImage':((item.cover.length>0)?'url(\''+(item.cover[0].thumb||item.cover[0].url)+'\')':'unset'),
             'filter':((item.cover.length>0)?'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\''+(item.cover[0].thumb||item.cover[0].url)+'\',sizingMethod=scale)':'unset'),
             '-ms-filter':((item.cover.length>0)?'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\''+(item.cover[0].thumb||item.cover[0].url)+'\',sizingMethod=scale)':'unset')}"
        >
          <div class="album-limited-offer-icon"
               v-if="item.hasOwnProperty('time_limit_discount_status')&&item.time_limit_discount_status==1">
            <img :src="limitedOfferIcon" alt="限时优惠图标">
          </div>
          <div class="home-album-mask"
               v-if="!item.is_free&&(item.time_limit_discount_status==1?item.time_limit_discount_price:item.money)">
            <span>
              {{!item.is_free&&(item.time_limit_discount_status==1?item.time_limit_discount_price:item.money)?'￥'+(item.time_limit_discount_status==1?item.time_limit_discount_price:item.money):'免费'}}
            </span>
          </div>
        </div>
        <h4 class="text-ellipsis-1">{{item.title||''}}</h4>
        <p class="text-ellipsis-1">{{item.subtitle||''}}</p>
      </f7-col>
      <f7-col width="33" v-for="el in (albums.length%3)"></f7-col>
    </f7-row>
  </f7-block>
</template>

<script lang="ts">
  import {Options, Vue} from "vue-class-component";
  import {Prop} from "vue-property-decorator";
  import StorageUtils from "@/js/utils/storage";
  import Utils from "@/js/utils/utils";

  @Options({})
  export default class AlbumList extends Vue {
    @Prop(String) title!: string;
    @Prop(Object) albums!: any[];
    name: string = "album-list";
    // limitedOfferIcon: string = './static/img/icon_limited_offer1.png';
    limitedOfferIcon: any = require('@/assets/img/icon_limited_offer1.png').default;

    tipAlbumPage(item: any): void {
      const data: any = {
        id: (item.id || -1),
        title: item.title || "",
        from: 'home',
      };
      StorageUtils.updateByModule('album.query', data);
      Utils.load(this, '/album');
    }
  }
</script>

<style scoped>

</style>

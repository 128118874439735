
  import {f7, f7ready} from 'framework7-vue';
  import {Options, Vue} from "vue-class-component";
  import {Watch} from "vue-property-decorator";
  import Recommend from '@/components/recommend.vue';
  import History from '@/components/history.vue';
  import Vip from '@/components/vip.vue';
  import Person from '@/components/person.vue';
  import TitleShare from "@/components/title-share.vue";
  import Utils from "@/js/utils/utils.ts";
  import CommonUtils from "@/js/common/common.ts";
  import DateUtils from "@/js/utils/date";
  import Http from "@/js/utils/http";
  import StorageUtils from "@/js/utils/storage";
  import StringUtils from "@/js/utils/string";
  // import VConsole from 'vconsole';

  @Options({
    components: {
      Recommend,
      History,
      Vip,
      Person,
      TitleShare,
    },
  })
  export default class Home extends Vue {
    isApp: boolean = Http.isApp;
    pageName: string = 'home';
    // logoUrl: string = './static/img/logo.jpg';
    logoUrl: any = require('@/assets/img/logo.png').default;
    couponList: any[] = [];
    couponPopup: any = null;
    couponPopupOpened: boolean = false;
    newUserPopupCouponList: any[] = [];
    newUserPopup: any = null;
    newUserPopupOpened: boolean = false;
    exclusiveCouponList: any[] = [];
    exclusiveCouponPopup: any = null;
    exclusiveCouponPopupOpened: boolean = false;
    userInfo: any = {};
    // couponBg: string = './static/img/coupon_bg1.png';
    // couponGrayBg: string = './static/img/coupon_bg2.png';
    // couponDateIcon: string = './static/img/coupon_date.png';
    // couponUsedIcon: string = './static/img/coupon_used.png';
    couponBg: any = require('@/assets/img/coupon_bg1.png').default;
    couponGrayBg: any = require('@/assets/img/coupon_bg2.png').default;
    couponDateIcon: any = require('@/assets/img/coupon_date.png').default;
    couponUsedIcon: any = require('@/assets/img/coupon_used.png').default;
    isNewUser: number = 0; // 是否是新用户,0老用户，1新用户
    notPageBeforeIn: boolean = false;// 第一次不执行

    mounted(): void {
      let _beforeUnload_time: number = 0, _gap_time: number = 0;
      window.addEventListener('beforeunload', e => {
        StorageUtils.deleteByModule('coupon.homeNumber');
        Utils.fireEvent(Utils.kEvents.updateHistoryList, true);
      });
      window.addEventListener('unload', e => {
        StorageUtils.deleteByModule('coupon.homeNumber');
        Utils.fireEvent(Utils.kEvents.updateHistoryList, true);
      });
      window.onbeforeunload = (e: any): void => {
        _beforeUnload_time = new Date().getTime();
        Utils.fireEvent(Utils.kEvents.updateHistoryList, true);
      };
      window.onunload = (e: any): void => {
        _gap_time = new Date().getTime() - _beforeUnload_time;
        if (_gap_time <= 5) {
          //浏览器关闭
          Utils.fireEvent(Utils.kEvents.updateHistoryList, true);
        }
      };
    }

    created(): void {
      // const vConsole = new VConsole();
      // 初始化framework7对象并赋值给window相对应对象
      window.f7 = f7;
      window.f7ready = f7ready;
      // 定义共用变量
      let firstLogin: number = 0;
      // 获取自带url中data的传参值
      // alert('url=' + window.location.href);
      // const urlObj = Utils.getRequestObject(window.location.href);
      // console.log('========', urlObj);
      // let urlData = urlObj["data"];
      let urlData = Utils.getQueryString('data', window.location.href);
      // alert('urlData=' + urlData)
      if (urlData) {
        let end = urlData.charAt(urlData.length - 1);
        // alert('end=' + end)
        if ('#' == end) {
          urlData = urlData.substr(0, urlData.length - 1);
          // alert('urlData=2=' + urlData)
        }
      }
      // let urlData: string = "eyJvcGVuaWQiOiJvSzF6NDZvUEJYVVYxMnVhNWRsYW4xdzJKaGpjIiwibW9kZWwiOiJhcnRpY2xlIn0="; //zyh
      // let urlData: string = "eyJvcGVuaWQiOiJvSzF6NDZzNlZBNFdiMm1MQmhZZnpkckFyLWlrIiwibW9kZWwiOiJhcnRpY2xlIn0="; //刘晨
      // let urlData: string = "eyJvcGVuaWQiOiJvSzF6NDZvdmwzNlJWVklTYXN5TjlhLVhLT0trIiwibW9kZWwiOiJhcnRpY2xlIn0="; //A风杰
      // base64解码得到openid与model的信息
      if (urlData == null || urlData == "") {
        urlData = StorageUtils.getByModule('url.app_data');
        if (urlData == null) {
          let contactDialog = window.f7.dialog.alert("未能正确获取到部分信息，请联系管理人员");
          Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
            opened: true,
            dialog: contactDialog,
          });
        }
      } //else {
      StorageUtils.updateByModule('url.app_data', urlData);
      const decodeInfo: any = Utils.decode('base64', urlData);
      // alert('decodeInfo=' + decodeInfo)
      const decodeInfoObj: any = StringUtils.toJson(decodeInfo);
      StorageUtils.updateByModule('url.app_dataObj', decodeInfoObj);
      const openId = decodeInfoObj.openid || ""; // 进入用户的openid
      StorageUtils.updateByModule('user.openid', openId);
      sessionStorage.setItem('user.openid', openId)
      // alert('openId=' + openId)

      // const model = decodeInfoObj.model || "article"; // 是从哪个模块进入，此处不做任何处理
      // const invite = decodeInfoObj.invite || ''; // 通过分享链接进入的，该值为分享者分享出的数据base64加密后的字符串
      const invite = decodeInfoObj.invite || ''; // 通过分享链接进入的，该值为分享者分享出的数据
      firstLogin = decodeInfoObj.isFirstLogin == true || decodeInfoObj.isFirstLogin == 'true' ? 1 : 0; // 判断用户是否是第一次进入
      this.isNewUser = firstLogin;
      // if (firstLogin == 1) {
      // 第一次进入的用户时，删除本地多余的本地记录信息
      let initPageClear = sessionStorage.getItem('initPageClear');
      if (!initPageClear) {// 进入系统时先清空
        const playingAudioObj: any = StorageUtils.getByModule('album.playing');
        StorageUtils.clear();
        sessionStorage.setItem('initPageClear', '1');
        StorageUtils.updateByModule('album.playing', playingAudioObj);

        // 每次进入系统到首页
        this.pageName = 'home';
        document.title = this.getPageTitleBy(this.pageName);
        this.changeCurTabActive(this.pageName);
        this.notPageBeforeIn = true;
      } else {
        this.notPageBeforeIn = false;
      }
      StorageUtils.updateByModule('url.app_data', urlData);
      StorageUtils.updateByModule('url.app_dataObj', decodeInfoObj);
      StorageUtils.updateByModule('user.openid', openId);
      // }

      //TODO.start 处理分享链接进来的接受内容
      if (invite != '') {
        // const shareDecodeStr: any = (invite != '') ? Utils.decode('base64', invite) : {};
        // const shareObject: any = StringUtils.toJson(shareDecodeStr);
        const shareObject: any = StringUtils.toJson(invite);
        // from:invite-friends 来自分享; openid:分享者openid; uid:分享者用户id; source:从哪个页面分享过来的; data:分享出来的单独另外的参数;audioPlay:音频播放页播放的音频及专辑信息，为null时音频为空;
        const shareData: any = shareObject.hasOwnProperty('data') && shareObject.data ? shareObject.data : {};
        const shareAudioPlay: any = shareObject.hasOwnProperty('audioPlay') && shareObject.audioPlay ? shareObject.audioPlay : null;
        const shareAudioPlayId: any = shareObject.hasOwnProperty('audioPlayId') && shareObject.audioPlayId ? shareObject.audioPlayId : -1;
        const shareSource: string = shareObject.hasOwnProperty('source') && shareObject.source ? shareObject.source : '';
        const shareGiftInvite: number = shareObject.hasOwnProperty('giftInvite') && shareObject.giftInvite ? shareObject.giftInvite : -1;
        this.$nextTick((): void => {
          if (shareAudioPlayId && shareAudioPlayId > 0) {
            // 查询播放器页面的数据
            const temp_data = {
              id: shareAudioPlayId,
            };
            const successTemp: any = (res: any): void => {
              const resData: any = res.data || {};
              const strData: any = resData.content || '';
              if (strData != '') {
                let shareAudioPlayObj: any = StringUtils.toJson(strData);
                if (shareAudioPlayObj.hasOwnProperty('isFixed') && shareAudioPlayObj.isFixed) {
                } else {
                  // console.log('======正常应该打开播放器',shareAudioPlayObj);
                  setTimeout(function () {
                    Utils.fireEvent(Utils.kEvents.changeAlbumPlayer, shareAudioPlayObj);
                    Utils.fireEvent(Utils.kEvents.updatePopupShow, true);
                  }, 800)
                }
              }
            };
            const temp_params: any = {
              url: Http.kInterfacePaths.jump_temp_data.query,
              data: temp_data,
              warn_info: '查询数据失败！',
              success: successTemp,
            };
            Http.ajax(this, temp_params);

            /*if (shareAudioPlay.hasOwnProperty('isFixed') && shareAudioPlay.isFixed) {
            } else {
              // console.log('======正常应该打开播放器',shareAudioPlay);
              Utils.fireEvent(Utils.kEvents.changeAlbumPlayer, shareAudioPlay);
            }*/
          } else if (shareGiftInvite != -1) {
            const inviteOpenid: string = shareData.hasOwnProperty('openid') && shareData.openid ? shareData.openid : '';
            let t: any = {
              id: shareData.hasOwnProperty('id') && shareData.id ? shareData.id : -1,
              openid: inviteOpenid,
              from: 'home',
            };
            // TODO.
            if (shareGiftInvite == 0) {
            } else {
              if (inviteOpenid == openId) {
                // 同一个人
                Object.assign(t, {
                  gift_user_relation_id: shareData.hasOwnProperty('gift_user_relation_id') && shareData.gift_user_relation_id ? shareData.gift_user_relation_id : -1,
                  need_invite_user_number: shareData.hasOwnProperty('need_invite_user_number') && shareData.need_invite_user_number ? shareData.need_invite_user_number : 0,
                  invited_user_number: shareData.hasOwnProperty('invited_user_number') && shareData.invited_user_number ? shareData.invited_user_number : 0,
                });
                Utils.load(this, '/gift_invite', t);
              } else {
                // 不是一个人
                Utils.load(this, '/gift_detail', t);
              }
              // Utils.load(this, '/gift_detail', {data: t});
            }
          } else {
            switch (shareSource) {
              case '/album':
                StorageUtils.updateByModule('album.query', shareData);
                Utils.load(this, '/album');
                break;
              case '/goods_detail':
                StorageUtils.updateByModule('goods.query', shareData);
                Utils.load(this, '/goods_detail', {
                  id: shareData.id.hasOwnProperty('id') && shareData.id ? shareData.id : -1,
                });
                break;
            }
          }
        });
      }
      // model = 'wxPay';
      // invite = 'eyJvcGVuaWQiOiJvSzF6NDZzNlZBNFdiMm1MQmhZZnpkckFyLWlrIiwiZnJvbSI6Imludml0ZS1mcmllbmRzIiwic291cmNlIjoiL2ludml0ZV9mcmllbmRzIiwidWlkIjoxfQ==';
      // console.log('+++++++', decodeInfoObj);
      // }
      // 获取节日优惠券列表及用户信息
      let homeNumber: any = StorageUtils.getByModule('coupon.homeNumber');
      homeNumber = homeNumber != null ? (homeNumber + 1) : 1;
      StorageUtils.updateByModule('coupon.homeNumber', homeNumber);
      CommonUtils.getUserInfo(this, (params: any): void => {
        this.userInfo = params;
        // if (firstLogin == 1) {
        //   this.getNewUserCouponList();
        // }
        if (homeNumber == 1) {
          // this.getFestivalCouponList();
          this.getCouponList();
        }
      }, true);

      this.$nextTick((): void => {
        this.couponPopup = window.f7.popup.get('.festival-coupon-popup');
        this.newUserPopup = window.f7.popup.get('.new-user-coupon-popup');
        CommonUtils.setWechatShare(this);
        // 主页底部显示历史最新记录
        Utils.fireEvent(Utils.kEvents.updateHomeBottomView, {});
      });
      Utils.bindEvent(Utils.kEvents.changeHomeActiveTab, this.onChangeCurrentActiveTab);
    }

    onPageBeforeIn(): void {
      if (this.notPageBeforeIn) {
        this.notPageBeforeIn = false;
        return;
      }
      Utils.getParamData().then((params: any): void => {
        let p_name = params.activePage ? params.activePage : '';
        if (p_name == '') {
          let locHomeTabName: string = StorageUtils.getByModule('home.tab') || '';
          p_name = typeof locHomeTabName == "string" && locHomeTabName ? locHomeTabName : (this.pageName || 'home');
        }
        this.pageName = p_name;
        window.f7.tab.show('#' + p_name);
        document.title = this.getPageTitleBy(this.pageName);
        Utils.fireEvent(Utils.kEvents.updateHomePageName, {
          active: this.pageName,
        });
      });
      document.title = this.getPageTitleBy(this.pageName);
    }

    onPageBeforeOut(): void {
    }

    @Watch('pageName', {immediate: true, deep: true})
    onChangeCurrentPage(val: any, oldVal: any): void {
      this.$nextTick((): void => {
        const t: any = {
          active: this.pageName,
          noNeedSignature: (oldVal != undefined && typeof oldVal == "string" && oldVal),
        };
        CommonUtils.setWechatShare(this, t);
      });
      // document.title = this.getPageTitleBy(this.pageName);
    }

    // 根据当前展示页面名称page_name返回标题应当显示的名称
    getPageTitleBy(page_name: string): string {
      let res: string = '快乐听吧';
      switch (this.pageName) {
        case 'home':
          res = "快乐听吧";
          break;
        case 'history':
          // res = "播放历史";
          res = "我听";
          break;
        case 'vip':
          res = "礼物";
          break;
        case 'person':
          res = "我的";
          break;
      }
      return res;
    }

    // 监听改变了当前tab页面
    onChangeCurrentActiveTab(params: any): void {
      this.pageName = (params.hasOwnProperty('active') && params.active) ? params.active : 'home';
      Utils.fireEvent(Utils.kEvents.updateHomePageName, {
        active: this.pageName,
      });
      document.title = this.getPageTitleBy(this.pageName);
    }

    // 修改当前pageName
    onChangeActivePageName(page_name: string): void {
      this.pageName = page_name;
      Utils.fireEvent(Utils.kEvents.updateHomePageName, {
        active: this.pageName,
      });
      document.title = this.getPageTitleBy(this.pageName);
    }

    // 将播放器大窗口展示
    openAlbumPlayerPopup(): void {
      Utils.fireEvent(Utils.kEvents.updatePopupShow, true);
    }

    // 获取节日优惠券列表
    getFestivalCouponList(): void {
      const today: string = DateUtils.getCurDatetime().substring(0, 10);
      const data: any = {
        give_time: today,
        user_id: this.userInfo.id || -1,
      };
      const successList: any = (res: any): void => {
        let curResData: any[] = res.hasOwnProperty('data') && typeof res.data != "string" ? res.data : [];
        let resData: any[] = [];
        for (let i: number = 0; i < curResData.length; i++) {
          let item: { [key: string]: any } = curResData[i];
          if (curResData[i].hasOwnProperty('start_time') && curResData[i].start_time) {
            item['startDate'] = DateUtils.dateToDateString(curResData[i].start_time);
          }
          if (curResData[i].hasOwnProperty('end_time') && curResData[i].end_time) {
            item['endDate'] = DateUtils.dateToDateString(curResData[i].end_time);
          }
          resData.push(item);
        }
        this.couponList = resData;
        if (this.couponList.length > 0) {
          if (this.couponPopup) {
            this.couponPopup.open();
            this.couponPopupOpened = true;
          }
        }
      };
      const params: any = {
        url: Http.kInterfacePaths.coupon.search,
        data: data,
        warn_info: '获取赠送优惠券列表失败！',
        success: successList,
      };
      Http.ajax(this, params);
    }

    // 领取节日优惠券列表
    receiveFestivalCouponList(): void {
      let ids: number[] = [];
      for (let i: number = 0; i < this.couponList.length; i++) {
        if (!this.couponList[i].id) continue;
        ids.push(this.couponList[i].id);
      }
      const data: any = {
        user_id: this.userInfo.id || -1,
        coupon_id: ids,
      };
      const successAdd: any = (res: any): void => {
        if (this.couponPopup) {
          this.couponPopup.close();
          this.couponPopupOpened = false;
        }
      };
      const params: any = {
        url: Http.kInterfacePaths.coupon.add,
        data: data,
        warn_info: '优惠券领取失败！',
        success: successAdd,
      };
      Http.ajax(this, params);
    }

    // 暂不领取节日列表
    cancelReceiveFestivalCoupon(): void {
      if (this.couponPopup) {
        this.couponPopup.close();
        this.couponPopupOpened = false;
      }
    }

    // 暂不领取新用户优惠券列表
    closeNewUserCouponList(): void {
      if (this.newUserPopup) {
        this.newUserPopup.close();
        this.newUserPopupOpened = false;
      }
    }

    // 获取新用户优惠券列表
    getNewUserCouponList(): void {
      let data: any = {
        page: 1,
        size: 999,
        user_id: this.userInfo.id || -1,
      };
      let successList: any = (res: any): void => {
        let curResData = res.hasOwnProperty('data') && typeof res.data != "string" ? res.data : [];
        let resData: any[] = [];
        for (let i: number = 0; i < curResData.length; i++) {
          let item: { [key: string]: any } = curResData[i];
          if (curResData[i].hasOwnProperty('start_time') && curResData[i].start_time) {
            item['startDate'] = DateUtils.dateToDateString(curResData[i].start_time);
          }
          if (curResData[i].hasOwnProperty('end_time') && curResData[i].end_time) {
            item['endDate'] = DateUtils.dateToDateString(curResData[i].end_time);
          }
          resData.push(item);
        }
        this.newUserPopupCouponList = resData;
        if (this.newUserPopupCouponList.length > 0) {
          if (this.newUserPopup) {
            this.newUserPopup.open();
            this.newUserPopupOpened = true;
          }
        }
      };
      let params: any = {
        url: Http.kInterfacePaths.coupon.list,
        data: data,
        warn_info: '获取新用户优惠券记录失败！',
        success: successList,
      };
      Http.ajax(this, params);
    }

    // 获取节日优惠券列表及专属优惠券列表
    getCouponList(): void {
      // const today: string = DateUtils.getCurDatetime().substring(0, 10);
      const data: any = {
        // give_time: today,
        user_id: this.userInfo.id || -1,
      };
      const successList: any = (res: any): void => {
        let resData: any = res.hasOwnProperty('data') && typeof res.data != "string" ? res.data : {};

        // 专属优惠券
        const zsList: any[] = resData.hasOwnProperty('exclusive') && resData.exclusive ? resData.exclusive : [];
        if (zsList.length > 0) {
          let zsFinishList: any[] = [];
          for (let i: number = 0; i < zsList.length; i++) {
            let item: { [key: string]: any } = zsList[i];
            if (zsList[i].hasOwnProperty('start_time') && zsList[i].start_time) {
              item['startDate'] = DateUtils.dateToDateString(zsList[i].start_time);
            }
            if (zsList[i].hasOwnProperty('end_time') && zsList[i].end_time) {
              item['endDate'] = DateUtils.dateToDateString(zsList[i].end_time);
            }
            zsFinishList.push(item);
          }
          this.exclusiveCouponList = zsFinishList;
          if (this.exclusiveCouponList.length > 0) {
            if (this.exclusiveCouponPopup)
              this.exclusiveCouponPopup.open();
            this.exclusiveCouponPopupOpened = true;
          }
        }

        // 节日优惠券
        const jrList: any[] = resData.hasOwnProperty('holiday') && resData.holiday ? resData.holiday : [];
        if (jrList.length > 0) {
          let jrFinishList: any[] = [];
          for (let i: number = 0; i < jrList.length; i++) {
            let item: { [key: string]: any } = jrList[i];
            if (jrList[i].hasOwnProperty('start_time') && jrList[i].start_time) {
              item['startDate'] = DateUtils.dateToDateString(jrList[i].start_time);
            }
            if (jrList[i].hasOwnProperty('end_time') && jrList[i].end_time) {
              item['endDate'] = DateUtils.dateToDateString(jrList[i].end_time);
            }
            jrFinishList.push(item);
          }
          this.couponList = jrFinishList;
          if (this.couponList.length > 0) {
            if (this.couponPopup) {
              this.couponPopup.open();
              this.couponPopupOpened = true;
            }
          }
        }

        // 新用户优惠券
        const newList: any[] = resData.hasOwnProperty('newUser') && resData.newUser ? resData.newUser : [];
        if (newList.length > 0) {
          let newFinishList: any[] = [];
          for (let i: number = 0; i < newList.length; i++) {
            let item: { [key: string]: any } = newList[i];
            if (newList[i].hasOwnProperty('start_time') && newList[i].start_time) {
              item['startDate'] = DateUtils.dateToDateString(newList[i].start_time);
            }
            if (newList[i].hasOwnProperty('end_time') && newList[i].end_time) {
              item['endDate'] = DateUtils.dateToDateString(newList[i].end_time);
            }
            newFinishList.push(item);
          }
          this.newUserPopupCouponList = newFinishList;
          if (this.newUserPopupCouponList.length > 0 && this.isNewUser == 1) {
            if (this.newUserPopup) {
              this.newUserPopup.open();
              this.newUserPopupOpened = true;
            }
          }
        }
      };
      const params: any = {
        url: Http.kInterfacePaths.coupon.search,
        data: data,
        warn_info: '获取赠送优惠券列表失败！',
        success: successList,
      };
      Http.ajax(this, params);
    }

    // 关闭不领取优惠券
    closeCurPopup(class_name: string): void {
      window.f7.popup.close('.' + class_name);
      switch (class_name) {
        case 'festival-coupon-popup':
          this.couponPopupOpened = false;
          break;
        case 'new-user-coupon-popup':
          this.newUserPopupOpened = false;
          break;
        case 'exclusive-coupon-popup':
          this.exclusiveCouponPopupOpened = false;
          break;
      }
    }

    // 领取优惠券
    receiveCouponList(type: string): void {
      let ids: number[] = [];
      switch (type) {
        case 'festival':
          for (let i: number = 0; i < this.couponList.length; i++) {
            if (!this.couponList[i].id) continue;
            ids.push(this.couponList[i].id);
          }
          break;
        case 'new_user':
          for (let i: number = 0; i < this.newUserPopupCouponList.length; i++) {
            if (!this.newUserPopupCouponList[i].id) continue;
            ids.push(this.newUserPopupCouponList[i].id);
          }
          break;
        case 'exclusive':
          for (let i: number = 0; i < this.exclusiveCouponList.length; i++) {
            if (!this.exclusiveCouponList[i].id) continue;
            ids.push(this.exclusiveCouponList[i].id);
          }
          break;
      }
      const data: any = {
        user_id: this.userInfo.id || -1,
        coupon_id: ids,
      };
      const successAdd: any = (res: any): void => {
        switch (type) {
          case 'festival':
            this.closeCurPopup('festival-coupon-popup')
            break;
          case 'new_user':
            this.closeCurPopup('new-user-coupon-popup')
            break;
          case 'exclusive':
            this.closeCurPopup('exclusive-coupon-popup')
            break;
        }
      };
      const params: any = {
        url: Http.kInterfacePaths.coupon.add,
        data: data,
        warn_info: '优惠券领取失败！',
        success: successAdd,
      };
      Http.ajax(this, params);
    }

    // 切换当前tab显示页
    changeCurTabActive(tab_name: string): void {
      this.pageName = tab_name;
      window.f7.tab.show('#' + tab_name);
      Utils.fireEvent(Utils.kEvents.updateHomePageName, {
        active: tab_name,
      });
      document.title = this.getPageTitleBy(this.pageName);
    }
  }

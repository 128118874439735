//@ts-ignore
import StringUtils from './string';

enum Type {
  Local,
  Session
}

interface StorageMethod {
  type: Type,

  set(key: string, val: any): void,

  get(key: string): any,

  remove(key: string): void,

  clear(): void
}

class Storage implements StorageMethod {
  public type: Type;

  constructor(type: Type) {
    this.type = type;
  }

  public set(key: string, val: any): void {
    switch (this.type) {
      case Type.Local:
        localStorage.setItem(key, val);
        break;
      case Type.Session:
        sessionStorage.setItem(key, val);
        break;
      default:
        break;
    }
  }

  public get(key: string): any {
    let res: any = '';
    switch (this.type) {
      case Type.Local:
        res = localStorage.getItem(key);
        break;
      case Type.Session:
        res = sessionStorage.getItem(key);
        break;
      default:
        break;
    }
    return res;
  }

  public remove(key: string): void {
    switch (this.type) {
      case Type.Local:
        localStorage.removeItem(key);
        break;
      case Type.Session:
        sessionStorage.removeItem(key);
        break;
      default:
        break;
    }
  }

  public clear(): void {
    switch (this.type) {
      case Type.Local:
        localStorage.clear();
        break;
      case Type.Session:
        sessionStorage.clear();
        break;
      default:
        break;
    }
  }

}

const local = new Storage(Type.Local);

const StorageUtils = {
  projectName: 'lionShiZiLaoBaWechat',
  local: new Storage(Type.Local),
  session: new Storage(Type.Session),
  /**
   * 存储到 _module 中，例'updateByModule("news.factory","污染更严重了")'，
   * 输出为 { news: { factory: "污染更严重了" } }
   * @param _module
   * @param _data
   */
  updateByModule: function (_module: string = 'all', _data: any): void {
    let _this = this;
    if (_module != 'all') {
      const keys = _module.split('.');
      const keyLen = keys.length;
      let resObj: { [k: string]: any } = {};

      // 原数据
      let localStr = local.get(_this.projectName);
      localStr = localStr || '';
      let localObj: { [k: string]: any } = {};
      if (localStr == '') {
        localObj = {};
        localStr = StringUtils.toStr(localObj);
        local.set(_this.projectName, localStr);
      }
      localObj = StringUtils.toJson(localStr);

      if (keyLen > 0) {

        switch (keyLen) {
          case 1:
            if (localObj[_module]) {
              for (let i in localObj) {
                let item = localObj[i];
                if (i == _module) {
                  item = _data;
                }
                resObj[i] = item;
              }
            } else {
              resObj = localObj;
              resObj[_module] = _data;
            }
            break;
          case 2:
            if (localObj[keys[0]]) {
              if (localObj[keys[0]][keys[1]]) {
                for (let i in localObj) {
                  let item = localObj[i];
                  if (i == keys[0]) {
                    let resSubObj: { [k: string]: any } = {};
                    for (let x in localObj[i]) {
                      let subItem = localObj[i][x];
                      if (x == keys[1]) {
                        subItem = _data;
                      }
                      resSubObj[x] = subItem;
                    }
                    if (resSubObj.length == 0) {
                      continue;
                    }
                    item = resSubObj;
                  }
                  resObj[i] = item;
                }
              } else {
                resObj = localObj;
                resObj[keys[0]][keys[1]] = _data;
              }

            } else {
              resObj = localObj;
              resObj[keys[0]] = {};
              resObj[keys[0]][keys[1]] = _data;
            }
            break;
        }

      } else {
        resObj = localObj;
      }

      local.set(_this.projectName, StringUtils.toStr(resObj));

    } else {
      local.set(_module, StringUtils.toStr(_data));
    }
    local.set(_module, 1);
  },

  /**
   * 删除某个 _module 模块
   * @param _module
   */
  deleteByModule: function (_module: string = 'all'): void {
    let _this = this;
    local.remove(_module);

    let resObj: { [k: string]: any } = {};
    if (_module != 'all') {
      let localStr = local.get(_this.projectName);
      localStr = localStr ? localStr : '';
      let localObj: { [k: string]: any } = {};
      if (localStr == '') {
        localObj = {};
        localStr = StringUtils.toStr(localObj);
        local.set(_this.projectName, localStr);
      }
      localObj = StringUtils.toJson(localStr);
      let moduleArr = _module.split('.');
      let moduleLen = moduleArr.length;
      if (moduleLen > 0) {
        switch (moduleLen) {
          case 1:
            if (localObj[_module]) {
              for (let i in localObj) {
                let item = localObj[i];
                if (i == _module) {
                  continue;
                }
                resObj[i] = item;
              }
            } else {
              resObj = localObj;
            }
            break;
          case 2:
            if (localObj[moduleArr[0]]) {

              if (localObj[moduleArr[0]][moduleArr[1]]) {
                for (let i in localObj) {
                  let item = localObj[i];
                  if (i == moduleArr[0]) {
                    let resSubObj: { [k: string]: any } = {};
                    for (let x in localObj[i]) {
                      let subItem = localObj[i][x];
                      if (x == moduleArr[1]) {
                        continue;
                      }
                      resSubObj[x] = subItem;
                    }
                    if (resSubObj.length == 0) {
                      continue;
                    }
                    item = resSubObj;
                  }
                  resObj[i] = item;
                }
              } else {
                resObj = localObj;
              }

            } else {
              resObj = localObj;
            }
            break;
        }
      } else {
        resObj = localObj;
      }
      local.set(_this.projectName, StringUtils.toStr(resObj));

    } else {
      local.remove(_this.projectName);
    }

  },

  /**
   * 获取某个 _module 模块的存储数据
   * @param _module
   */
  getByModule: function (_module: string = 'all'): any {
    let _this = this;

    let getNum = local.get(_module);
    getNum = (getNum >= 0) ? parseInt(getNum) + 1 : 1;
    local.set(_module, getNum);

    let storageKey = _this.projectName;
    let moduleData: any = null;

    let localStr = local.get(storageKey);
    localStr = localStr ? localStr : '';
    let localObj: { [k: string]: any } = {};
    if (localStr == '') {
      localObj = {};
      localStr = StringUtils.toStr(localObj);
      local.set(storageKey, localStr);
    }
    localObj = StringUtils.toJson(localStr);
    let moduleArr = _module.split('.');
    let moduleLen = moduleArr.length;

    if (moduleLen > 0) {
      if (moduleLen == 1) {
        switch (_module) {
          case 'all':
            moduleData = localObj;
            break;
          default:
            moduleData = localObj[_module] ? localObj[_module] : null;
            break;
        }
      } else if (moduleLen == 2) {
        moduleData = (localObj[moduleArr[0]]
            ? (localObj[moduleArr[0]][moduleArr[1]]
                ? localObj[moduleArr[0]][moduleArr[1]] : null) : null);
      }
    }

    return moduleData;
  },
  clear: (): void => {
    local.clear();
  },
};

export default StorageUtils;

<template>
  <div>
    <banner-list divId="albumIntroduceImages"
                 :banners="introduceImages"
    ></banner-list>
    <div class="album-limited-offer-frame"
         :class="{'limited-offer':isLimitedOffer}">
      <div class="album-limited-offer-price">
        <span>￥</span>
        <span>{{albumInfo.time_limit_discount_status==1?(albumInfo.time_limit_discount_price||0):(albumInfo.money||0)}}</span>
        <span class="album-org-price"
              v-if="albumInfo.time_limit_discount_status==1?albumInfo.money:albumInfo.org_money">
          ￥{{albumInfo.time_limit_discount_status==1?(albumInfo.money||0):(albumInfo.org_money||0)}}
        </span>
      </div>
      <div class="album-limited-offer-time-box text-center"
           v-if="isLimitedOffer">
        <p class="album-limited-offer-time-title">距优惠失效：</p>
        <p v-if="((limitedOfferInfo.hasOwnProperty('day')&&parseInt(limitedOfferInfo.day)>0)||(limitedOfferInfo.hasOwnProperty('hour')&&limitedOfferInfo.hour!='00')||(limitedOfferInfo.hasOwnProperty('minute')&&limitedOfferInfo.minute!='00')||(limitedOfferInfo.hasOwnProperty('second')&&limitedOfferInfo.second!='00'))">
          <span v-if="parseInt(limitedOfferInfo.day)>0">{{parseInt(limitedOfferInfo.day)}}天</span>
          <span class="album-limited-offer-time text-center">{{limitedOfferInfo.hour}}</span>
          :
          <span class="album-limited-offer-time text-center">{{limitedOfferInfo.minute}}</span>
          :
          <span class="album-limited-offer-time text-center">{{limitedOfferInfo.second}}</span>
        </p>
        <p v-if="(limitedOfferInfo.day&&limitedOfferInfo.day=='00'&&limitedOfferInfo.hour&&limitedOfferInfo.hour=='00'&&limitedOfferInfo.minute&&limitedOfferInfo.minute=='00'&&limitedOfferInfo.second&&limitedOfferInfo.second=='00')">
          已结束</p>
      </div>
    </div>
    <f7-block class="home-block"
              :style="{'margin-top':(isLimitedOffer?'16px':'0px')}">
      <!--:class="{'limited-offer':isLimitedOffer}">-->
      <div>
        <h3 class="album-title">
          {{title||''}}
        </h3>
        <p class="album-subtitle">{{subtitle||''}}</p>
        <p class="album-description">
          已更新{{totalRow||0}}集
          &nbsp;|&nbsp;
          {{subscription||0}}人订阅
        </p>
      </div>
    </f7-block>
    <f7-block class="album-tab-link-block">
      <div class="album-tab-link-frame">
        <f7-link tab-link="#content"
                 :tab-link-active="currentTab=='content'"
                 @click="changeTabActive('content')">
          详情
          <div class="album-tab-active-line"></div>
        </f7-link>
        <f7-link tab-link="#catalog"
                 :tab-link-active="currentTab=='catalog'"
                 @click="changeTabActive('catalog')">
          目录
          <!--          <span class="album-tab-link-diversity">共{{totalRow}}集</span>-->
          <div class="album-tab-active-line"></div>
        </f7-link>
        <div class="album-diversity-sort-link"
             v-if="currentTab=='catalog'"
             :class="{'disabled':(currentTab=='content')}">
          <!--<f7-link color="gray" class="album-diversity-sort"
                   @click="changeDiversitySort">
            <f7-icon :f7="isSortReverse?'sort_up':'sort_down'"></f7-icon>
          </f7-link>-->
          <span class="album-diversity-link" @click="changeDiversityDisplay">
            共{{totalRow}}集
            <f7-icon
                :f7="isShowDiversity?'chevron_up':'chevron_down'"></f7-icon>
          </span>
        </div>
      </div>
    </f7-block>
  </div>
</template>

<script lang="ts">
  import {Options, Vue} from "vue-class-component";
  import {Prop, Watch} from "vue-property-decorator";
  import BannerList from "@/components/banner-list.vue";

  @Options({
    components: {
      BannerList,
    },
  })
  export default class AlbumHeader extends Vue {
    name: string = "album-header";
    @Prop(Object) albumInfo!: any; // 专辑信息
    @Prop(String) currentTab!: string; // 当前激活的tab
    @Prop(Object) introduceImages!: any[]; // 介绍图列表
    @Prop(String) title!: string; // 专辑标题
    @Prop(String) subtitle!: string; // 专辑副标题
    @Prop(String) updatedData!: string; // 专辑更新期数/集数
    @Prop(String) subscription!: string; // 订阅数
    @Prop(Number) totalRow!: number; // 共多少集
    @Prop(Boolean) isShowDiversity!: boolean; // 是否显示分集展示
    @Prop({default: false}) isLimitedOffer!: boolean; // 是否限时特惠专辑
    @Prop({default: 0}) limitedOfferPrice!: number; // 限时优惠价格
    @Prop({
      default: {
        // day: '',
        // hour: '',
        // minute: '',
        // second: ''
      }
    }) limitedOfferInfo!: any; // 限时优惠倒计时信息
    // @Prop(Boolean) isSortReverse!: boolean; // 是否倒序排列

    changeTabActive(active: string): void {
      this.$emit('updateTabActive', active);
    }

    changeDiversityDisplay(): void {
      if (this.currentTab == 'catalog') {
        this.$emit('updateDiversityShow', !this.isShowDiversity);
      }
    }

    // changeDiversitySort(): void {
    //   if (this.currentTab == 'catalog') {
    //     this.$emit('updateDiversitySort', !this.isSortReverse);
    //   }
    // }
  }
</script>

<style scoped>

</style>

<template>
  <f7-page :name="name" @page:beforein="onPageBeforeIn" :no-navbar="!isApp"
           :no-toolbar="true">
    <f7-navbar back-link title="VIP会员" v-if="isApp"/>
    <f7-block>
      <div class="vip-nameplate-frame" @click="tipVipBuyConfirmPage">
        <div class="vip-nameplate-person">
          <img class="vip-nameplate-bg-image" :src="nameplateBgUrl" alt="水印">
          <img class="my-avatar-img"
               :src="userInfo.headimg_url?userInfo.headimg_url:''" alt="头像">
          <h2 class="vip-nameplate-title">{{userInfo.nickname||''}}</h2>
          <p class="vip-nameplate-p"
             v-if="userInfo.hasOwnProperty('member_code')&&userInfo.member_code">
            <!--{{userInfo.invite_code?'会员邀请码：'+userInfo.invite_code:''}}-->
            {{userInfo.member_code?'会员码：'+userInfo.member_code:''}}
            <span class="member_over"
                  v-if="userInfo.member_code && userInfo.is_expire==1">已到期</span>
          </p>
          <p class="vip-nameplate-p"
             v-if="!(userInfo.hasOwnProperty('member_code')&&userInfo.member_code)">
            开通VIP会员
          </p>
        </div>
        <div class="vip-nameplate-border"></div>
        <p class="vip-nameplate-p">
          {{(userInfo.hasOwnProperty('member_code')&&userInfo.member_code && userInfo.is_expire==0)?
          userInfo.expire_time&&userInfo.expire_time=='lifetime'?'会员期限：永久有效':'会员期限：'+userInfo.expire_time
          :'点击铭牌，立即升级为VIP会员'}}
        </p>
      </div>
      <div
          v-if="!(userInfo.hasOwnProperty('member_code')&&userInfo.member_code)">
        <h2 class="text-center" style="font-weight:500;margin-top: 36px;">
          六大专属权益
        </h2>
        <f7-row no-gap>
          <f7-col width="33" v-for="(el,index) in modelList" :key="el.id"
                  class="text-center" style="margin-bottom:24px;">
            <div class="vip-model-img-box text-center">
              <img :src="el.icon" :alt="el.title" class="vip-model-img">
            </div>
            <p class="vip-model-title">{{el.title}}</p>
          </f7-col>
        </f7-row>
      </div>
      <div v-if="userInfo.hasOwnProperty('member_code')&&userInfo.member_code">
        <f7-row no-gap style="margin:24px 0;">
          <f7-col width="50" class="vip-model-link-item"
                  v-for="el in modelLinkList"
                  :key="'modelLink_'+el.id" @click="tipModelLinkTo(el)">
            <div class="vip-link-img-box">
              <img :src="el.icon" alt="图标" class="vip-link-img">
            </div>
            <h3>{{el.title}}</h3>
            <p>{{el.type =='team'?teamNumber+'人': el.description}}</p>
          </f7-col>
        </f7-row>
        <h2 class="text-center vip-invite-title" style="margin-top: 36px;"
            v-if="gifts.length>0">
          邀请好礼
        </h2>
        <p class="text-center vip-invite-p" v-if="gifts.length>0">
          邀请好友任意消费即可领取</p>
        <goods-list :gifts="gifts" type="gifts"
                    @clickGoodsDetail="onClickGiftDetail"
        ></goods-list>
      </div>
    </f7-block>
  </f7-page>
</template>

<script lang="ts">
  import {Options, Vue} from "vue-class-component";
  import GoodsList from "@/components/goods-list.vue";
  import CommonUtils from "@/js/common/common.ts";
  import Http from "@/js/utils/http.ts";
  import Utils from "@/js/utils/utils";
  import StorageUtils from '@/js/utils/storage';

  @Options({
    components: {
      GoodsList,
    },
  })
  export default class VipPage extends Vue {
    isApp: boolean = Http.isApp;
    name: string = "vip";
    // nameplateBgUrl: string = './static/img/vip_shuiyin.png';
    nameplateBgUrl: any = require('@/assets/img/vip_shuiyin.png').default;
    userInfo: any = {};
    from: string = '';
    shareObject: any = {};
    inviterInfo: any = {};
    modelList: any[] = [
      {
        id: 1,
        type: 'changting',
        title: '故事畅听',
        icon: require('@/assets/img/vip_yongjiuchangting.png').default,
      },
      {
        id: 2,
        type: 'guanggao',
        title: '无广告',
        icon: require('@/assets/img/vip_yongwuguanggao.png').default,
      },
      {
        id: 3,
        type: 'shangxin',
        title: '专属上新',
        icon: require('@/assets/img/vip_zhuanshushangxin.png').default,
      },
      {
        id: 4,
        type: 'yaoren',
        title: '邀人有奖',
        icon: require('@/assets/img/vip_yaoqingyoujiang.png').default,
      },
      {
        id: 5,
        type: 'shengqian',
        title: '自用省钱',
        icon: require('@/assets/img/vip_ziyongshengqian.png').default,
      },
      {
        id: 6,
        type: 'zhuanqian',
        title: '分享赚钱',
        icon: require('@/assets/img/vip_fenxiangzhuanqian.png').default,
      },
    ];
    modelLinkList: any[] = [
      {
        id: 1,
        type: 'invite',
        title: '邀请好友',
        description: '一起赚收益',
        icon: require('@/assets/img/vip_yaoqingyoujiang.png').default,
      },
      {
        id: 2,
        type: 'team',
        title: '我的团队',
        description: '0人',
        icon: require('@/assets/img/vip_wodetuandui.png').default,
      },
    ];
    teamNumber: number = 0;
    gifts: any[] = [];

    onPageBeforeIn(): void {
      document.title = 'VIP会员';
    }

    created(): void {
      CommonUtils.getUserInfo(this, (params: any): void => {
        this.userInfo = params;

        let shareInfo: any = StorageUtils.getByModule('vip.share');
        if (shareInfo == null) {
          shareInfo = StorageUtils.getByModule('vip.copy_share');
        }
        if (shareInfo != null) {
          StorageUtils.updateByModule('vip.copy_share', shareInfo);
          this.from = shareInfo.from || this.from;
          this.shareObject = shareInfo.data || {};
          StorageUtils.deleteByModule('vip.share');

          if (this.from == 'share') {
            //判断是否是会员
            if (this.userInfo.hasOwnProperty('member_code') && this.userInfo.member_code && this.userInfo.member_code != '') {
              let contactDialog = window.f7.dialog.alert('您已经是会员了呦，赶快去听好故事吧~', (): void => {
                Utils.back(this, '/', {activePage: 'home'}, {
                  reloadAll: true,
                  force: true
                });
              });
              Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
                opened: true,
                dialog: contactDialog,
              });
              return;
            }
            this.inviterInfo = StorageUtils.getByModule('vip.inviter') || {};
          }
        }
      });
      this.getGiftList();
      this.$nextTick((): void => {
        CommonUtils.setWechatShare(this);
      });
    }

    tipVipBuyConfirmPage(): void {
      if (!(this.userInfo.hasOwnProperty('member_code') && this.userInfo.member_code && this.userInfo.is_expire==0)) {
        const data: any = {
          from: 'vip',
          // is_share: (this.from == 'share'),
          is_share: (this.shareObject.hasOwnProperty('openid')),
          share_info: this.shareObject,
          order: {
            id: -1,
            title: '开通快乐听吧VIP会员',
            // price: 599,
            price: 699,
            content: '使用周期3年',
          },
          inviter_info: this.inviterInfo,
        };
        StorageUtils.updateByModule('buy.query', data);
        Utils.load(this, '/buy_confirm_vip', {from: 'vip'});
      }
    }

    tipModelLinkTo(item: any): void {
      switch (item.type) {
        case 'invite':
          // 邀请好友
          Utils.load(this, '/vip_invite');
          break;
        case 'team':
          // 我的团队
          Utils.load(this, '/vip_team');
          break;
      }
    }

    //TODO.start 获取邀请好礼礼包列表-点击进入礼包详情
    getGiftList(): void {
      let data: any = {
        page: 1,
        size: 4,
      };
      let successList: any = (res: any): void => {
        this.gifts = res.hasOwnProperty('data') ? res.data : [];
      };
      let params: any = {
        url: Http.kInterfacePaths.gift.list,
        data: data,
        warn_info: '获取邀请好礼记录失败！',
        success: successList,
      };
      Http.ajax(this, params);
    }

    onClickGiftDetail(params: any): void {
      // Utils.load(this, '/goods_detail');
      if (params.stock <= 0) {
        window.f7.toast.create({
          text: '活动太火爆了，该商品已抢光啦~~~~',
          closeTimeout: 2000,
          position: "center",
        }).open();
        return;
      }
      let data: any = {
        id: params.hasOwnProperty('id') && params.id ? params.id : -1,
        from: 'vip',
      };
      StorageUtils.updateByModule('gift.detail', data);
      Utils.load(this, '/gift_detail', data);
    }

  }
</script>

<style scoped>

</style>

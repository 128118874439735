<template>
  <f7-page :page-content="false"
           @page:beforein="onPageBeforeIn" @page:beforeout="onPageBeforeOut">
    <f7-navbar back-link title="专辑详情" v-if="isApp"/>
    <title-share v-if="!isApp"></title-share>
    <div class="toolbar tabbar toolbar-bottom no-shadow home-toolbar-frame"
         v-if="!(userInfo.hasOwnProperty('member_code')&&userInfo.member_code && userInfo.is_expire==0)">
      <div class="toolbar-inner"
           style="padding-left:16px;padding-right:16px;">
        <a href="javascript:void(0);" @click="tipVipPage"
           class="color-orange text-center album-vip-link">
          <f7-icon f7="gift" size="22"></f7-icon>
          <span class="tabbar-label" style="font-size:12px;">VIP畅听</span>
        </a>
        <f7-button fill round color="orange" style="width:100%;"
                   class="album-submit-btn disabled"
                   v-if="albumInfo.hasOwnProperty('is_buy')&&albumInfo.is_buy">
          已订阅
        </f7-button>
        <f7-button fill round color="orange" style="width:100%;"
                   class="album-submit-btn" v-else
                   @click="tipAlbumBuyConfirmPage">
          立即订阅
          <span
              v-if="albumInfo.hasOwnProperty('org_money')&&albumInfo.org_money"
              style="font-size:12px;color:#f5f5f5;text-decoration:line-through;">
            ￥{{albumInfo.org_money||0}}
          </span>
        </f7-button>
      </div>
    </div>
    <f7-tabs>
      <f7-page-content tab
                       :tab-active="currentActiveTab=='content'"
                       @tab:show="currentActiveTab=='content'">
        <album-header
            :albumInfo="albumInfo"
            :isLimitedOffer="albumInfo.hasOwnProperty('time_limit_discount_status')&&albumInfo.time_limit_discount_status == 1"
            :limitedOfferPrice="(albumInfo.time_limit_discount_price||0)"
            :limitedOfferInfo="limitedInfo"
            :currentTab="currentActiveTab"
            :introduceImages="cycleArr||[]"
            :title="albumInfo.title||''"
            :subtitle="albumInfo.subtitle||''"
            :updatedData="albumInfo.new_number||''"
            :subscription="albumInfo.follow_number||''"
            :total-row="totalRow"
            @updateTabActive="onUpdateTabActive"
        ></album-header>
        <f7-block class="album-block">
          <div class="album-content-box" v-html="albumInfo.content||''"></div>
        </f7-block>
      </f7-page-content>
      <f7-page-content tab :tab-active="currentActiveTab=='catalog'"
                       @tab:show="currentActiveTab=='catalog'"
                       style="height:100% !important;"
                       :ptr="ptrPreloader" :ptr-distance="56"
                       @ptr:refresh="getPreviousAudioList"
                       infinite :infinite-distance="50"
                       :infinite-preloader="preloader"
                       @infinite="getNextAlbumChildrenList">
        <album-header
            :albumInfo="albumInfo"
            :isLimitedOffer="albumInfo.hasOwnProperty('time_limit_discount_status')&&albumInfo.time_limit_discount_status == 1"
            :limitedOfferPrice="(albumInfo.time_limit_discount_price||0)"
            :limitedOfferInfo="limitedInfo"
            :currentTab="currentActiveTab"
            :introduceImages="cycleArr||[]"
            :title="albumInfo.title||''"
            :subtitle="albumInfo.subtitle||''"
            :updatedData="albumInfo.new_number||''"
            :subscription="albumInfo.follow_number||''"
            :total-row="totalRow"
            @updateTabActive="onUpdateTabActive"
            :is-show-diversity="isShowDiversity"
            @updateDiversityShow="onUpdateDiversityShow"
        ></album-header>
        <!--集数范围选择-->
        <f7-sheet
            id="album_lists"
            class="sheet-diversity-select-box"
            style="height: 75vh; --f7-sheet-bg-color: rgba(0,0,0,0.75);"
            swipe-to-close
            backdrop
            :opened="isShowDiversity"
            @sheet:closed="isShowDiversity = false"
        >
          <f7-page-content>
            <f7-row no-gap style="justify-content:end;margin: 16px 0;">
              <f7-col width="33" class="text-center album-diversity-item"
                      v-for="(el,index) in (Math.ceil(totalRow/diversityValue))"
                      :key="'col_'+index">
                <f7-button :class="{'active':(activeDiversity==index)}"
                           @click="changeActiveDiversityRange(index)"
                           fill small>
                  {{diversityValue*index+1}}-{{(diversityValue*(index+1)>=totalRow?totalRow:diversityValue*(index+1))}}
                </f7-button>
              </f7-col>
            </f7-row>
          </f7-page-content>
        </f7-sheet>

        <f7-list media-list class="album-content-list">
          <f7-list-item v-for="(el,index) in albumChilds" :key="el.id"
                        :id="'albumAudio_'+el.sort"
                        @click="tipAlbumPlayerPage(el,index)">
            <template #title>
              <span class="album-try-mask"
                    v-if="el.is_free&&!(albumInfo.is_buy||(userInfo.hasOwnProperty('member_code')&&userInfo.member_code!=null&&userInfo.member_code!=''))">试听</span>
              {{el.title||''}}
            </template>
            <template #text>
              <p class="album-content-item-p">
                <span class="album-content-type">
                  {{el.type =='audio'?'音频':(el.type =='article'?'文章':'其他')}}
                </span>
                {{el.create_time.substr(0,10)||''}}
                {{el.hasOwnProperty('create_time')&&el.hasOwnProperty('study_times')&&el.study_times
                ?' | ':''}}
                {{el.hasOwnProperty('study_times')&&el.study_times?el.study_times+'次收听':''}}
                <f7-icon f7="play_circle"
                         class="album-content-item-play"></f7-icon>
              </p>
            </template>
            <template #content>
              <span class="album-history-mask" v-if="lastPlayedId==el.id">
                {{el.type =='audio'?'上次听到':(el.type =='article'?'上次读到':'上次记录')}}
              </span>
            </template>
          </f7-list-item>
        </f7-list>
      </f7-page-content>
    </f7-tabs>
  </f7-page>
</template>

<script lang="ts">
  import {Options, Vue} from "vue-class-component";
  import AlbumHeader from "@/components/album-header.vue";
  import TitleShare from "@/components/title-share.vue";
  import Utils from "@/js/utils/utils";
  import Http from "@/js/utils/http";
  import StorageUtils from "@/js/utils/storage";
  import DateUtils from "@/js/utils/date";
  import CommonUtils from "@/js/common/common";

  @Options({
    components: {
      AlbumHeader,
      TitleShare,
    },
  })
  export default class AlbumInfoPage extends Vue {
    isApp: boolean = Http.isApp;
    name: string = "album-info-page";
    // logoUrl: string = './static/img/logo.jpg';
    logoUrl: any = require('@/assets/img/logo.png').default;
    currentActiveTab: string = 'content'; // content 详情; catalog 目录; diversity 分集
    albumInfo: any = {
      is_cycle: false,
      create_time: "",
      pid: 0,
      title: "",
      type: "",
      cycle: [],
      content: "",
      cover: [],
      money: 0,
      subtitle: "",
      is_free: false,
      id: -1,
      audio: [],
      is_buy: 0,
      org_money: 0, // 原价
      follow_number: 0, // 订阅人数
      new_number: 0, // 更新集数
      time_limit_discount_start_time: '', // 限制优惠的开始时间
      time_limit_discount_end_time: '', // 限制优惠的结束时间
      time_limit_discount_price: '', // 限制优惠的价格
      time_limit_discount_status: 0, // 专辑类型：1限制优惠专辑；0正常专辑
    };
    loading: boolean = true;
    preloader: boolean = true;
    pageSize: number = 10;
    curPage: number = 1;
    totalPage: number = 1;
    totalRow: number = 0;
    albumChilds: any[] = []; // 正序排列的音频列表
    userInfo: any = {}; // 用户信息
    from: string = ''; // 从哪里进入专辑详情页的
    historyLocalAlbumQuery: any = {}; // 历史记录中收听的最后一个音频信息
    lastPlayedIndex: number = -1; // 最后播放的index值
    lastPlayedId: number = -1; // 最后播放音频的id
    isShowDiversity: boolean = false; // 是否显示分集
    activeDiversity: number = 0;
    diversityValue: number = 20;
    ptrPreloader: boolean = true;
    previousCurPage: number = 1;
    limitedInterval: any = null; // 限时优惠倒计时计时器
    limitedInfo: any = {
      day: '00',
      hour: '00',
      minute: '00',
      second: '00',
    };// 限时优惠倒计时信息

    created(): void {
      CommonUtils.getUserInfo(this, (params: any): void => {
        this.userInfo = params || {};
        const item: any = StorageUtils.getByModule('album.query');
        // console.log('++++++++++album query+++++', item)
        if (item != null) {
          this.from = item.from || '';
          this.albumInfo.id = item.id || -1;
          this.albumInfo.title = item.title || "";
          this.getHistoryAudioQuery();
          this.getAlbumQuery();
          this.getAlbumAudioBy(this.curPage, 'next');
        }
      });
    }

    onPageBeforeIn(): void {
      Utils.fireEvent(Utils.kEvents.updateTitleShareBtnStatus, true);
      const item: any = StorageUtils.getByModule('album.query');
      if (item) {
        document.title = item.title || '快乐听吧';
      } else {
        document.title = this.albumInfo.title || '快乐听吧';
      }
    }

    onPageBeforeOut(): void {
      if (this.limitedInterval) {
        clearInterval(this.limitedInterval);
      }
      this.isShowDiversity = false;
    }

    // 更新当前显示
    onUpdateTabActive(params: any): void {
      this.currentActiveTab = params || 'content';
    }

    get cycleArr(): any[] {
      let resCycleArr: any[] = [];
      let time_limit_discount_status: number = this.albumInfo && this.albumInfo.hasOwnProperty('time_limit_discount_status') && this.albumInfo.time_limit_discount_status ? this.albumInfo.time_limit_discount_status : 0;
      let time_limit_discount_start_time: number = this.albumInfo && this.albumInfo.hasOwnProperty('time_limit_discount_start_time') && this.albumInfo.time_limit_discount_start_time ? this.albumInfo.time_limit_discount_start_time : '';
      let time_limit_discount_end_time: number = this.albumInfo && this.albumInfo.hasOwnProperty('time_limit_discount_end_time') && this.albumInfo.time_limit_discount_end_time ? this.albumInfo.time_limit_discount_end_time : '';
      let time_limit_discount_price: number = this.albumInfo && this.albumInfo.hasOwnProperty('time_limit_discount_price') && this.albumInfo.time_limit_discount_price ? this.albumInfo.time_limit_discount_price : '';
      let cycleArr: any[] = this.albumInfo && this.albumInfo.hasOwnProperty('cycle') && this.albumInfo.cycle ? this.albumInfo.cycle : [];
      if (cycleArr.length > 0) {
        for (let i: number = 0; i < cycleArr.length; i++) {
          let item: any = cycleArr[i];
          item['time_limit_discount_status'] = time_limit_discount_status;
          item['time_limit_discount_start_time'] = time_limit_discount_start_time;
          item['time_limit_discount_end_time'] = time_limit_discount_end_time;
          item['time_limit_discount_price'] = time_limit_discount_price;
          resCycleArr.push(item);
        }
      }
      return resCycleArr;
    }

    onUpdateDiversityShow(params: any): void {
      if (this.currentActiveTab == 'catalog') {
        this.isShowDiversity = params;
      }
    }

    /**
     * 下拉刷新
     */
    getPreviousAudioList(done: any): void {
      setTimeout((): void => {
        if (this.previousCurPage <= 1) {
          this.ptrPreloader = false;
          return;
        }
        this.getAlbumAudioBy(this.previousCurPage - 1, 'previous');
        done();
      }, 1000);
    }

    getAlbumAudioBy(cur_page: number, direction: string = 'next', successHandler: any = null): void {
      if (!(this.albumInfo.hasOwnProperty('id') && this.albumInfo.id) || cur_page <= 0) {
        return;
      }
      let filterObj: any = {
        pid: this.albumInfo.id,
      };
      // if (!(this.albumInfo.is_buy || this.userInfo.member_code != null || this.albumInfo.is_free)) {
      //   Object.assign(filterObj, {
      //     is_free: true,
      //   });
      // }
      let filter: any [] = [];
      filter.push(filterObj)
      filter.push({
        is_soldout: false,
      });

      let data: any = {
        page: cur_page,
        size: this.pageSize,
        filter: filter,
      };
      let successList: any = (res: any): void => {
        this.totalPage = res.hasOwnProperty('total') ? res.total : 1;
        const page_number: number = res.hasOwnProperty('page') && res.page ? res.page : 1;
        this.totalRow = res.hasOwnProperty('rows') ? res.rows : 0;
        let curResData: any[] = res.hasOwnProperty('data') ? res.data : [];
        for (let i: number = 0; i < curResData.length; i++) {
          curResData[i]['sort'] = (page_number - 1) * this.pageSize + i + 1;
        }

        if (this.totalPage <= 1) {
          this.preloader = false;
        }

        if (successHandler && typeof successHandler == "function") {
          successHandler(curResData);
        } else {
          let afterArr: any[] = (page_number <= 1) && direction == 'next' ? [] : this.albumChilds;
          if (afterArr.length == 0) {
            this.curPage = page_number;
            this.previousCurPage = page_number;
          }
          switch (direction) {
            case 'next':
              this.curPage = page_number;
              if (this.curPage <= 1) {
                afterArr = curResData;
              } else {
                for (let i: number = 0; i < curResData.length; i++) {
                  afterArr.push(curResData[i]);
                }
              }
              break;
            case 'previous':
              this.previousCurPage = page_number;
              for (let i: number = 0; i < curResData.length; i++) {
                afterArr.splice(i, 0, curResData[i]);
              }
              break;
          }
          this.albumChilds = afterArr;

          if (this.from == 'history') {
            let historyPlayedInd: number = this.lastPlayedIndex;
            for (let i: number = 0; i < this.albumChilds.length; i++) {
              if (this.albumChilds[i].id == this.lastPlayedId) {
                historyPlayedInd = i;
              }
            }
            this.tipAlbumPlayerPage(this.albumChilds[historyPlayedInd], historyPlayedInd);
          }
        }

      };
      let params: any = {
        url: Http.kInterfacePaths.album.list,
        data: data,
        warn_info: '获取列表失败！',
        success: successList,
      };
      Http.ajax(this, params);
    }

    getNextAlbumChildrenList(): void {
      if (!this.loading) return;
      this.loading = false;
      setTimeout((): void => {
        if (this.curPage >= this.totalPage) {
          this.preloader = false;
          return;
        }
        let curPageNumber = this.curPage + 1;
        this.getAlbumAudioBy(curPageNumber, 'next');
        this.loading = true;
      }, 1000);
    }

    /**
     * 跳转到对应专辑播放页或文章查看页等
     * @param item:专辑/文章对应项
     */
    tipAlbumPlayerPage(item: any, ind: number): void {
      // console.log('是否可收听', item.is_free, this.albumQuery.is_buy, this.userInfo.member_code != null);
      if (this.albumInfo.is_free || item.is_free || this.albumInfo.is_buy || this.userInfo.member_code != null) {
        const historyTime: number = (this.lastPlayedId == item.id) ? (this.historyLocalAlbumQuery != null && this.historyLocalAlbumQuery.audio ? (this.historyLocalAlbumQuery.audio.currentTime || 0) : 0) : 0;
        const data: any = {
          from: this.from,
          is_buy: this.albumInfo.is_buy,
          is_member: this.userInfo.member_code != null,
          startPlayTime: historyTime,
          album: this.albumInfo,
          audio: item,
          id: item.id || -1,
          title: item.title || "",
          ind: item.sort - 1,
        };
        // console.log('history audio info====', this.historyLocalAlbumQuery.audio);
        // console.log('album info audio data====', data);
        StorageUtils.updateByModule('album.play', data);
        StorageUtils.updateByModule('audioDom.audioPlaying', true)
        Utils.fireEvent(Utils.kEvents.changeAlbumPlayer, {
          isFixed: false,
          album: this.albumInfo,
          audio: item,
          playData: data,
        });
        Utils.fireEvent(Utils.kEvents.updatePopupShow, true);
      } else {
        let contactDialog = window.f7.dialog.alert('请您先购买该专辑或成为会员');
        Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
          opened: true,
          dialog: contactDialog,
        });
      }
    }

    /**
     * 获取专辑详情
     */
    getAlbumQuery(): void {
      const data: any = {
        id: this.albumInfo.id || -1,
        uid: this.userInfo.id || -1,
      };
      const successQuery: any = (res: any): void => {
        const resData: any = res.hasOwnProperty('data') && res.data ? res.data : this.albumInfo;
        this.albumInfo = resData;
        this.$nextTick((): void => {
          const t: any = {
            id: (this.albumInfo.id || -1),
            title: this.albumInfo.title || "",
            subtitle: this.albumInfo.subtitle || "",
            thumb: (this.albumInfo.cover && this.albumInfo.cover.length > 0) ? (this.albumInfo.cover[0].url || '') : '',
            from: 'invite-friends',
          };
          CommonUtils.setWechatShare(this, t);
        });
        if (this.albumInfo.hasOwnProperty('time_limit_discount_status') && this.albumInfo.time_limit_discount_status == 1 && this.albumInfo.time_limit_discount_end_time) {
          this.limitedInterval = setInterval((): void => {
            this.limitedInfo = DateUtils.getCutOffTimeTo(this.albumInfo.time_limit_discount_end_time);
            if (this.limitedInfo.day == '00' && this.limitedInfo.hour == '00' && this.limitedInfo.minute == '00' && this.limitedInfo.second == '00') {
              clearInterval(this.limitedInterval);
            }
          }, 1000);
        }
        // if (this.albumQuery.hasOwnProperty('type') && this.albumQuery.type == 'fineGoods') {
        //   this.$nextTick((): void => {
        //     const tabbarActive: any = document.querySelectorAll("span.tab-link-highlight")[0];
        //     tabbarActive.style.width = '100%';
        //   });
        // }
      };
      const params: any = {
        url: Http.kInterfacePaths.album.query,
        data: data,
        warn_info: '获取专辑详情失败！',
        success: successQuery,
      };
      Http.ajax(this, params);
    }

    /**
     * 获取上次专辑历史记录点
     */
    getHistoryAudioQuery(): void {
      const data: any = {
        wid: this.userInfo.id || -1,
        apid: this.albumInfo.id || -1,
      };
      const errorQuery: any = (res: any): void => {
      };
      const successQuery: any = (res: any): void => {
        this.historyLocalAlbumQuery = res.data ? res.data : null;
        if (this.historyLocalAlbumQuery != null && this.historyLocalAlbumQuery.hasOwnProperty('id')) {
          this.lastPlayedId = this.historyLocalAlbumQuery.audio.id;
        }
      };
      const params: any = {
        url: Http.kInterfacePaths.history.query,
        data: data,
        // warn_info: '获取专辑历史记录失败！',
        success: successQuery,
        error: errorQuery,
      };
      Http.ajax(this, params);
    }

    /**
     * 改变显示分集模块
     * @param ind
     */
    changeActiveDiversityRange(ind: number): void {
      this.activeDiversity = ind;
      this.isShowDiversity = false;
      this.albumChilds = [];
      this.curPage = ((ind + 1) * this.diversityValue) / 10 - 1;
      this.getAlbumAudioBy(this.curPage, 'next');
    }

    tipAlbumBuyConfirmPage(): void {
      const data: any = {
        from: 'album',
        is_share: false,
        share_info: {},
        order: this.albumInfo,
      };
      StorageUtils.updateByModule('buy.query', data);
      Utils.load(this, '/buy_confirm_album');
    }

    tipVipPage(): void {
      Utils.load(this, '/vip', {
        from: 'album',
      });
    }

  }
</script>

<style scoped>

</style>

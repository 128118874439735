
  import {Options, Vue} from "vue-class-component";
  import {Prop, Watch} from "vue-property-decorator";

  @Options({})
  export default class BannerList extends Vue {
    @Prop(String) divId!: string;
    @Prop(Array) banners!: any[];
    @Prop(Object) params!: any;
    name: string = "banner-list";
    // limitedOfferIcon: string = './static/img/icon_limited_offer1.png';
    limitedOfferIcon: any = require('@/assets/img/icon_limited_offer1.png').default;

    created(): void {
    }

    @Watch('banners', {immediate: true, deep: true})
    onChangeBannerList(val: any, oldValue: any): void {
      if (this.banners.length > 1) {
        this.$nextTick((): void => {
          let options: any = {
            centeredSlides: true,
            speed: 500,
            preventLinksPropagation: false,   // 阻止点击事件冒泡
          };
          if (this.params.hasOwnProperty('isLoop') && this.params.isLoop) {
            Object.assign(options, {
              loop: true,
            });
          }
          if (this.params.hasOwnProperty('isAutoPlay') && this.params.isAutoPlay) {
            Object.assign(options, {
              autoplay: {
                disableOnInteraction: false,
                delay: 2500,
              },
              pagination: {
                el: ".swiper-pagination",
                clickable: true,
              },
            });
          }
          let swiper: any = new Swiper('#' + this.divId, options);

          // var swiper = new Swiper("#" + this.divId, {
          //   // spaceBetween: 30,
          //   centeredSlides: true,
          //   speed: 500,
          //   loop: true,
          //   autoplay: {
          //     disableOnInteraction: false,
          //     delay: 2500,
          //   },
          //   pagination: {
          //     el: ".swiper-pagination",
          //     clickable: true,
          //   },
          //   preventLinksPropagation: false,   // 阻止点击事件冒泡
          //   // navigation: {
          //   //   nextEl: ".swiper-button-next",
          //   //   prevEl: ".swiper-button-prev",
          //   // },
          // });
        });
      }
    }

    tipBannerItem(item: any): void {
      this.$emit('clickBannerItem', item);
    }
  }

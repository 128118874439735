// Import Vue
import {createApp} from 'vue';

// Import Framework7
import Framework7 from 'framework7/lite-bundle';

// Import Framework7-Vue Plugin
import Framework7Vue, {registerComponents} from 'framework7-vue/bundle';


// Import Framework7 Styles
// import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles

// Import App Component
import App from '../components/app.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);
// Init App
const app = createApp(App);

// Vue.config.ignoredElements = [
//   'wx-open-audio',
//   // 用一个 `RegExp` 忽略所有“ion-”开头的元素
//   // 仅在 2.5+ 支持
//   // /^ion-/
// ];
// app.config.compilerOptions.isCustomElement = tag => tag === 'wx-open-audio';
app.config.compilerOptions.isCustomElement = tag => tag.startsWith('wx-open-');

// Register Framework7 Vue components
registerComponents(app);

// TODO.start 设置全局变量
// app.config.globalProperties.f7 = f7;
// app.config.globalProperties.temp='hello world!!!';
// TODO.end 设置全局变量

// Mount the app
app.mount('#app');

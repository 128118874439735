<template>
  <f7-view>
    <f7-page name="playerAudiosPopup" :no-toolbar="true"
             :ptr="ptrPreloader" :ptr-distance="56"
             @ptr:refresh="getPreviousAudioList"
             infinite :infinite-distance="50"
             :infinite-preloader="preloader" @infinite="getNextAlbumList"
    >
      <f7-navbar>
        <f7-nav-title class="title-frame" style="overflow:hidden;">
          <f7-link class="audio-play-title-tab color-orange">
            正在播放
            <span class="audio-play-title-line"></span>
          </f7-link>
          <f7-link class="audio-play-mode color-orange"
                   @click="toggleAudioMode">
            <!--循环播放-->
            <f7-icon v-if="mode=='circulation'" f7="repeat"></f7-icon>
            <!--单曲循环播放-->
            <f7-icon v-if="mode=='single'" f7="repeat_1"></f7-icon>
            <!--顺序播放-->
            <f7-icon v-if="mode=='order'" f7="menu"></f7-icon>
            {{mode=='circulation'?'循环播放':(mode=='single'?'单曲循环':'顺序播放')}}
          </f7-link>
        </f7-nav-title>
      </f7-navbar>
      <f7-list style="margin:0;">
        <f7-list-item class="audio-play-item"
                      v-for="(el,index) in albums" :key="el.id"
                      :class="{'active':(el.id==audioInfo.id)}"
                      @click="changePlayingAudio(el, index)">
          <template #title>
            <p class="audio-play-item-title">
            <span class="album-try-mask"
                  v-if="el.is_free&&!(albumInfo.is_buy||(userInfo.hasOwnProperty('member_code')&&userInfo.member_code!=null&&userInfo.member_code!=''))">试听</span>
              <span class="audio-type">
              {{el.type =='audio'?'音频':(el.type =='article'?'文章':'其他')}}
            </span>
              {{el.title||''}}
              <span class="audio-play-progress-percent"
                    v-if="el.id==audioInfo.id">
                已播{{audioProgress!='100%'?audioProgress:'完'}}
              </span>
            </p>
          </template>
        </f7-list-item>
      </f7-list>
    </f7-page>
  </f7-view>
</template>

<script lang="ts">
  import {Options, Vue} from "vue-class-component";
  import Http from "../js/utils/http";
  import {Prop, Watch} from "vue-property-decorator";
  import albumListApi from '../js/api/albumList'
  import Utils from "@/js/utils/utils";

  @Options({})
  export default class AudioPlayList extends Vue {
    @Prop(Object) userInfo!: any; // 用户信息
    @Prop(Boolean) isTry!: boolean; // 是否试用
    @Prop({default: {}}) playAudio!: any; // 要播放音频的所有信息，包含album/audio/playData
    // @Prop(Object) albumInfo!: any; // 专辑信息
    // @Prop(Object) audioInfo!: any; // 播放音频
    // @Prop(Object) playData!: any; // 播放音频
    @Prop({default: 'circulation'}) mode!: string; // 播放模式
    @Prop({default: '0%'}) audioProgress!: string; // 播放百分比进度
    @Prop({default: -1}) refreshPageList!: number; // 是否获取同一专辑的第一页信息
    @Prop({default: -1}) updateList!: number;
    name: string = "audio-play-list";

    albumInfo: any = {}; // 专辑信息
    audioInfo: any = {}; // 播放音频
    playData: any = {}; // 播放音频

    previousCurPage: number = 1;
    ptrPreloader: boolean = true;
    loading: boolean = true;
    preloader: boolean = true;
    pageSize: number = 10;
    curPage: number = 1;
    totalPage: number = 1;
    totalRow: number = 0;
    albums: any [] = [];
    albumIds: number[] = [];

    @Watch('playAudio', {immediate: true, deep: true})
    onChangePlayingAudio(val: any, oldVal: any): void {
      if (val && !oldVal) {
        // 初次进入
        this.albumInfo = val.album || {};
        this.audioInfo = val.audio || {};
        this.playData = val.playData || {};
        this.refreshAlbumList();
      } else if (val && oldVal) {
        this.albumInfo = val.album || {};
        this.audioInfo = val.audio || {};
        this.playData = val.playData || {};
        if (val.album.id != oldVal.album.id) {
          // 更换专辑
          this.refreshAlbumList();
        } else {
          // 同一专辑，不同audio
        }
      } else {
        // 没有任何值，不需要任何操作
      }
    }

    get audioInfoAndAlbums(): any {
      return {
        audio: this.audioInfo,
        albums: this.albums,
      };
    }

    @Watch('updateList', {immediate: true, deep: true})
    onUpdateList(val: any, oldVal: any): void {
      if (val) {
        console.log('updateList====', val, oldVal)
        this.refreshAlbumList();
      }
    }

    @Watch('audioInfoAndAlbums', {immediate: true, deep: true})
    onUpdateAudioInfoAndAlbums(val: any, oldVal: any): void {
      const audioObj: any = val.audio || {};
      const albums: any[] = val.albums || [];
      if (audioObj.hasOwnProperty('id') && audioObj.id != -1 && albums.length > 0) {
        const len: number = albums.length;
        if (audioObj.id == albums[0].id && albums[0].sort != 0) {
          // 自动获取上一页信息
          console.log('自动获取上一页信息', this.previousCurPage - 1)
          this.getAlbumAudioBy(this.previousCurPage - 1, 'previous');
        }
        if (audioObj.id == albums[len - 1].id && albums[0].sort != (this.totalRow - 1)) {
          // 自动获取下一页信息
          console.log('自动获取下一页信息', this.curPage + 1)
          this.getAlbumAudioBy(this.curPage + 1);
        }
      }
    }

    @Watch('isTry', {immediate: true, deep: true})
    onChangeTry(val: any, oldVal: any): void {
      if (!val) { // 会员
        this.refreshAlbumList();
      }
    }

    @Watch('refreshPageList', {immediate: true, deep: true})
    onChangeRefreshPageList(val: any, oldVal: any): void {
      if (val != -1) {
        this.preloader = true;
        this.ptrPreloader = true;
        this.albums = [];
        this.albumIds = [];
        this.curPage = val;
        this.previousCurPage = val;
        this.getAlbumAudioBy(this.curPage);
      }
    }

    refreshAlbumList(): void {
      this.albums = [];
      this.albumIds = [];
      let cur_page: number = 1;

      // if (this.audioInfo.hasOwnProperty('sort')) {
      //   const index: number = this.audioInfo.sort || 0;
      //   cur_page = Math.ceil(index / this.pageSize);
      // }

      if (this.playData && this.playData.hasOwnProperty('ind') && typeof this.playData.ind == "number") {
        const index: number = (this.playData.ind + 1) || 0;
        cur_page = Math.ceil(index / this.pageSize);
      }
      console.log('refreshAlbumList===', this.playData, cur_page)

      this.curPage = cur_page;
      this.previousCurPage = cur_page;
      this.getAlbumAudioBy(this.curPage);
    }

    getNextAlbumList(): void {
      if (!this.loading) return;
      this.loading = false;
      setTimeout((): void => {
        if (this.curPage >= this.totalPage) {
          this.preloader = false;
          return;
        }
        const curPageNumber: number = this.curPage + 1;
        this.getAlbumAudioBy(curPageNumber, 'next');
        this.loading = true;
      }, 1000);
    }

    getPreviousAudioList(done: any): void {
      setTimeout((): void => {
        if (this.previousCurPage <= 1) {
          this.ptrPreloader = false;
          return;
        }
        this.getAlbumAudioBy(this.previousCurPage - 1, 'previous');
        done();
      }, 1000);
    }

    getAlbumAudioBy(cur_page: number, direction: string = 'next', successHandler: any = null): void {
      let _this = this;
      if (!(this.albumInfo.hasOwnProperty('id') && this.albumInfo.id) || cur_page <= 0) {
        return;
      }
      let filterObj: any = {
        pid: this.refreshPageList == -1 ? this.audioInfo.pid : this.albumInfo.id,
      };
      if (this.isTry) {
        Object.assign(filterObj, {
          is_free: true,
        });
      }
      let filter: any [] = [];
      filter.push(filterObj)
      filter.push({
        is_soldout: false,
      });
      let data: any = {
        page: cur_page,
        size: this.pageSize,
        filter: filter,
      };
      let successList: any = (res: any): void => {
        this.totalPage = res.hasOwnProperty('total') ? res.total : 1;
        const page_number: number = res.hasOwnProperty('page') && res.page ? res.page : 1;
        this.totalRow = res.hasOwnProperty('rows') ? res.rows : 0;
        let curResData: any[] = res.hasOwnProperty('data') ? res.data : [];
        for (let i: number = 0; i < curResData.length; i++) {
          // let sortNumber: number = (page_number - 1) * this.pageSize + i + 1;
          curResData[i]['sort'] = (page_number - 1) * this.pageSize + i + 1;
        }
        // console.log('~~~~~~total page===', this.totalPage);
        if (this.totalPage <= 1 || page_number >= this.totalPage) {
          this.preloader = false;
        }

        if (successHandler && typeof successHandler == "function") {
          successHandler(curResData);
        } else {
          let afterArr: any[] = (page_number <= 1) && direction == 'next' ? [] : this.albums;
          switch (direction) {
            case 'next':
              this.curPage = page_number;
              if (this.curPage <= 1) {
                afterArr = curResData;
              } else {
                for (let i: number = 0; i < curResData.length; i++) {
                  afterArr.push(curResData[i]);
                }
              }
              break;
            case 'previous':
              this.previousCurPage = page_number;
              for (let i: number = 0; i < curResData.length; i++) {
                afterArr.splice(i, 0, curResData[i]);
              }
              break;
          }
          this.albums = afterArr;
          let ids: number[] = [];
          for (let i: number = 0; i < this.albums.length; i++) {
            if (!this.albums[i].id) continue;
            ids.push(this.albums[i].id);
          }
          if (this.audioInfo.id != -1 && ids.length > 0 && ids.indexOf(this.audioInfo.id) < 0) {
            this.refreshAlbumList();
          }
          this.albumIds = ids;

          this.$emit('changeAlbumAudios', {
            totalRow: this.totalRow,
            curPage: this.curPage,
            pageSize: this.pageSize,
            list: afterArr,
          });
        }

      };
      console.log('--------------------------', data)
      let params: any = {
        url: Http.kInterfacePaths.album.list,
        data: data,
        warn_info: '获取记录失败！',
        success: successList,
      };
      Http.ajax(this, params);

      // if (_this.times) {
      //   clearTimeout(_this.times)
      // }
      // _this.times = setTimeout(function () {
      //   if (_this.times1) {
      //     clearTimeout(_this.times1)
      //   }
      //   // data.size = 10;
      //   _this.dataList = [];
      //   _this.getList(data, cur_page, direction, successHandler);
      //   console.log('===to=getList======', _this.dataList.length)
      // }, 600)
      // _this.albums = [];
      // _this.albumIds = [];
      // _this.$emit('changeAlbumAudios', {
      //   totalRow: 0,
      //   curPage: 1,
      //   pageSize: _this.pageSize,
      //   list: [],
      // });
    }

    times: any = null;
    times1: any = null;
    dataList: any [] = [];

    getList(data: any, cur_page: number, direction: string = 'next', successHandler: any = null): void {
      let _this = this;
      if (_this.times1) {
        clearTimeout(_this.times1)
      }
      let params: any = {
        url: Http.kInterfacePaths.album.list,
        data: data,
        warn_info: '查询列表失败！',
        success: function (res: any) {
          const _data = res.data || [];
          let rows = res.rows || 0;
          _this.dataList.push(..._data);
          // console.log('===========', _this.dataList.length)
          if (rows > _this.dataList.length) {
            _this.times1 = setTimeout(function () {
              data.page += 1;
              _this.getList(data, cur_page, direction, successHandler);
            }, 10)
          } else {
            _this.setData(res, cur_page, direction, successHandler);
          }
        },
      };
      Http.ajax(this, params);
      // albumListApi.getList(data).then((res: any) => {
      //   const _data = res.data || [];
      //   let rows = res.rows || 0;
      //   _this.dataList.push(..._data);
      //   console.log('===========', _this.dataList.length)
      //   if (rows > _this.dataList.length) {
      //
      //     _this.times1 = setTimeout(function () {
      //       data.page += 1;
      //       _this.getList(data, cur_page, direction, successHandler);
      //     }, 10)
      //   } else {
      //     _this.setData(res, cur_page, direction, successHandler);
      //   }
      // }).catch((e: any) => {
      //   let contactDialog = window.f7.dialog.alert('查询列表失败！');
      //   Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
      //     opened: true,
      //     dialog: contactDialog,
      //   });
      // })
    }

    setData(res: any, cur_page: number, direction: string = 'next', successHandler: any = null): void {
      let _this = this;
      this.totalPage = res.hasOwnProperty('total') ? res.total : 1;
      const page_number: number = res.hasOwnProperty('page') && res.page ? res.page : 1;
      this.totalRow = res.hasOwnProperty('rows') ? res.rows : 0;
      let curResData: any[] = _this.dataList;//所有数据
      for (let i: number = 0; i < curResData.length; i++) {
        curResData[i]['sort'] = i + 1;
      }
      if (this.totalPage <= 1 || page_number >= this.totalPage) {
        this.preloader = false;
      }

      if (successHandler && typeof successHandler == "function") {
        successHandler(curResData);
      } else {
        let afterArr: any[] = (page_number <= 1) && direction == 'next' ? [] : this.albums;
        switch (direction) {
          case 'next':
            this.curPage = page_number;
            if (this.curPage <= 1) {
              afterArr = curResData;
            } else {
              for (let i: number = 0; i < curResData.length; i++) {
                afterArr.push(curResData[i]);
              }
            }
            break;
          case 'previous':
            this.previousCurPage = page_number;
            for (let i: number = 0; i < curResData.length; i++) {
              afterArr.splice(i, 0, curResData[i]);
            }
            break;
        }
        this.albums = afterArr;
        let ids: number[] = [];
        for (let i: number = 0; i < this.albums.length; i++) {
          if (!this.albums[i].id) continue;
          ids.push(this.albums[i].id);
        }
        if (this.audioInfo.id != -1 && ids.length > 0 && ids.indexOf(this.audioInfo.id) < 0) {
          //this.refreshAlbumList();
        }
        this.albumIds = ids;

        this.$emit('changeAlbumAudios', {
          totalRow: this.totalRow,
          curPage: this.curPage,
          pageSize: this.pageSize,
          list: afterArr,
        });
      }
    }

    toggleAudioMode(): void {
      let resMode: string = this.mode;
      switch (this.mode) {
        case 'circulation':
          //当前循环播放--》单曲循环
          resMode = 'single';
          break;
        case 'single':
          //当前单曲循环--》顺序播放
          resMode = 'order';
          break;
        case 'order':
          //当前顺序播放--》循环播放
          resMode = 'circulation';
          break;
      }
      this.$emit('changeModeStatus', resMode);
    }

    changePlayingAudio(item: any, ind: number): void {
      this.$emit('changePlayedAudio', {
        sort: item.sort,
        ind: ind,
        audioInfo: item,
      });
    }

  }
</script>

<style scoped>

</style>

import {Base64} from 'js-base64';

let emitter = require('tiny-emitter/instance');
const Utils: any = {
  kEvents: {
    // TODO.start 要监听的事件名称，不可重复
    changeAlbumPlayer: 'change.albumPlaying',
    updateAlbumQuery: 'update.albumQuery',
    changeHistoryList: 'change.historyList',
    updateIsMemberInfo: 'update.isMemberInfo', // 监听更新用户是否为会员信息
    updatePersonUserInfo: 'update.personUserInfo', // 监听更新用户是否为会员信息
    updateHomePageName: 'update.homePageName', // 监听home页面tab页切换变化并反馈给app主页面
    changeHomeActiveTab: 'change.homeActiveTab', // 主页面要求由其他tab页返回至首页的变化给home页面
    updateIsCanReturnCustom: 'update.isCanReturnCustom',
    updateHistoryList: 'update.historyList', // 监听是否更新我听页面的历史记录
    updateAlertIsShow: 'update.alertIsShow',
    updatePopupShow: 'update.popupShow', // 监听home页面点击了最小化popup
    showPreviousPlayingAudio: 'show.previousPlayingAudio', // 初始进入时，把上一次播放的音频信息赋值给播放器并最小化
    updateAudioPlayMinStatus: 'update.audioPlayMinStatus', // 更新播放器的显示状态
    updateTitleShareBtnStatus: 'update.titleShareBtnStatus', // 更新标题栏分享按钮是否显示的状态
    updateHomeBottomView: 'update.homeBottomView', // 主页底部显示历史最新记录
  },
  // bindEvent: (context: any, name: string, func: any) => {
  bindEvent: (name: string, func: any) => {
    // context.$root._data._eventBus.$on(name, func);
    emitter.on(name, func);
  },
  // onceEvent: (context: any, name: string, func: any) => {
  onceEvent: (name: string, func: any) => {
    // context.$root._data._eventBus.$once(name, func);
    emitter.once(name, func);
  },
  // fireEvent: (context: any, name: string, obj: any) => {
  fireEvent: (name: string, obj: any) => {
    // context.$root._data._eventBus.$emit(name, obj);
    emitter.emit(name, obj);
  },
  // unbindEvent: (context: any, name: string, func: any) => {
  unbindEvent: (name: string, func: any) => {
    // context.$root._data._eventBus.$off(name, func);
    emitter.off(name, func);
  },
  load: (context: any, url: string, data?: object, options?: object): void => {
    const dataStr: string = (typeof data == 'string' ? data : ((data != undefined && data != null) ? JSON.stringify(data) : ''));
    // const loadUrl: string = url + ((data != undefined && data != null) ? '?data=' + JSON.stringify(data) : '');
    // const loadUrl: string = url + ((data != undefined && data != null) ? '?data=' + dataStr : '');
    const loadUrl: string = url + ((dataStr !== '') ? '?data=' + dataStr : '');
    window.f7.views.main.router.navigate(loadUrl, options);
    // if (routerObj != undefined && routerObj != null && routerObj != '') {
    //   context.$f7router.navigate(loadUrl, options);
    // } else {
    //   context.$f7.views.main.router.navigate(loadUrl, options);
    // }
  },
  back: (context: any, url: string, data?: object, options?: object): void => {
    // context.$f7router.back(url + '?data=' + JSON.stringify(data), options);
    window.f7.views.main.router.back(url + '?data=' + (!data ? JSON.stringify(data) : ''), options)
  },
  // getParamData: (context: any): any => {
  getParamData: (): any => {
    return new Promise((resolve, reject): void => {
      try {
        setTimeout((): void => {
          const queryObj: any = window.f7.views.main.router.currentRoute.query;
          const data: string = queryObj != null ? queryObj.data : '';
          let res: any = {};
          try {
            res = (data != null && data != undefined && data.trim().length > 0) ? JSON.parse(data) : {};
          } catch (e) {
            res = {};
          }
          resolve(res);
        }, 80);
      } catch (e) {
        reject(e);
      }
    });

  },
  // alert: (txt: string, callback?: any): any => {
  //   return window.f7.dialog.alert(txt, callback);
  // },
  /**
   * 获取地址栏参数
   * @param key
   */
  getUrlParam: (key: string): any => {
    const res: any = null;
    let params: any = window.location.search.substring(1);
    if (!params) {
      return;
    }
    params = params.split('&');
    for (const item of params) {
      const itemArr: any[] = item.split('=');
      // console.log(itemArr[0]);
      // console.log(itemArr[1]);
      res[itemArr[0]] = decodeURIComponent(itemArr[1]);
    }
    if (key != undefined && res != null) {
      return res[key];
    }
    return res;
  },
  getRequestObject: (urlStr: string): any => {
    let url: string = '';
    if (typeof urlStr == 'undefined') {
      url = decodeURI(location.search); // 获取url中"?"符后的字符串
    } else {
      url = '?' + urlStr.split('?')[1];
    }
    // @ts-ignore
    let theRequest: any = new Object();
    if (url.indexOf('?') != -1) {
      const str: any = url.substr(1);
      const strs: any = str.split('&');
      for (let i: number = 0; i < strs.length; i++) {
        theRequest[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1]);
      }
    }
    return theRequest;
  },

  getQueryString: (name: string, urlStr: string): any => {
    let url: string = '';
    if (typeof urlStr == 'undefined') {
      url = decodeURI(location.search); // 获取url中"?"符后的字符串
    } else {
      url = '?' + urlStr.split('?')[1];
    }

    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = url.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },


  /**
   * 编码，type：base64,sha1,md5
   * @param type
   */
  encode: (type: string, str: string): string => {
    let res: string = '';
    switch (type) {
      case 'base64':
        // res = Base64.encode(str);
        res = window.btoa(unescape(encodeURIComponent(str)));
        break;
      case 'sha1':
        break;
      case 'md5':
        break;
    }
    return res;
  },
  /**
   * 解码，type：base64,sha1,md5
   * @param type
   */
  decode: (type: string, encodeStr: string): any => {
    let res: any = null;
    switch (type) {
      case 'base64':
        // encodeStr = encodeStr.replaceAll(' ', '+');
        // res = Base64.decode(encodeStr);
        res = decodeURIComponent(escape(window.atob(encodeStr)));
        break;
      case 'sha1':
        break;
      case 'md5':
        break;
    }
    return res;
  },
  /**
   * 按照有效数字位数进行四舍五入，默认6位有效数字
   * @param num
   * @param rank
   */
  signFigures: (num: number, rank = 6): any => {
    if (!num) {
      return (0);
    }
    const sign: number = num / Math.abs(num);
    const numb: number = num * sign;
    let temp: number = rank - 1 - Math.floor(Math.log10(numb));
    let ans: number;
    if (temp > 0) {
      ans = parseFloat(numb.toFixed(temp));
    } else if (temp < 0) {
      temp = Math.pow(10, temp);
      ans = Math.round(numb / temp) * temp;
    } else {
      ans = Math.round(numb);
    }
    return (ans * sign);
  },
  /**
   * 将NodeList转换成Array
   * @param list
   */
  transformList(list: any[]): any {
    let arr: any[] = [];
    for (let i = 0; i < list.length; i++) {
      //@ts-ignore
      arr.push(list[i]);
    }
    return arr;
  },
  /**
   * 查找一个父节点下指定类名的子节点
   * @param parentNode 父节点
   * @param childName 要查找的子节点的类名
   * @return true有该类名的子节点，false没有该类名的子节点
   */
  getParentElement(parentNode: any, childName: string): boolean {
    let _this = (this as any);
    // 如果父节点parentNode含有指定类名childName，这个节点就是目标节点
    if (parentNode && parentNode.classList && parentNode.classList.length > 0 &&
        parentNode.classList.contains(childName)) {
      return true;
    } else {
      // 父节点不含有指定类名childName，递归查找它的子节点。
      let nodes: any = parentNode.childNodes;
      // 将子节点的list转换成标准数组并且过滤掉Text元素
      nodes = _this.transformList(nodes).filter((item: any): any => {
        if (item.nodeType !== 3) {
          return item;
        } else {
        }
      });
      // 如果子节点数组中有值，则递归查找
      if (nodes.length > 0) {
        let isRes: boolean = false;
        for (let i = 0; i < nodes.length; i++) {
          const itemRes: boolean = _this.getParentElement(nodes[i], childName);
          if (itemRes) {
            isRes = true;
            break;
          }
        }
        return isRes;
      } else {
        return false;
      }
    }
  },
  /**
   * 检查当前设备是Android还是ios
   */
  checkAndroidOrIos: (): string => {
    let res: string = '';
    //userAgent 属性是一个只读的字符串，声明了浏览器用于 HTTP 请求的用户代理头的值
    const u: any = navigator.userAgent;
    //Android终端
    const isAndroid: boolean = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
    //iOS终端
    const isiOS: boolean = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isAndroid) {
      //如果为Android
      res = 'android';
    }
    if (isiOS) {
      //如果为iOS
      res = 'ios';
    }
    return res;
  },
};
export default Utils;

<template>
  <div v-if="isShow" class="text-align-center" style="margin-top: 25vh;">
    <div class="image-tblr-center person-avatar-80"
         :style="{'backgroundImage':((logoUrl)?'url(\''+(logoUrl)+'\')':'unset'),
             'filter':((logoUrl)?'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\''+(logoUrl)+'\',sizingMethod=scale)':'unset'),
             '-ms-filter':((logoUrl)?'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\''+(logoUrl)+'\',sizingMethod=scale)':'unset')}"
         style="display: inline-block;"></div>
    <p style="color: #999;">暂无数据！
      <span style="color: #333;" @click="tipHomePage">去逛逛~~~</span>
    </p>
  </div>
</template>

<script lang="ts">
  import {Options, Vue} from "vue-class-component";
  import {Prop} from "vue-property-decorator";
  import Utils from '@/js/utils/utils.ts';
  import StorageUtils from '@/js/utils/storage.ts';

  @Options({})
  export default class NullList extends Vue {
    // 父组件传值接收
    @Prop(Boolean) isShow!: boolean;
    // 子组件的data
    name: string = 'null-list';
    // logoUrl: string = './static/img/logo.jpg';
    logoUrl: any = require('@/assets/img/logo.png').default;

    tipHomePage(): void {
      let curPagePath: any = StorageUtils.getByModule('page.current');
      if (curPagePath != null) {
        if (curPagePath != '/') {
          Utils.back(this, '/', {activePage: 'home'}, {
            reloadAll: true,
            force: true,
          });
        } else {
          window.history.back();
        }
      }
    }
  }
</script>

<style scoped>

</style>

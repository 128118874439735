<template>
  <f7-page-content id="history" tab :tab-active="currentPageName=='history'"
                   style="height:100% !important;"
                   @tab:show="changeCurrentPageName"
                   infinite :infinite-distance="50"
                   :infinite-preloader="preloader"
                   @infinite="getNextHistoryList">
    <null-list :is-show="historyList.length==0"></null-list>
    <history-list v-if="historyList.length>0" title="" :is-media="isAvatar"
                  :list="historyList" :user-info="userInfo"
                  @updateHistoryList="initHistoryList">
    </history-list>
  </f7-page-content>
</template>
<script lang="ts">
  import {Options, Vue} from "vue-class-component";
  import {Prop, Watch} from 'vue-property-decorator';
  import HistoryList from './history-list.vue';
  import NullList from './null-list.vue';
  import Utils from "@/js/utils/utils.ts"
  import Http from "@/js/utils/http.ts";
  import CommonUtils from '@/js/common/common.ts';

  @Options({
    components: {
      // HomeToolbar,
      HistoryList,
      NullList,
    },
  })
  export default class History extends Vue {
    // 父组件传值接收
    @Prop(String) currentPageName!: string;
    isAvatar: boolean = true;
    historyList: any[] = [];
    loading: boolean = true;
    preloader: boolean = true;
    pageSize: number = 10;
    curPage: number = 1;
    totalPage: number = 1;
    totalRow: number = 0;
    userInfo: any = {};

    created(): void {
      CommonUtils.getUserInfo(this, (params: any): void => {
        this.userInfo = params || {};
        this.getHistoryListBy(this.curPage);
      });
      Utils.bindEvent(Utils.kEvents.changeHistoryList, this.initHistoryList);
    }

    @Watch('currentPageName', {immediate: true, deep: true})
    onTabIsShowChanged(val: any, oldVal: any): void {
      if (val == 'history') {
        this.initHistoryList(true);
      }
    }

    changeCurrentPageName(): void {
      this.$emit('changeActivePageName', 'history');
    }

    initHistoryList(params: any): void {
      console.log('----------init history list-------', params)
      if (params) {
        this.getHistoryListBy(1);
      }
    }

    getNextHistoryList(): void {
      if (!this.loading) return;
      this.loading = false;
      setTimeout((): void => {
        if (this.curPage >= this.totalPage) {
          this.preloader = false;
          return;
        }
        let curPageNumber = this.curPage + 1;
        this.getHistoryListBy(curPageNumber);
        this.loading = true;
      }, 1000);
    }

    getHistoryListBy(cur_page: number): void {
      let data: any = {
        page: cur_page,
        size: this.pageSize,
        wid: this.userInfo.id || -1,
      };
      let successList: any = (res: any): void => {
        this.totalPage = res.hasOwnProperty('total') ? res.total : 1;
        this.curPage = res.hasOwnProperty('page') ? res.page : 1;
        this.totalRow = res.hasOwnProperty('rows') ? res.rows : 0;

        let curResData = res.hasOwnProperty('data') ? res.data : [];

        if (this.totalPage <= 1) {
          this.preloader = false;
        }

        if (this.curPage <= 1) {
          this.historyList = curResData;
        } else {
          let afterArr = this.historyList;
          for (let i in curResData) {
            afterArr.push(curResData[i]);
          }
          this.historyList = afterArr;
        }
      };
      let params: any = {
        url: Http.kInterfacePaths.history.list,
        data: data,
        warn_info: '获取历史记录失败！',
        success: successList,
      };
      Http.ajax(this, params);
    }

  }
</script>


  import {Options, Vue} from "vue-class-component";
  import {Prop} from "vue-property-decorator";

  @Options({})
  export default class GoodsList extends Vue {
    @Prop(Array) gifts!: any[];
    @Prop(String) type!: string;// gifts goods
    name: string = "goods-list";

    receiveIconUrl: any = require('@/assets/img/icon_gift_receive.png').default;
    shouqingIconUrl: any = require('@/assets/img/icon_gift_shouqing.png').default;
    qiangguangIconUrl: any = require('@/assets/img/icon_gift_qiangguang.png').default;

    tipClickGoods(item: any): void {
      if (item.hasOwnProperty('receive') && item.receive == true) {
        window.f7.toast.create({
          text: '您已经领取该礼物了哦！',
          closeTimeout: 2000,
          position: "center",
        }).open();
      } else {
        this.$emit('clickGoodsDetail', item);
      }
    }

  }


  import {Options, Vue} from "vue-class-component";
  import {Prop} from "vue-property-decorator";
  import Utils from '@/js/utils/utils.ts';
  import StorageUtils from '@/js/utils/storage.ts';

  @Options({})
  export default class NullList extends Vue {
    // 父组件传值接收
    @Prop(Boolean) isShow!: boolean;
    // 子组件的data
    name: string = 'null-list';
    // logoUrl: string = './static/img/logo.jpg';
    logoUrl: any = require('@/assets/img/logo.png').default;

    tipHomePage(): void {
      let curPagePath: any = StorageUtils.getByModule('page.current');
      if (curPagePath != null) {
        if (curPagePath != '/') {
          Utils.back(this, '/', {activePage: 'home'}, {
            reloadAll: true,
            force: true,
          });
        } else {
          window.history.back();
        }
      }
    }
  }

<template>
  <f7-page class="audio-player-page" :page-content="false"
           :class="{'min-status':isMin}">
    <title-share v-if="!isMin" :noShowShare="false"></title-share>
    <f7-page-content :class="{'min-status':isMin}"
                     style="width:100%;height:100%;">
      <div class="audio-frame" id="audioFrame"
           style="z-index:2;"
           :style="{left: (isMin?'-60px':'50%'),marginLeft:(isMin?'unset':'-28px'),top:(isMin?'0':(audioContainerHeight-105)+'px')}">
        <!--           :style="{left: (isMin?'-60px':'50%'),marginLeft:(isMin?'unset':'-28px'),top:(isMin?'0':(audioContainerHeight-91)+'px')}">-->
        <audio v-if="!isWxSupport.openTag" id="audioTag"
               class="normal-audio-dom"
               :src="audioInfo.hasOwnProperty('audio')&&audioInfo.audio&&audioInfo.audio.length>0?audioInfo.audio[0].url:''"
        ></audio>
        <wx-open-audio
            v-if="isWxSupport.openTag&&isWxSupport.ready"
            id="audioTag"
            :title="audioInfo.title||''"
            :episode="albumInfo.title||''"
            :src="audioInfo.hasOwnProperty('audio')&&audioInfo.audio&&audioInfo.audio.length>0?audioInfo.audio[0].url:''"
            :cover="((albumInfo.hasOwnProperty('cover')&&albumInfo.cover.length>0&&(albumInfo.cover[0].thumb!=null||albumInfo.cover[0].url!=null))?(albumInfo.cover[0].thumb||albumInfo.cover[0].url):logoUrl)"
            @canplay="wxOpenTagAudioCanPlay"
            @play="wxOpenTagAudioPlay"
            @pause="wxOpenTagAudioPause"
            @timeupdate="wxOpenTagAudioTimeUpdate"
        >
          <div v-is="'script'" type="text/wxtag-template">
            <div class="playBackground"></div>
          </div>
          <div v-is="'script'" type="text/wxtag-template" slot="playing">
            <div class="pauseBackground"></div>
          </div>
          <div v-is="'script'" type="text/wxtag-template" slot="style">
            <div v-is="'style'">
              .playBackground {
              width: 56px;
              height: 68px;
              }

              .pauseBackground {
              width: 56px;
              height: 68px;
              }
            </div>
          </div>
        </wx-open-audio>
      </div>
      <f7-block class="audio-play-frame" v-if="!isMin">
        <h3 class="text-center title" style="margin-bottom:4px;height:42px;">
          {{audioInfo.title||'专辑标题'}}
        </h3>
        <p class="text-center description">
          {{audioInfo.hasOwnProperty('create_time')&&audioInfo.create_time?audioInfo.create_time.substring(0,10):''}}
          |
          {{audioInfo.study_times||0}}次收听
        </p>
        <div class="audio-img-frame">
          <img
              :src="((audioInfo.hasOwnProperty('cover')&&audioInfo.cover&&audioInfo.cover.length>0)||(albumInfo.cover&&albumInfo.cover.length>0)?(audioInfo.hasOwnProperty('cover')&&audioInfo.cover&&audioInfo.cover.length>0?audioInfo.cover[0].url:albumInfo.cover[0].url):logoUrl)"
              alt="音频代表图">
        </div>
        <div class="audio-box">
          <div class="a-progress">
            <span class="gobackward15" @click="goBackward15">
              <f7-icon f7="gobackward_15"></f7-icon>
            </span>
            <div class="pgs-total" id="progressFrame">
              <div class="pgs-play" id="progress"
                   :data-content-after="playedTime+'/'+duration"
                   :style="{'width': progressWidth}"></div>
            </div>
            <span class="goforward15" @click="goForward15">
              <f7-icon f7="goforward_15"></f7-icon>
            </span>
          </div>
          <!--<f7-card style="margin:0;">
            <f7-card-content>
              <f7-row no-gap style="height:40px;">
                <f7-col width="20" class="audio-set-button-item">
                  <f7-button color="gray" sheet-open=".timing-sheet">
                    <div>
                      <p>
                        <f7-icon f7="alarm" class="font-size-24"></f7-icon>
                      </p>
                      <p>定时关闭</p>
                    </div>
                  </f7-button>
                </f7-col>
                <f7-col width="20" class="audio-set-button-item">
                  <f7-button color="black" @click="goPreviousAudio">
                    <f7-icon f7="backward_end_fill"
                             class="font-size-22"></f7-icon>
                  </f7-button>
                </f7-col>
                <f7-col width="20" class="audio-set-button-item"
                        style="position: relative;height:40px;cursor: pointer;"
                        @click.stop="playOrPauseController">
                  <f7-button color="black"
                             @click.stop="playOrPauseController">
                    <f7-icon :f7="audioPlaying?'pause_fill':'play_fill'"
                             class="font-size-30"></f7-icon>
                  </f7-button>
                </f7-col>
                <f7-col width="20" class="audio-set-button-item">
                  <f7-button color="black" @click="goNextAudio">
                    <f7-icon f7="forward_end_fill"
                             class="font-size-22"></f7-icon>
                  </f7-button>
                </f7-col>
                <f7-col width="20" class="audio-set-button-item">
                  <f7-button color="gray" @click="toggleAudioMode">
                    <div>
                      <p>
                        &lt;!&ndash;循环播放&ndash;&gt;
                        <f7-icon v-if="mode=='circulation'" f7="repeat"
                                 class="font-size-24"></f7-icon>
                        &lt;!&ndash;单曲循环播放&ndash;&gt;
                        <f7-icon v-if="mode=='single'" f7="repeat_1"
                                 class="font-size-24"></f7-icon>
                        &lt;!&ndash;顺序播放&ndash;&gt;
                        <f7-icon v-if="mode=='order'" f7="menu"
                                 class="font-size-24"></f7-icon>
                      </p>
                      <p>
                        {{mode=='circulation'?'循环播放':(mode=='single'?'单曲循环':'顺序播放')}}</p>
                    </div>
                  </f7-button>
                </f7-col>
                &lt;!&ndash;<f7-col width="20" class="audio-set-button-item">
                  <f7-button color="gray" popup-open="#albumPlayerListPopup">
                    <div>
                      <p>
                        <f7-icon f7="menu" class="font-size-24"></f7-icon>
                      </p>
                      <p>播放列表</p>
                    </div>
                  </f7-button>
                </f7-col>&ndash;&gt;
              </f7-row>
            </f7-card-content>
          </f7-card>-->
          <f7-card style="margin:10px 0px;">
            <div class="menu-div">
              <div class="item-div audio-set-button-item">
                <f7-button color="gray" sheet-open=".timing-sheet">
                  <div>
                    <p>
                      <f7-icon f7="alarm" class="font-size-24"></f7-icon>
                    </p>
                    <p>定时关闭</p>
                  </div>
                </f7-button>
              </div>
              <div class="item-div">
                <f7-button color="black" @click="goPreviousAudio">
                  <f7-icon f7="backward_end_fill"
                           class="font-size-22"></f7-icon>
                </f7-button>
              </div>
              <div class="item-div" @click.stop="playOrPauseController">
                <f7-button color="black"
                           @click.stop="playOrPauseController">
                  <f7-icon :f7="audioPlaying?'pause_fill':'play_fill'"
                           class="font-size-22"></f7-icon>
                </f7-button>
              </div>
              <div class="item-div">
                <f7-button color="black" @click="goNextAudio">
                  <f7-icon f7="forward_end_fill"
                           class="font-size-22"></f7-icon>
                </f7-button>
              </div>
              <div class="item-div audio-set-button-item">
                <f7-button color="gray" @click="toggleAudioMode">
                  <div>
                    <p>
                      <!--循环播放-->
                      <f7-icon v-if="mode=='circulation'" f7="repeat"
                               class="font-size-24"></f7-icon>
                      <!--单曲循环播放-->
                      <f7-icon v-if="mode=='single'" f7="repeat_1"
                               class="font-size-24"></f7-icon>
                      <!--顺序播放-->
                      <f7-icon v-if="mode=='order'" f7="menu"
                               class="font-size-24"></f7-icon>
                    </p>
                    <p>
                      {{mode=='circulation'?'循环播放':(mode=='single'?'单曲循环':'顺序播放')}}</p>
                  </div>
                </f7-button>
              </div>
            </div>
          </f7-card>
        </div>
        <f7-list media-list style="margin:26px 0;">
          <f7-list-item @click="tipAlbumPage">
            <template #media>
              <div class="album-image-65 image-tblr-center" :style="{'backgroundImage':((albumInfo.hasOwnProperty('cover')&&albumInfo.cover.length>0&&(albumInfo.cover[0].thumb!=null||albumInfo.cover[0].url!=null))?'url(\''+(albumInfo.cover[0].thumb||albumInfo.cover[0].url)+'\')':'unset'),
      'filter':((albumInfo.hasOwnProperty('cover')&&albumInfo.cover.length>0&&(albumInfo.cover[0].thumb!=null||albumInfo.cover[0].url!=null))?'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\''+(albumInfo.cover[0].thumb||albumInfo.cover[0].url)+'\',sizingMethod=scale)':'unset'),
      '-ms-filter':((albumInfo.hasOwnProperty('cover')&&albumInfo.cover.length>0&&(albumInfo.cover[0].thumb!=null||albumInfo.cover[0].url!=null))?'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\''+(albumInfo.cover[0].thumb||albumInfo.cover[0].url)+'\',sizingMethod=scale)':'unset')}"
              >
              </div>
            </template>
            <template #title>
              {{albumInfo.title}}
            </template>
            <template #text style="margin-top:8px;">
              <div style="margin-top:8px;" class="div-line1">
                <!--<span v-if="albumInfo.hasOwnProperty('episodes_number')">
                  共{{albumInfo.episodes_number||0}}集
                </span>-->
                {{albumInfo.subtitle}}
              </div>
            </template>
          </f7-list-item>
        </f7-list>
      </f7-block>
      <f7-sheet class="timing-sheet" :backdrop="true" id="audio_player_times"
                :opened="timingSheetOpened"
                @sheet:closed="timingSheetOpened = false">
        <f7-toolbar>
          <f7-button color="white"
                     class="timing-cancel-btn text-center"
                     sheet-close>取消
          </f7-button>
        </f7-toolbar>
        <!-- Scrollable sheet content -->
        <f7-page-content>
          <ul class="timing-list">
            <li class="timing-item"
                v-for="el in timingList" :key="el.type"
                :class="{'active': (el.type==timingType)}"
                @click="changeTimingType(el.type)">
              <f7-icon f7="checkmark_alt"></f7-icon>
              {{el.title}}
            </li>
          </ul>
        </f7-page-content>
      </f7-sheet>
      <f7-popup :tablet-fullscreen="true" :backdrop="true"
                :close-by-backdrop-click="true"
                id="albumPlayerListPopup"
                :opened="playListStatus"
                @popup:opened="onPlayListPopupOpened"
                @popup:closed="onPlayListPopupClosed"
                style="height:80vh;top:20vh;z-index:10000000;"
      >
        <audio-play-list
            :user-info="userInfo"
            :is-try="isTry"
            :mode="mode"
            :playAudio="playAudio"
            :updateList="updateList"
            :audio-progress="progressWidth"
            :refresh-page-list="needRefreshToPage"
            @changeModeStatus="onChangeModeStatus"
            @changePlayedAudio="onChangePlayedAudio"
            @changeAlbumAudios="onChangeAlbumList"
        ></audio-play-list>
      </f7-popup>
      <div class="audio-play-min-frame" v-if="isMin"
           :style="{'left':(isOpacity0?'150%':'50%')}"
           :class="{'has-toolbar':(isToolbarShow)}">
        <div class="player-min-frame" @click="changePlayMinStatus">
          <f7-gauge
              class="player-min-gauge"
              type="circle"
              :value="gaugeValue"
              :size="60"
              border-color="#ff9500"
              :border-width="2"
          ></f7-gauge>
          <div class="player-min-img-box" :class="{'playing':audioPlaying}">
            <img
                :src="((audioInfo.hasOwnProperty('cover')&&audioInfo.cover&&audioInfo.cover.length>0)||(albumInfo.cover&&albumInfo.cover.length>0)?(audioInfo.hasOwnProperty('cover')&&audioInfo.cover&&audioInfo.cover.length>0?audioInfo.cover[0].url:albumInfo.cover[0].url):logoUrl)"
                alt="音频代表图">
          </div>
        </div>
      </div>
    </f7-page-content>
  </f7-page>
  <!--<f7-page :page-content="false" class="audio-player-page"
           :class="{'min-status':isMin}">
    <title-share v-if="!isMin"></title-share>
    <f7-page-content :class="{'min-status':isMin}">
      <div class="audio-frame" id="audioFrame"
           style="z-index:2;width: 56px;min-height: 68px;"
           :style="{left: (isMin?'-60px':'50%'),marginLeft:(isMin?'unset':'-28px'),top:(isMin?'0':(audioContainerHeight-133)+'px')}">
        <audio v-if="!isWxSupport.openTag" id="audioTag"
               class="normal-audio-dom"
               :src="audioInfo.hasOwnProperty('audio')&&audioInfo.audio&&audioInfo.audio.length>0?audioInfo.audio[0].url:''"
        ></audio>
        <wx-open-audio
            v-if="isWxSupport.openTag&&isWxSupport.ready"
            id="audioTag"
            :title="audioInfo.title||''"
            :episode="albumInfo.title||''"
            :src="audioInfo.hasOwnProperty('audio')&&audioInfo.audio&&audioInfo.audio.length>0?audioInfo.audio[0].url:''"
            :cover="((albumInfo.hasOwnProperty('cover')&&albumInfo.cover.length>0&&(albumInfo.cover[0].thumb!=null||albumInfo.cover[0].url!=null))?(albumInfo.cover[0].thumb||albumInfo.cover[0].url):logoUrl)"
            @canplay="wxOpenTagAudioCanPlay"
            @play="wxOpenTagAudioPlay"
            @pause="wxOpenTagAudioPause"
            @timeupdate="wxOpenTagAudioTimeUpdate"
            style="width:56px;height:68px;"
        >
          <div v-is="'script'" type="text/wxtag-template">
            <div class="playBackground"></div>
          </div>
          <div v-is="'script'" type="text/wxtag-template" slot="playing">
            <div class="pauseBackground"></div>
          </div>
          <div v-is="'script'" type="text/wxtag-template" slot="style">
            <div v-is="'style'">
              .playBackground {
              width: 56px;
              height: 68px;
              /*background-color: rgba(0, 255, 255, 0.05);*/
              /*opacity: 0;*/
              }

              .pauseBackground {
              width: 56px;
              height: 68px;
              /*background-color: rgba(0, 0, 0, 0.5);*/
              /*opacity: 0;*/
              }
            </div>
          </div>
        </wx-open-audio>
      </div>
      <f7-block class="audio-play-frame" v-if="!isMin">
        <h3 class="text-center title" style="margin-bottom:4px;">
          {{audioInfo.title||'专辑标题'}}
        </h3>
        <p class="text-center description">
          {{audioInfo.hasOwnProperty('create_time')&&audioInfo.create_time?audioInfo.create_time.substring(0,10):''}}
          |
          {{audioInfo.study_times||0}}次收听
        </p>
        <div class="audio-img-frame">
          <img
              :src="((audioInfo.hasOwnProperty('cover')&&audioInfo.cover&&audioInfo.cover.length>0)||(albumInfo.cover&&albumInfo.cover.length>0)?(audioInfo.hasOwnProperty('cover')&&audioInfo.cover&&audioInfo.cover.length>0?audioInfo.cover[0].url:albumInfo.cover[0].url):logoUrl)"
              alt="音频代表图">
        </div>
        <div class="audio-box">
          &lt;!&ndash;<audio id="audioTag" :src="testAudioUrl"></audio>&ndash;&gt;
          <div class="a-progress">
            <span class="gobackward15" @click="goBackward15">
              <f7-icon f7="gobackward_15"></f7-icon>
            </span>
            <div class="pgs-total" id="progressFrame">
              <div class="pgs-play" id="progress"
                   :data-content-after="playedTime+'/'+duration"
                   :style="{'width': progressWidth}"></div>
            </div>
            <span class="goforward15" @click="goForward15">
              <f7-icon f7="goforward_15"></f7-icon>
            </span>
          </div>
          <f7-card style="margin:0;">
            <f7-card-content>
              <f7-row no-gap>
                <f7-col width="20" class="audio-set-button-item">
                  <f7-button color="gray" sheet-open=".timing-sheet">
                    <div>
                      <p>
                        <f7-icon f7="alarm" class="font-size-24"></f7-icon>
                      </p>
                      <p>定时关闭</p>
                    </div>
                  </f7-button>
                </f7-col>
                <f7-col width="20" class="audio-set-button-item">
                  <f7-button color="black" @click="goPreviousAudio">
                    <f7-icon f7="backward_end_fill"
                             class="font-size-22"></f7-icon>
                  </f7-button>
                </f7-col>
                <f7-col width="20" class="audio-set-button-item">
                  <f7-button color="black" @click="playOrPauseController">
                    <f7-icon :f7="audioPlaying?'pause_fill':'play_fill'"
                             class="font-size-30"></f7-icon>
                  </f7-button>
                </f7-col>
                <f7-col width="20" class="audio-set-button-item">
                  <f7-button color="black" @click="goNextAudio">
                    <f7-icon f7="forward_end_fill"
                             class="font-size-22"></f7-icon>
                  </f7-button>
                </f7-col>
                <f7-col width="20" class="audio-set-button-item">
                  <f7-button color="gray" @click="toggleAudioMode">
                    <div>
                      <p>
                        &lt;!&ndash;循环播放&ndash;&gt;
                        <f7-icon v-if="mode=='circulation'" f7="repeat"
                                 class="font-size-24"></f7-icon>
                        &lt;!&ndash;单曲循环播放&ndash;&gt;
                        <f7-icon v-if="mode=='single'" f7="repeat_1"
                                 class="font-size-24"></f7-icon>
                        &lt;!&ndash;顺序播放&ndash;&gt;
                        <f7-icon v-if="mode=='order'" f7="menu"
                                 class="font-size-24"></f7-icon>
                      </p>
                      <p>
                        {{mode=='circulation'?'循环播放':(mode=='single'?'单曲循环':'顺序播放')}}</p>
                    </div>
                  </f7-button>
                </f7-col>
                &lt;!&ndash;<f7-col width="20" class="audio-set-button-item">
                  <f7-button color="gray" popup-open="#albumPlayerListPopup">
                    <div>
                      <p>
                        <f7-icon f7="menu" class="font-size-24"></f7-icon>
                      </p>
                      <p>播放列表</p>
                    </div>
                  </f7-button>
                </f7-col>&ndash;&gt;
              </f7-row>
            </f7-card-content>
          </f7-card>
        </div>
        <f7-list media-list style="margin:16px 0;">
          <f7-list-item @click="tipAlbumPage">
            <template #media>
              <div class="album-image-65 image-tblr-center" :style="{'backgroundImage':((albumInfo.hasOwnProperty('cover')&&albumInfo.cover.length>0&&(albumInfo.cover[0].thumb!=null||albumInfo.cover[0].url!=null))?'url(\''+(albumInfo.cover[0].thumb||albumInfo.cover[0].url)+'\')':'unset'),
      'filter':((albumInfo.hasOwnProperty('cover')&&albumInfo.cover.length>0&&(albumInfo.cover[0].thumb!=null||albumInfo.cover[0].url!=null))?'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\''+(albumInfo.cover[0].thumb||albumInfo.cover[0].url)+'\',sizingMethod=scale)':'unset'),
      '-ms-filter':((albumInfo.hasOwnProperty('cover')&&albumInfo.cover.length>0&&(albumInfo.cover[0].thumb!=null||albumInfo.cover[0].url!=null))?'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\''+(albumInfo.cover[0].thumb||albumInfo.cover[0].url)+'\',sizingMethod=scale)':'unset')}"
              >
              </div>
            </template>
            <template #title>
              专辑：{{albumInfo.title}}
            </template>
            <template #text style="margin-top:8px;">
              <p style="margin-top:8px;">
             <span v-if="albumInfo.hasOwnProperty('episodes_number')">
               共{{albumInfo.episodes_number||0}}集
             </span>
              </p>
            </template>
          </f7-list-item>
        </f7-list>
      </f7-block>
      <f7-sheet class="timing-sheet" :backdrop="false"
                :opened="timingSheetOpened"
                @sheet:closed="timingSheetOpened = false">
        <f7-toolbar>
          <f7-button color="white"
                     class="timing-cancel-btn text-center"
                     sheet-close>取消
          </f7-button>
        </f7-toolbar>
        &lt;!&ndash; Scrollable sheet content &ndash;&gt;
        <f7-page-content>
          <ul class="timing-list">
            <li class="timing-item"
                v-for="el in timingList" :key="el.type"
                :class="{'active': (el.type==timingType)}"
                @click="changeTimingType(el.type)">
              <f7-icon f7="checkmark_alt"></f7-icon>
              {{el.title}}
            </li>
          </ul>
        </f7-page-content>
      </f7-sheet>
      <f7-popup :tablet-fullscreen="true" :backdrop="true"
                :close-by-backdrop-click="true"
                id="albumPlayerListPopup"
                :opened="playListStatus"
                @popup:opened="onPlayListPopupOpened"
                @popup:closed="onPlayListPopupClosed"
                style="height:80vh;top:20vh;z-index:10000000;"
      >
        <audio-play-list
            :user-info="userInfo"
            :is-try="isTry"
            :mode="mode"
            :playAudio="playAudio"
            :audio-progress="progressWidth"
            :refresh-page-list="needRefreshToPage"
            @changeModeStatus="onChangeModeStatus"
            @changePlayedAudio="onChangePlayedAudio"
            @changeAlbumAudios="onChangeAlbumList"
        ></audio-play-list>
        &lt;!&ndash;:album-info="albumInfo"
        :audio-info="audioInfo"&ndash;&gt;
      </f7-popup>
      <div class="audio-play-min-frame" v-if="isMin"
           :style="{'left':(isOpacity0?'150%':'50%')}"
           :class="{'has-toolbar':(isToolbarShow)}">
        <div class="player-min-frame" @click="changePlayMinStatus">
          <f7-gauge
              class="player-min-gauge"
              type="circle"
              :value="gaugeValue"
              :size="60"
              border-color="#ff9500"
              :border-width="2"
          ></f7-gauge>
          <div class="player-min-img-box" :class="{'playing':audioPlaying}">
            <img
                :src="((audioInfo.hasOwnProperty('cover')&&audioInfo.cover&&audioInfo.cover.length>0)||(albumInfo.cover&&albumInfo.cover.length>0)?(audioInfo.hasOwnProperty('cover')&&audioInfo.cover&&audioInfo.cover.length>0?audioInfo.cover[0].url:albumInfo.cover[0].url):logoUrl)"
                alt="音频代表图">
          </div>
        </div>
      </div>
    </f7-page-content>
  </f7-page>-->
</template>

<script lang="ts">
  import {Options, Vue} from 'vue-class-component';
  import {Prop, Watch} from "vue-property-decorator";
  import TitleShare from '@/components/title-share.vue';
  import AudioPlayList from "@/components/audio-play-list.vue";
  import CommonUtils from "@/js/common/common";
  import Utils from "@/js/utils/utils"
  import StorageUtils from "@/js/utils/storage";
  import Http from "@/js/utils/http";
  import WechatUtils from "@/js/utils/wechat";

  @Options({
    components: {
      TitleShare,
      AudioPlayList,
    }
  })
  export default class AudioPlayer extends Vue {
    @Prop(Boolean) isOpacity0!: boolean; // 播放器是否在当前页面显示,true隐藏，false显示
    @Prop(Boolean) isToolbarShow!: boolean; // 专辑播放页是否被打开
    @Prop(Boolean) isAudioPopupOpened!: boolean; // 专辑播放页是否被打开
    @Prop(Boolean) isMin!: boolean; // 是否最小化展示
    @Prop(Boolean) isTry!: boolean; // 是否只获取试听的内容
    @Prop(Boolean) playListStatus!: boolean; // 专辑所有的音频列表所在的Popup是否被打开
    @Prop({default: {}}) playAudio!: any; // 要播放音频的所有信息，包含album/audio/playData

    // 音频所在的专辑信息
    albumInfo: any = {
      is_cycle: false,
      create_time: "",
      pid: 0,
      title: "专辑标题",
      type: "",
      cycle: [],
      content: "",
      cover: [],
      money: 0,
      subtitle: "",
      is_free: false,
      id: -1,
      audio: [],
      is_buy: 0,
      org_money: 0, // 原价
      follow_number: 0, // 订阅人数
      new_number: 0, // 更新集数
      episodes_number: 0, // 共多少集
    };
    // 当前播放音频的信息
    audioInfo: any = {
      is_cycle: false,
      create_time: "",
      last_modify_time: "",
      pid: 0,
      title: "",
      type: "audio",
      cycle: [],
      content: '',
      cover: [],
      money: null,
      subtitle: null,
      is_free: true,
      id: -1,
      audio: [],
      study_times: 0,
    };
    playData: any = {}; // 当前播放音频的附带信息
    source: string = 'home';
    name: string = 'audio-player';
    // logoUrl: string = './static/img/logo.jpg'; // logo url
    logoUrl: any = require('@/assets/img/logo.png').default;
    userInfo: any = {};
    mode: string = 'circulation'; // 播放模式：循环播放circulation、顺序播放order、单曲播放single
    // is_try: boolean = false; // 是否只获取试听的内容
    albumList: any[] = []; // 当前专辑列表
    albumTotalRow: number = 0; // 当前专辑共多少条记录
    albumCurPage: number = 1; // 当前专辑列表获取的第几页信息
    // albumPageSize:number=10; // 当前专辑列表每次获取多少条信息
    // audioId: number = -1; // 当前播放音频的id
    ind: number = -1; // 当前播放音频属于列表的第几项
    indexAtAlbum: number = -1; // 当前音频属于专辑的排序号sort值
    historyAudioTime: number = 0; // 获取到的历史记录播放时间
    preStartPlayTime: number = 0; // 获取到的历史记录播放时间
    audioDom: any = null; // 当前播放audio组件的DOM对象
    progressDom: any = null; // 进度条的DOM对象
    audioPlaying: boolean = false; // 是否正在播放，默认不播放
    progressWidth: string = '0%'; // 进度百分比
    playedTime: string = '00:00'; // 当前播放时间
    duration: string = '00:00'; // 当前音频的总时间
    gaugeValue: number = 0; // 播放进度条进度
    timingSheetOpened: boolean = false; // 定时选择框是否开启
    // 定时选择的类型列表
    timingList: any = {
      'none': {
        id: 0,
        type: 'none',
        title: '不开启',
        number: 0,
      },
      'one': {
        id: 1,
        type: 'one',
        title: '播放1集声音',
        number: 1,
      },
      'two': {
        id: 2,
        type: 'two',
        title: '播放2集声音',
        number: 2,
      },
      'three': {
        id: 3,
        type: 'three',
        title: '播放3集声音',
        number: 3,
      },
      'time10': {
        id: 4,
        type: 'time10',
        title: '10分钟后',
        number: 10,
      },
      'time20': {
        id: 5,
        type: 'time20',
        title: '20分钟后',
        number: 20,
      },
      'time30': {
        id: 6,
        type: 'time30',
        title: '30分钟后',
        number: 30,
      },
      'time60': {
        id: 7,
        type: 'time60',
        title: '60分钟后',
        number: 60,
      },
      'time90': {
        id: 8,
        type: 'time90',
        title: '90分钟后',
        number: 90,
      },
    };
    timingType: string = 'none'; // 定时选择后的类型，默认不开启
    timingStart: number = 0; // 定时类型计算音频数量或音频已播放时间
    timingInterval: any = null; // 定时多少分钟时的计时对象
    needRefreshToPage: number = -1; // 需要重新获取第几页的音频列表信息
    preloadedAudioList: any[] = []; // 需要预加载的列表
    preloadedAudioNumber: number = 0; // 预加载个数
    preloadBatchNumber: number = 0; // 预加载第几批次的5个音频
    preloadedAudioIds: number[] = []; // 所有已经预加载完成的音频id
    preloadAudioObjList: { [key: string]: any } = {};
    needPreloadAudioLength: number = 0; // 当前需要加载的列表的长度
    audioFrameDom: any = null; // audio所在的父级标签DOM对象
    isWxSupport: any = {
      can: false,// 是否微信环境
      version: false,// 微信版本是否支持
      openTag: false,// 是否支持微信wx-open-audio标签
      ready: false,// 微信是否ready成功
      error: false,// config是否出现error错误
    };
    audioContainerHeight: number = 300; // 播放器大屏时内容区域的高度
    isProgressDragging: boolean = false;
    historyAudioTimeLoc: number = 0; // 用户设置历史播放时间点
    curPlayingAudioId: number = -1; // 当前播放音频 id
    // playData: any = {}; // 播放信息
    shebei: string = ''; // 当前设备为ios/android/其他[鸿蒙]
    wxConfigErrorNumber: number = 0;//微信config失败次数
    isSwitchNewAlbum: boolean = false;// 是否切换新专辑
    previousPlayAudio: any = {};// 上一个播放音频所有信息
    isFirstPlayAudio: boolean = true; // 是否是第一次进入播放器
    updateList: number = 0;// 去更新列表

    created(): void {
      this.shebei = Utils.checkAndroidOrIos();
      // console.log(this.shebei)
      CommonUtils.getUserInfo(this, (params: any): void => {
        this.userInfo = params;
      });
      this.$nextTick((): void => {
        this.audioFrameDom = document.getElementById('audioFrame');
        this.progressDom = document.getElementById('progress');
      });
      this.isWxSupport.can = WechatUtils.isWeiXin();
      this.isWxSupport.version = WechatUtils.isVersionToOpenTag();
      // console.log('is wx=' + this.isWxSupport.can + '; is version=' + this.isWxSupport.version);
      // 判断微信版本是否支持及微信ready是否成功
      WechatUtils.ready((): void => {
        this.isWxSupport.ready = true;
        // console.log('is wx ready=' + this.isWxSupport.ready);
      });
      WechatUtils.error((error: any): void => {
        // console.log('is wx error=', error);
        this.wxConfigErrorNumber += 1;
        if (this.wxConfigErrorNumber <= 3) {
          CommonUtils.getWechatSign(this);
        } else if (this.wxConfigErrorNumber == 4) {
          // 请求接口，告诉后台前台已经微信config3次失败了
          const data: any = {
            wid: this.userInfo.id || -1,
            platform: this.shebei != '' ? this.shebei : 'harmony',
            error: {
              msg: '3次微信config均失败了',
              data: error,
            },
          };
          const errorCallback: any = (res: any): void => {
          };
          const successCallback: any = (res: any): void => {
          };
          const errParams: any = {
            url: Http.kInterfacePaths.log.error,
            data: data,
            error: errorCallback,
            success: successCallback,
          };
          Http.ajax(this, errParams);
        }
      });
      // 微信版本不支持微信开放标签时的错误信息监听
      document.addEventListener('WeixinOpenTagsError', (e: any): void => {
        // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开发标签，JS-SDK其他功能不受影响
        // console.error('open tags error====', e.detail.errMsg);
        this.isWxSupport.openTag = false;
      });
    }

    mounted(): void {
      let _beforeUnload_time: number = 0, _gap_time: number = 0;
      window.addEventListener('beforeunload', e => {
        if (this.isAudioPopupOpened) this.updateHistoryAudioPlay(this.audioInfo);
      });
      window.addEventListener('unload', e => {
        if (this.isAudioPopupOpened) this.updateHistoryAudioPlay(this.audioInfo);
      });
      window.onbeforeunload = (e: any): void => {
        _beforeUnload_time = new Date().getTime();
        if (this.isAudioPopupOpened) this.updateHistoryAudioPlay(this.audioInfo);
      };
      window.onunload = (e: any): void => {
        _gap_time = new Date().getTime() - _beforeUnload_time;
        if (_gap_time <= 5) {
          //浏览器关闭
          if (this.isAudioPopupOpened) this.updateHistoryAudioPlay(this.audioInfo);
        }
      };
    }

    beforeDestroy(): void {
      if (this.isAudioPopupOpened) this.updateHistoryAudioPlay(this.audioInfo);
    }

    @Watch('isMin', {immediate: true, deep: true})
    onUpdateAudioIsMinStatus(val: any, oldVal: any): void {
      if (typeof val == "boolean" && !val) {
        this.$nextTick((): void => {
          const audioPlayFrameDom: any = document.querySelector('.audio-play-frame');
          this.audioContainerHeight = audioPlayFrameDom != null ? audioPlayFrameDom.clientHeight : 300;
          // 拖动进度条播放
          let progressFrameDom: any = document.getElementById('progressFrame');
          if (progressFrameDom == null) return;
          progressFrameDom.addEventListener('touchstart', (e: any): void => {
            this.isProgressDragging = true;
          });
          progressFrameDom.addEventListener('touchmove', (e: any): void => {
            let x = e.changedTouches[0].clientX - progressFrameDom.offsetLeft;
            let X = x < 0 ? 0 : x;
            let W = progressFrameDom.offsetWidth;
            let place = X > W ? W : X;
            this.progressWidth = (place / W) * 100 + "%";
            this.gaugeValue = (place / W);
            const styleSheet = document.createElement('style');
            document.head.appendChild(styleSheet);
            styleSheet.textContent = "#progress::after {right:-" + (80 - 80 * this.gaugeValue) + "px;}";
            this.playedTime = this.transTime((place / W) * this.audioDom.duration);
          });
          progressFrameDom.addEventListener('touchend', (e: any): void => {
            this.isProgressDragging = false;
            let x = e.changedTouches[0].clientX - progressFrameDom.offsetLeft;
            let X = x < 0 ? 0 : x;
            let W = progressFrameDom.offsetWidth;
            let place = X > W ? W : X;
            this.progressWidth = (place / W) * 100 + "%";
            this.gaugeValue = (place / W);
            this.audioDom.currentTime = (place / W) * this.audioDom.duration;
            this.updateProgress();
          });
        });
      }
    }

    // @Watch('isOpacity0', {immediate: true, deep: true})
    // onUpdateAudioPlayerIsShow(val: any, oldVal: any): void {
    //   // TODO.start 隐藏又显示后保证进度条正常的音频播放更新
    //   // console.log(val, oldVal);
    //   if (typeof oldVal == "boolean") {
    //     if (oldVal && !val) {
    //       // 由隐藏转为显示
    //       // console.log('由隐藏转为显示')
    //       // if (this.audioDom) {
    //       //   this.audioDom.load();
    //       //   this.audioDom.play();
    //       //   this.audioDom.addEventListener('timeupdate', this.updateProgress, false);
    //       //   this.audioDom.addEventListener('ended', this.audioEnded, false);
    //       // }
    //     } else if (!oldVal && val) {
    //       // 由显示转为隐藏
    //       // console.log('由显示转为隐藏')
    //     }
    //   }
    //   //   if (this.audioDom) {
    //   //     console.log(this.audioDom.paused)
    //   //     console.log(this.audioDom)
    //   //     console.log(this.audioDom.currentTime)
    //   //
    //   //     // this.audioDom.addEventListener('timeupdate', this.updateProgress, false);
    //   //     // // 监听播放完成事件
    //   //     // // this.audioDom.addEventListener('ended', this.audioEnded, false);
    //   //     // this.audioDom.addEventListener('ended', this.audioEnded, false);
    //   //   }
    // }

    @Watch('playAudio', {immediate: true, deep: true})
    onChangePlayingAudio(val: any, oldVal: any): void {
      this.isWxSupport.openTag = this.isWxSupport.can ? this.isWxSupport.version : false;
      console.log(this.isWxSupport.openTag, this.shebei)
      switch (this.shebei) {
        case 'android':
          this.isWxSupport.openTag = false;
          break;
        case 'ios':
          break;
        default:
          this.isWxSupport.openTag = false;
          break;
      }
      if (this.isAudioPopupOpened) {
        if (val && !oldVal) {
          this.isFirstPlayAudio = true;
          this.previousPlayAudio = {};
          // 初次进入
          StorageUtils.updateByModule('audioDom.audioPlaying', false);
          this.albumInfo = val.album || {};
          this.audioInfo = val.audio || {};
          this.playData = val.playData || {};
          this.isSwitchNewAlbum = true;
        } else if (val && oldVal) {
          this.isFirstPlayAudio = false;
          this.previousPlayAudio = oldVal;
          if (val.album.id != oldVal.album.id) {
            // 更换专辑
            this.preloadBatchNumber = 0;
            this.preloadedAudioIds = [];
            this.isSwitchNewAlbum = true;
            this.$emit('changePreviousAlbumId', this.albumInfo.hasOwnProperty('id') ? this.albumInfo.id : -1);
          } else {
            // 同一专辑，不同audio
            this.isSwitchNewAlbum = false;
          }
          this.albumInfo = val.album || {};
          this.audioInfo = val.audio || {};
          this.playData = val.playData || {};
        } else {
          // 没有任何值，不需要任何操作
        }
      }
    }

    @Watch('audioInfo', {immediate: true, deep: true})
    onUpdateAudio(val: any, oldVal: any): void {
      if (val && val.hasOwnProperty('id') && val.id != -1) {
        if (oldVal && oldVal.hasOwnProperty('id') && oldVal.id != -1) {
          // 更新原来视频播放的历史记录
          this.updateHistoryAudioPlay(oldVal, 'previous');
        }

        // 音频更换后
        if (val.hasOwnProperty('audio') && val.audio) {
          this.$nextTick((): void => {
            if (this.isWxSupport.openTag) {
              this.curPlayingAudioId = this.audioInfo.id || -1;
              // console.log('------------', this.audioInfo.audio[0].url)
            } else {
              let audioByIdDom: any = document.getElementById('audio' + val.id);
              if (audioByIdDom != null) {
                this.audioDom = audioByIdDom;
              } else {
                this.audioDom = document.getElementById('audioTag');
              }
            }
          });

          // 更新当前音频的学习次数
          const data: any = {
            id: this.audioInfo && this.audioInfo.hasOwnProperty('id') && this.audioInfo.id ? this.audioInfo.id : -1,
            study_times: this.audioInfo && this.audioInfo.hasOwnProperty('study_times') && this.audioInfo.study_times ? this.audioInfo.study_times : 0,
          };
          if (data.id != -1) {
            const errorStudyTimes: any = (res: any): void => {
            };
            const successStudyTimes: any = (res: any): void => {
            };
            const studyParams: any = {
              url: Http.kInterfacePaths.album.studyTimes,
              data: data,
              warn_info: '更新学习次数失败！',
              success: successStudyTimes,
              error: errorStudyTimes,
            };
            Http.ajax(this, studyParams);
          }
        } else if (val.hasOwnProperty('id') && val.id && val.id != -1) {
          // 从历史记录页进入这里的
          const data: any = {
            id: val.id || -1,
            uid: this.userInfo.id || -1,
          };
          const successQuery: any = (res: any): void => {
            const resData: any = res.hasOwnProperty('data') && res.data ? res.data : {
              is_cycle: false,
              create_time: "",
              last_modify_time: "",
              pid: 0,
              title: "",
              type: "audio",
              cycle: [],
              content: '',
              cover: [],
              money: null,
              subtitle: null,
              is_free: true,
              id: -1,
              audio: [],
              study_times: 0,
            };
            this.$emit('refreshAudioInfo', resData);
          };
          const params: any = {
            url: Http.kInterfacePaths.album.query,
            data: data,
            warn_info: '获取专辑详情失败！',
            success: successQuery,
          };
          Http.ajax(this, params);
        }
      }
    }

    get audioDomAndWxData(): any {
      return {
        cur_play_audio_id: this.curPlayingAudioId,
        is_wx_ready: this.isWxSupport.ready,
        wx_open_tag: this.isWxSupport.openTag,
      };
    }

    @Watch('audioDomAndWxData', {immediate: true, deep: true})
    onUpdateAudioAndWxInfo(val: any, oldVal: any): void {
      if (val.cur_play_audio_id != -1 && val.is_wx_ready && val.wx_open_tag) {
        // this.$nextTick((): void => {
        this.audioDom = document.getElementById('audioTag');
        // console.log('++++++++++++++++++', this.audioDom)
        // });
      }
    }

    @Watch('playData', {immediate: true, deep: true})
    onChangeAudioPlayData(val: any, oldVal: any): void {
      if (val && val.hasOwnProperty('id') && val.id == this.audioInfo.id) {
        this.source = val.hasOwnProperty('from') && val.from ? val.from : 'home';
        let historyPreTime: number = val.hasOwnProperty('startPlayTime') && val.startPlayTime ? val.startPlayTime : 0;
        this.preStartPlayTime = val.hasOwnProperty('preStartPlayTime') && val.preStartPlayTime ? val.preStartPlayTime : 0;
        this.ind = (this.isSwitchNewAlbum ? (val.hasOwnProperty('ind') && typeof val.ind == "number" ? val.ind : -1) : this.ind);
        this.indexAtAlbum = val.hasOwnProperty('ind') && typeof val.ind == "number" ? val.ind : -1;
        this.historyAudioTimeLoc = historyPreTime;
        this.historyAudioTime = historyPreTime;
        this.ind = this.indexAtAlbum;
        // console.log('onChangeAudioPlayData===========', this.ind, this.indexAtAlbum, val, this.isSwitchNewAlbum)
      } else {
        this.source = 'home';
      }
    }

    @Watch('audioDom', {immediate: true, deep: true})
    onUpdateAudioDom(val: any, oldVal: any): void {
      if (oldVal) {
        if (typeof oldVal.paused == "boolean" && !oldVal.paused) {
          oldVal.pause();
        }
      }
      // console.log('audio dom change watch===', oldVal, val)
      if (val) {
        if (this.isWxSupport.openTag) {
          this.audioDom.title = this.audioInfo.title || '';
          this.audioDom.src = this.audioInfo.hasOwnProperty('audio') && this.audioInfo.audio && this.audioInfo.audio.length > 0 ? this.audioInfo.audio[0].url : '';
          this.audioDom.cover = ((this.albumInfo.hasOwnProperty('cover') && this.albumInfo.cover.length > 0 && (this.albumInfo.cover[0].thumb != null || this.albumInfo.cover[0].url != null)) ? (this.albumInfo.cover[0].thumb || this.albumInfo.cover[0].url) : this.logoUrl);
          this.audioDom.episode = this.albumInfo.title || '';
        } else {
          this.audioDom.currentTime = this.historyAudioTime;
        }
        if (this.audioDom.duration) this.duration = this.transTime(this.audioDom.duration);
        // 监听音频播放时间并更新进度条
        // this.audioDom.addEventListener('timeupdate', this.updateProgress, false);
        val.addEventListener('timeupdate', this.updateProgress, false);
        // 监听播放完成事件
        // this.audioDom.addEventListener('ended', this.audioEnded, false);
        val.addEventListener('ended', this.audioEnded, false);
        // 读取视频长度,设置页面时长显示-loadedmetadata:指定视频/音频（audio/video）的元数据加载后触发
        // audio.duration 获取音频的时长，单位为秒
        // console.log('============loadedmetadata================', this.audioDom.duration);
        // this.audioDom.addEventListener('loadedmetadata', (event: any): void => {
        this.audioDom.addEventListener('loadedmetadata', (event: any): void => {
          // console.log('audio dom=', this.audioDom);
          // console.log('audio duration=', this.audioDom.duration);
          // 更换视频后，将进度、进度条及播放状态初始化
          this.progressWidth = '0%';
          this.gaugeValue = 0;
          this.updateProgress();
          this.audioPlaying = false;
          this.duration = this.transTime(this.audioDom.duration);
          // android端自动播放控制操作
          if (!this.isWxSupport.openTag) {
            if (typeof this.audioDom.paused == "boolean") {
              let audioDom_audioPlaying: any = StorageUtils.getByModule('audioDom.audioPlaying');
              if (audioDom_audioPlaying) {// 正在播放
                console.log('原先的时候正在播放===', this.audioPlaying, this.isFirstPlayAudio);
                if (!this.isFirstPlayAudio) {
                  this.audioPlaying = true;
                  this.playOrPauseControllerPlay();
                }
              } else {
                this.audioDom.pause();
                this.audioPlaying = false;
                StorageUtils.updateByModule('audioDom.audioPlaying', false)
                // this.playOrPauseController();
                console.log('原先的时候暂停===', this.audioPlaying);
              }
            } else {
              this.playOrPauseController();
            }
          }
        });
      }
    }

    // 微信开放标签是否能播放
    wxOpenTagAudioCanPlay(event: any): void {
      // 可随时播放
      // const state = event.detail;
      // console.log(state.duration);
      if (this.audioDom) {
        this.audioDom.play();
        StorageUtils.updateByModule('audioDom.audioPlaying', true)
      } else {
        this.audioDom = document.getElementById('audioTag');
      }
    }

    // 微信开放标签播放
    wxOpenTagAudioPlay(event: any): void {
      // console.log('audio play', event);
      if (this.historyAudioTimeLoc != 0) {
        this.audioDom.currentTime = (this.historyAudioTimeLoc > this.audioDom.duration ? this.preStartPlayTime : this.historyAudioTimeLoc);
        this.historyAudioTimeLoc = 0;
      }
      this.audioPlaying = true;
      StorageUtils.updateByModule('audioDom.audioPlaying', true)
    }

    // 微信开放标签暂停
    wxOpenTagAudioPause(event: any): void {
      // console.log('audio pause');
      this.audioPlaying = false;
      StorageUtils.updateByModule('audioDom.audioPlaying', false)
      this.updateHistoryAudioPlay(this.audioInfo);
      StorageUtils.deleteByModule('album.playing');
    }

    // 微信开放标签进度更改
    wxOpenTagAudioTimeUpdate(event: any): void {
      const state = event.detail;
      // console.log(state.src);         // 当前音频来源
      // console.log(state.title);       // 当前音频标题
      // console.log(state.episode);     // 当前专辑名称
      // console.log(state.singer);      // 当前歌手名称
      // console.log(state.cover);       // 当前专辑封面
      // console.log(state.playState);   // 播放状态，'playing' | 'pause' | 'seeked'
      // console.log(state.paused);      // 是否暂停
      // console.log(state.buffered);    // 已缓冲位置，单位秒
      // console.log(state.currentTime); // 当前播放位置，单位秒
      // if (!this.isOpacity0) {
      // console.log('~~~~~~~~~~',state.currentTime); // 当前播放位置，单位秒
      // }
    }

    /**
     * 更新历史记录
     * @param audioObj: 上一个播放音频的基础信息
     */
    updateHistoryAudioPlay(audioObj: any, which: string = 'current'): void {
      // 当前播放音频对象为空/音频播放标签组件为空/用户id为空/当前音频所在的ind为-1等情况时不能添加历史记录
      if (audioObj == null || this.audioDom == null || ((this.userInfo.id || -1) == -1)) return;
      let audioIndexAtAlbum: number = which == 'previous' ? (this.previousPlayAudio.playData && (typeof this.previousPlayAudio.playData.ind == "number") ? this.previousPlayAudio.playData.ind + 1 : -1) : (this.indexAtAlbum + 1);
      if (audioIndexAtAlbum < 0 || this.ind < 0) return;
      if (!audioObj.hasOwnProperty('pid') || audioObj.pid == -1) return;
      if ((this.userInfo.id || -1) == -1) return;
      let curTime: number = this.audioDom && this.audioDom.currentTime ? this.audioDom.currentTime : 0;
      if (this.isWxSupport.openTag && (this.audioDom && ((typeof this.audioDom.paused == "boolean" && this.audioDom.paused) || typeof this.audioDom.paused != "boolean"))) {
        if (curTime == 0) {
          curTime = this.historyAudioTime;
        }
      }
      const data: any = {
        wid: this.userInfo.id || -1,
        apid: audioObj.pid || -1,
        aid: audioObj.id || -1,
        index: audioIndexAtAlbum,
        currentTime: curTime.toLocaleString() || '',
        duration: this.audioDom && this.audioDom.duration ? this.audioDom.duration.toLocaleString() : '',
      };
      console.log('update history data=', data, 'audio obj===', audioObj);
      StorageUtils.updateByModule('update.history', {
        response_status: false,
        data: data,
      });
      const successAdd: any = (res: any): void => {
        Utils.fireEvent(Utils.kEvents.changeHistoryList, true);
        Utils.fireEvent(Utils.kEvents.updateHistoryList, false);
        StorageUtils.deleteByModule('update.history');
        StorageUtils.deleteByModule('album.playing');
      };
      const params: any = {
        url: Http.kInterfacePaths.history.add,
        data: data,
        warn_info: '更新历史记录失败！',
        success: successAdd,
      };
      Http.ajax(this, params);
    }

    //分批次预加载音频
    getNewAudioBatchArr(): void {
      this.preloadedAudioNumber = 0;
      let len: number = 0;
      let arr: any[] = [];
      for (let i: number = 0; i < this.albumList.length; i++) {
        if (i > (this.ind + 2 * this.preloadBatchNumber + len) && len < 2) {
          arr.push(this.albumList[i]);
          len += 1;
        }
      }
      // console.log('除去audio后紧随的5个音频对象：', arr);
      if (arr.length == 0) return;
      this.needPreloadAudioLength = arr.length;
      for (let x: number = 0; x < arr.length; x++) {
        if (this.isWxSupport.openTag) {
          // this.preloadedAudioList.push(arr[x].audio);
          //
          // const data: any = {};
          // const errorQuery: any = (res: any): void => {
          // };
          // const successQuery: any = (res: any): void => {
          //   this.preloadAudioObjList['audio' + arr[x].id] = res;
          //   this.preloadedAudioNumber += 1;
          //   this.preloadedAudioIds.push(arr[x].id);
          //   if (this.preloadedAudioNumber == arr.length) {
          //     // 预加载完成
          //     this.preloadBatchNumber += 1;
          //     this.getNewAudioBatchArr();
          //   }
          // };
          // const params: any = {
          //   domain: false,
          //   url: arr[x].audio[0].url,
          //   data: data,
          //   success: successQuery,
          //   error: errorQuery,
          // };
          // Http.ajax(this, params);

        } else {
          // let itemAudio: any = new Audio(arr[x].audio[0].url);
          // itemAudio.preload = 'auto';
          // itemAudio.id = 'audio' + arr[x].id;
          // itemAudio.addEventListener('canplaythrough', (): void => {
          //   this.preloadedAudioNumber += 1;
          //   this.preloadedAudioIds.push(arr[x].id);
          //   if (this.preloadedAudioNumber == arr.length) {
          //     // 预加载完成
          //     // console.log('加载完成一批次后的preloadedAudioIds=', JSON.stringify(this.preloadedAudioIds));
          //     this.preloadBatchNumber += 1;
          //     // this.getNewAudioBatchArr();
          //   }
          // });
          // this.audioFrameDom.appendChild(itemAudio);
        }
      }
    }

    // wx-open-audio 标签准备好可以播放时监听执行的事件
    changeAudioCanPlayNumber(audio: any): void {
      this.preloadedAudioNumber += 1;
      this.preloadedAudioIds.push(audio.id);
      if (this.preloadedAudioNumber == this.needPreloadAudioLength) {
        // 预加载完成
        this.preloadBatchNumber += 1;
        this.getNewAudioBatchArr();
      }
    }

    // 修改定时类型
    changeTimingType(type: string): void {
      this.timingType = type;
      if (this.timingInterval != null) clearInterval(this.timingInterval);
      this.timingStart = 0;
      window.f7.sheet.close('.timing-sheet');
      const timingTypeList: string[] = ['time10', 'time20', 'time30', 'time60', 'time90'];
      if (timingTypeList.indexOf(this.timingType) > -1) {
        let timeMaxVal: number = this.timingList[this.timingType].number * 60;
        this.timingInterval = setInterval((): void => {
          this.timingStart += 1;
          if (this.timingStart >= timeMaxVal) {
            // 暂停播放内容
            this.playOrPauseController();
            clearInterval(this.timingInterval);
          }
        }, 1000);
      }
    }

    // 播放暂停控制
    playOrPauseController(): void {
      if (!this.isWxSupport.openTag) {
        // this.audioDom.addEventListener('timeupdate', this.updateProgress, false); // 监听音频播放时间并更新进度条
        // this.audioDom.addEventListener('ended', this.audioEnded, false); // 监听播放完成事件
        // 改变暂停/播放icon
        if (typeof this.audioDom.paused == "undefined") {
          this.audioDom.load();
          this.audioDom.play();
          StorageUtils.updateByModule('audioDom.audioPlaying', true)
        } else {
          if (this.audioDom.paused) {
            this.audioDom.play();
            if (this.audioDom.paused) {
              this.audioDom.play();
            }
            this.audioPlaying = true;
            StorageUtils.updateByModule('audioDom.audioPlaying', true)
          } else {
            this.audioDom.pause();
            this.audioPlaying = false;
            StorageUtils.updateByModule('audioDom.audioPlaying', false)
            setTimeout((): void => {
              StorageUtils.deleteByModule('album.playing');
            }, 500);
            this.updateHistoryAudioPlay(this.audioInfo);
          }
        }
      }
    }

    //播放
    playOrPauseControllerPlay(): void {
      if (!this.isWxSupport.openTag) {
        // 改变暂停/播放icon
        if (typeof this.audioDom.paused == "undefined") {
          this.audioDom.load();
          this.audioDom.play();
          StorageUtils.updateByModule('audioDom.audioPlaying', true)
        } else {
          if (this.audioDom.paused) {
            this.audioDom.play();
            if (this.audioDom.paused) {
              this.audioDom.play();
            }
            this.audioPlaying = true;
            StorageUtils.updateByModule('audioDom.audioPlaying', true)
          }
        }
      }
    }

    // 转换音频时长显示
    transTime(time: any): string {
      let duration: number = parseInt(time);
      // let minute: string = parseInt(duration/60);
      let minute: any = Math.floor(duration / 60);
      let sec = duration % 60 + '';
      let isM0 = ':';
      if (minute == 0) {
        minute = '00';
      } else if (minute < 10) {
        minute = '0' + minute;
      }
      if (sec.length == 1) {
        sec = '0' + sec;
      }
      return minute + isM0 + sec
    }

    // 更新进度条
    updateProgress(event: any = null): void {
      if (this.isProgressDragging) return;
      let curTime: number = this.audioDom.currentTime;
      // console.log(curTime)
      // if (event != null) {
      //   console.log(event)
      //   const state = event.detail;
      //   console.log('=====',state)
      //   // console.log(state.src);         // 当前音频来源
      //   // console.log(state.title);       // 当前音频标题
      //   // console.log(state.playState);   // 播放状态，'playing' | 'pause' | 'seeked'
      //   // console.log(state.paused);      // 是否暂停
      //   // console.log(state.buffered);    // 已缓冲位置，单位秒
      //   // console.log(state.currentTime); // 当前播放位置，单位秒
      //   curTime = state.currentTime;
      // }

      let percent = Math.floor(curTime) / Math.floor(this.audioDom.duration);
      let value = Math.round((Math.floor(curTime) / Math.floor(this.audioDom.duration)) * 100);
      this.progressWidth = value * 1 + "%";
      this.gaugeValue = percent;
      const styleSheet = document.createElement('style');
      document.head.appendChild(styleSheet);
      styleSheet.textContent = "#progress::after {right:-" + (80 - 80 * percent) + "px;}";
      this.playedTime = this.transTime(curTime);
      // console.log('played time=========', this.playedTime);

      const data: any = {
        from: 'playing',
        is_buy: this.playData.is_buy,
        is_member: this.userInfo.member_code != null,
        startPlayTime: curTime,
        album: this.albumInfo,
        audio: this.audioInfo,
        ind: this.playData.ind,
        id: this.audioInfo.id || -1,
        title: this.audioInfo.title || "",
      };
      const historyPlaying: any = {
        isFixed: true,
        album: this.albumInfo,
        audio: this.audioInfo,
        playData: data,
      };
      StorageUtils.updateByModule('album.playing', {
        time: (new Date()).getTime(),
        data: data,
        params: historyPlaying,
      });
    }

    // 播放完成
    audioEnded(): void {
      this.audioDom.currentTime = 0;
      this.audioDom.pause();
      this.audioPlaying = false;

      const timingTypeList: string[] = ['one', 'two', 'three'];
      if (timingTypeList.indexOf(this.timingType) > -1) {
        this.timingStart += 1;
        if (this.timingStart >= this.timingList[this.timingType].number) return;
      }

      // TODO. 根据不同模式操作播放完成后如何操作
      switch (this.mode) {
        case 'circulation':
          if (this.audioInfo.sort == this.albumTotalRow) {
            // 最后一首了，播放完成后，直接播放第一集
            if (this.albumList.length == this.albumTotalRow) {
              if (this.isTry) {
                let contactDialog = window.f7.dialog.alert("免费试听结束，收听完整版请购买专辑或者开通VIP会员");
                Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
                  opened: true,
                  dialog: contactDialog,
                });
                return;
              } else {
                // 直接播放列表的第一集即可
                this.ind = 0;
                const audioObj: any = this.albumList[this.ind];
                let tempData: any = {
                  from: this.playData.from,
                  is_buy: this.playData.is_buy,
                  is_member: this.playData.is_member,
                  startPlayTime: (audioObj.id == this.playData.id ? this.playData.startPlayTime : 0),
                  album: this.playData.album,
                  audio: audioObj,
                  id: audioObj.id || -1,
                  title: audioObj.title || "",
                  ind: audioObj.sort - 1 || 0,
                };
                Utils.fireEvent(Utils.kEvents.changeAlbumPlayer, {
                  isFixed: this.isMin,
                  album: this.albumInfo,
                  audio: this.albumList[this.ind],
                  playData: tempData,
                });
              }
            } else {
              if (this.isTry) {
                let contactDialog = window.f7.dialog.alert("免费试听结束，收听完整版请购买专辑或者开通VIP会员");
                Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
                  opened: true,
                  dialog: contactDialog,
                });
                return;
              } else {
                // 重新获取第一页列表并播放第一集音频
                this.needRefreshToPage = 1;
              }
            }
          } else {
            // 不是最后一首，直接播放下一集就可以
            this.goNextAudio();
          }
          break;
        case 'order':
          if (this.audioInfo.sort == this.albumTotalRow) {
            // 最后一首了，播放完成后,暂停不做任何操作
            if (!this.audioDom.paused) {
              this.playOrPauseController();
            }
            if (this.isTry) {
              let contactDialog = window.f7.dialog.alert("免费试听结束，收听完整版请购买专辑或者开通VIP会员");
              Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
                opened: true,
                dialog: contactDialog,
              });
              return;
            }
          } else {
            // 不是最后一首，直接播放下一集就可以
            this.goNextAudio();
          }
          break;
        case 'single':
          // 再次播放当前的音频
          this.audioDom.play();
          this.audioPlaying = true;
          StorageUtils.updateByModule('audioDom.audioPlaying', true)
          break;
      }
      StorageUtils.deleteByModule('album.playing');
    }

    // 快进快退
    skipWard(value: any): void {
      if (this.audioDom.currentTime + value >= this.audioDom.duration) {
        this.audioDom.currentTime = this.audioDom.duration;
      } else if (this.audioDom.currentTime + value <= 0) {
        this.audioDom.currentTime = 0;
      } else {
        this.audioDom.currentTime += value;
      }
    }

    // 快进15秒
    goForward15(): void {
      this.skipWard(15);
      this.updateProgress();
    }

    // 快退15秒
    goBackward15(): void {
      this.skipWard(-15);
      this.updateProgress();
    }

    // 获取上一集数据
    getPreData(): any {
      let _this = this;
      let albumList = this.albumList || [];
      let len = albumList.length;
      let obj = null;
      for (let i = 0; i < len; i++) {
        if (albumList[i].sort == _this.ind + 1) {
          obj = albumList[i];
          break;
        }
      }
      console.log('获取上一集数据', _this.ind, obj, albumList)
      return obj;
    }

    // 获取下一集数据
    getNextData(): any {
      let _this = this;
      let albumList = this.albumList || [];
      let len = albumList.length;
      let obj = null;
      for (let i = 0; i < len; i++) {
        if (albumList[i].sort == _this.ind + 1) {
          obj = albumList[i];
          break;
        }
      }
      console.log('获取下一集数据', _this.ind, obj, albumList)
      return obj;
    }

    // 上一集
    goPreviousAudio(): void {
      let dataObj = this.albumList[0];
      console.log('上一集===========', this.ind, dataObj, this.albumList)
      if (this.ind > dataObj.sort - 1) {
        // 上一集
        this.ind = this.ind - 1;
        // const audioObj: any = this.albumList[this.ind];
        const audioObj: any = this.getPreData();
        console.log('上一集', this.ind, audioObj)
        if (!audioObj) {
          this.updateList++;
          return;
        }
        let tempData: any = {
          from: this.playData.from,
          is_buy: this.playData.is_buy,
          is_member: this.playData.is_member,
          startPlayTime: (audioObj.id == this.playData.id ? this.playData.startPlayTime : 0),
          album: this.playData.album,
          audio: audioObj,
          id: audioObj.id || -1,
          title: audioObj.title || "",
          ind: audioObj.sort - 1 || 0,
        };
        Utils.fireEvent(Utils.kEvents.changeAlbumPlayer, {
          isFixed: this.isMin,
          album: this.albumInfo,
          audio: audioObj,//this.albumList[this.ind],
          playData: tempData,
        });
      } else {
        if (this.audioInfo.hasOwnProperty('sort') && this.audioInfo.sort == 1) {
          // 已经是第一集了，没有上一集了，不做任何操作
          window.f7.toast.create({
            text: '当前已为第一个音频了',
            closeTimeout: 2000,
            position: "center",
          }).open();
        } else {
          // TODO. 应该获取上一页列表信息了
          console.log('应该获取上一页列表信息了');
          window.f7.toast.create({
            text: '正在加载数据...',
            closeTimeout: 2000,
            position: "center",
          }).open();
        }
      }
    }

    // 下一集
    goNextAudio(): void {
      let dataObj = this.albumList[this.albumList.length - 1];
      console.log('下一集===========', this.ind, dataObj, this.albumList)
      if (this.ind < dataObj.sort - 1) {
        // 下一集
        this.ind = this.ind + 1;
        // const audioObj: any = this.albumList[this.ind];
        const audioObj: any = this.getNextData();
        if (!audioObj) {
          this.updateList++;
          return;
        }
        let tempData: any = {
          from: this.playData.from,
          is_buy: this.playData.is_buy,
          is_member: this.playData.is_member,
          startPlayTime: (audioObj.id == this.playData.id ? this.playData.startPlayTime : 0),
          album: this.playData.album,
          audio: audioObj,
          ind: audioObj.sort - 1 || 0,
          id: audioObj.id || -1,
          title: audioObj.title || "",
        };
        Utils.fireEvent(Utils.kEvents.changeAlbumPlayer, {
          isFixed: this.isMin,
          album: this.albumInfo,
          audio: audioObj,//this.albumList[this.ind],
          playData: tempData,
        });
        // 预加载
        // if (this.ind + 2 < this.albumList.length) {
        //   let Obj: any = this.albumList[this.ind + 2];
        //   if (!this.isWxSupport.openTag) {
        //     let itemAudio: any = new Audio(Obj.audio[0].url);
        //     itemAudio.preload = 'auto';
        //     itemAudio.id = 'audio' + Obj.id;
        //     itemAudio.addEventListener('canplaythrough', (): void => {
        //       this.preloadedAudioNumber += 1;
        //       this.preloadedAudioIds.push(Obj.id);
        //     });
        //     this.audioFrameDom.appendChild(itemAudio);
        //   }
        // }
        let _this = this;
        setTimeout(function () {
          if (_this.audioPlaying) {
            _this.audioDom.play();
            _this.audioPlaying = true;
          }
          setTimeout(function () {
            if (_this.audioPlaying) {
              _this.audioDom.play();
              _this.audioPlaying = true;
            }
            setTimeout(function () {
              if (_this.audioPlaying) {
                _this.audioDom.play();
                _this.audioPlaying = true;
              }
            }, 3000)
          }, 3000)
        }, 3000)
      } else {
        console.log('已经是最后一集了===========', this.ind, dataObj, this.audioInfo)
        if (this.audioInfo.hasOwnProperty('sort') && this.audioInfo.sort - 1 == this.ind) {
          // 已经是最后一集了，没有最后一集了，不做任何操作
          window.f7.toast.create({
            text: '当前已为最后一个音频了',
            closeTimeout: 2000,
            position: "center",
          }).open();
        } else {
          if (this.isTry) {
            let contactDialog = window.f7.dialog.alert("免费试听结束，收听完整版请购买专辑或者开通VIP会员");
            Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
              opened: true,
              dialog: contactDialog,
            });
            return;
          } else {
            // TODO. 应该获取下一页列表信息了
            console.log('应该获取下一页列表信息了');
            // this.isAutoResponseNextPage = true;
            window.f7.toast.create({
              text: '正在加载数据...',
              closeTimeout: 2000,
              position: "center",
            }).open();
          }
        }
      }
    }

    // 专辑列表所在的popup被打开后执行的事件
    onPlayListPopupOpened(): void {
      // TODO. 告诉APP主页列表页被打开，点击返回按钮后先关闭列表
      this.$emit('changeListPopupOpenStatus', true);
    }

    // 专辑列表所在的popup被关闭后执行的事件
    onPlayListPopupClosed(): void {
      // TODO. 告诉APP主页列表页被关闭
      this.$emit('changeListPopupOpenStatus', false);
    }

    // 改变播放循环模式
    onChangeModeStatus(params: any): void {
      this.mode = params;
    }

    toggleAudioMode(): void {
      let resMode: string = this.mode;
      switch (this.mode) {
        case 'circulation':
          //当前循环播放--》单曲循环
          resMode = 'single';
          break;
        case 'single':
          //当前单曲循环--》顺序播放
          resMode = 'order';
          break;
        case 'order':
          //当前顺序播放--》循环播放
          resMode = 'circulation';
          break;
      }
      this.mode = resMode;
    }

    onChangeListPopupOpenStatus(params: any): void {
      // TODO. 告诉APP主页列表页被打开，点击返回按钮后先关闭列表
      this.$emit('changeListPopupOpenStatus', params);
    }

    onChangePlayedAudio(params: any): void {
      // TODO. 被告知更新当前播放的audio对象
      console.log(params);
      let tempData: any = {
        from: this.playData.from,
        is_buy: this.playData.is_buy,
        is_member: this.playData.is_member,
        startPlayTime: (params.audioInfo.id == this.playData.id ? this.playData.startPlayTime : 0),
        album: this.playData.album,
        audio: params.audioInfo,
        ind: params.sort - 1,
        id: params.audioInfo.id || -1,
        title: params.audioInfo.title || "",
      };
      Utils.fireEvent(Utils.kEvents.changeAlbumPlayer, {
        isFixed: this.isMin,
        album: this.albumInfo,
        audio: params.audioInfo,
        playData: tempData,
      });
      this.$emit('changeListPopupOpenStatus', false);
      this.updatePlayingAudioAtIndex(params.audioInfo.id);
    }

    onChangeAlbumList(params: any): void {
      // TODO. 被告知更新了专辑列表
      this.albumList = params.hasOwnProperty('list') && params.list ? params.list : [];
      this.albumTotalRow = params.hasOwnProperty('totalRow') && params.totalRow ? params.totalRow : 0;
      this.albumCurPage = params.hasOwnProperty('curPage') && params.curPage ? params.curPage : 1;
      // this.albumPageSize = params.hasOwnProperty('pageSize') && params.pageSize ? params.pageSize : 15;

      if (this.needRefreshToPage == 1) {
        this.ind = 0;
        const audioObj: any = this.albumList[this.ind];
        let tempData: any = {
          from: this.playData.from,
          is_buy: this.playData.is_buy,
          is_member: this.playData.is_member,
          startPlayTime: (audioObj.id == this.playData.id ? this.playData.startPlayTime : 0),
          album: this.playData.album,
          audio: audioObj,
          ind: audioObj.sort - 1 || 0,
          id: audioObj.id || -1,
          title: audioObj.title || "",
        };
        Utils.fireEvent(Utils.kEvents.changeAlbumPlayer, {
          isFixed: this.isMin,
          album: this.albumInfo,
          audio: audioObj,
          playData: tempData,
        });
        this.needRefreshToPage = -1;
      } else {
        this.updatePlayingAudioAtIndex(this.audioInfo.id);
      }
      // console.log('index~~~~~~~~~=', this.ind);
      // console.log('index+preloadedAudioNumber=', (this.ind + this.preloadedAudioIds.length));
      // console.log('==========', this.preloadBatchNumber);
      this.getNewAudioBatchArr();
    }

    // 更新当前播放的audio属于列表的第几项
    updatePlayingAudioAtIndex(audioId: number): void {
      for (let i: number = 0; i < this.albumList.length; i++) {
        if (this.albumList[i].id == audioId) {
          this.ind = this.albumList[i].sort - 1;
        }
      }
      // console.log('ind===',this.ind)
    }

    // 更新最小化至最大化
    changePlayMinStatus(): void {
      Utils.fireEvent(Utils.kEvents.updatePopupShow, true);
    }

    tipAlbumPage(): void {
      Utils.fireEvent(Utils.kEvents.updateAudioPlayMinStatus, {is_min: true});
      const data: any = {
        id: (this.albumInfo.id || -1),
        title: this.albumInfo.title || "",
        from: 'home',
      };
      StorageUtils.updateByModule('album.query', data);
      Utils.load(this, '/album');
    }
  }
</script>

<style scoped>
  /* 自定义语音样式*/
  .audio-box {
    margin: 0 auto;
    /*background-color: #ffffff;*/
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .a-progress {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 100%;
    margin: 30px auto;
    position: relative;
    padding: 0 40px;
    color: #ff9500;
  }

  .a-progress > span {
    width: 40px;
    position: absolute;
    top: -6px;
  }

  .a-progress .gobackward15 {
    left: 0;
  }

  .a-progress .goforward15 {
    right: 0;
    text-align: right;
    text-align: -moz-right;
    text-align: -webkit-right;
  }

  .a-progress .pgs-total {
    width: 100%;
    height: 16px;
    background-color: transparent;
    border-radius: 10px;
    position: relative;
  }

  .a-progress .pgs-total:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 7px;
    bottom: 7px;
    background-color: #bbbbbb;
    border-radius: 10px;
    z-index: 0;
  }

  .a-progress .pgs-total .pgs-play {
    height: 100%;
    position: relative;
    border-radius: 10px;
  }

  .a-progress .pgs-total .pgs-play:before {
    content: '';
    position: absolute;
    top: 7px;
    bottom: 7px;
    left: 0;
    right: 0;
    background-color: #ff9500;
    border-radius: 10px;
    z-index: 1;
  }

  .a-progress .pgs-total .pgs-play:after {
    content: attr(data-content-after);
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
    font-size: 12px;
    color: #ffffff;
    position: absolute;
    right: -80px;
    width: 80px;
    height: 16px;
    top: 50%;
    margin-top: -8px;
    background-color: #ff9500;
    border-radius: 8px;
    z-index: 1;
  }
</style>


  import {Options, Vue} from "vue-class-component";
  import WarnShare from "@/components/warn-share.vue";
  import Utils from "../js/utils/utils";
  import {Prop} from "vue-property-decorator";
  import StorageUtils from "@/js/utils/storage";

  @Options({
    components: {
      WarnShare,
    },
  })
  export default class TitleShare extends Vue {
    @Prop({default: false}) noShowShare!: boolean; // 不显示分享按钮，true不显示，false显示
    name: string = "title-share";
    // logoUrl: string = './static/img/logo.jpg';
    logoUrl: any = require('@/assets/img/logo.png').default;
    // arrowUpUrl: string = './static/img/arrow_warning.png';
    // wechatFriendsUrl: string = './static/img/wechat_friends.png';
    // wechatFriendCircleUrl: string = './static/img/wechat_friend_circle.png';
    arrowUpUrl: any = require('@/assets/img/arrow_warning.png').default;
    wechatFriendsUrl: any = require('@/assets/img/wechat_friends.png').default;
    wechatFriendCircleUrl: any = require('@/assets/img/wechat_friend_circle.png').default;
    // shareToast: any = null;
    isShareWarn: boolean = false;
    isShowShareBtn: boolean = false;

    created(): void {
      Utils.bindEvent(Utils.kEvents.updateTitleShareBtnStatus, (params: any): void => {
        if (typeof params == "boolean") {
          this.isShowShareBtn = params;
        } else {
          this.isShowShareBtn = false;
        }
      });
    }

    tipHomePageTo(tab_name: string): void {
      StorageUtils.updateByModule('audioPlay.isOpened', false);
      let curPagePath: string = StorageUtils.getByModule('page.new');
      curPagePath = curPagePath || '/';
      if (curPagePath != '/') {
        this.isShowShareBtn = false;
        StorageUtils.updateByModule('home.tab', tab_name);
        Utils.back(this, '/', {activePage: tab_name}, {
          force: true,
          reloadAll: true,
        });
      } else {
        Utils.fireEvent(Utils.kEvents.updateAudioPlayMinStatus, {is_min: true});
        Utils.fireEvent(Utils.kEvents.updateHomePageName, {
          active: tab_name,
        });
        window.f7.tab.show('#' + tab_name);
      }
    }

    shareWarnStatus(params: any): void {
      this.isShareWarn = params;
    }

  }

import HomePage from '../pages/home.vue';
import AlbumInfoPage from "@/pages/album.vue";
// import AboutPage from '../pages/about.vue';
// import FormPage from '../pages/form.vue';
// import DynamicRoutePage from '../pages/dynamic-route.vue';
// import RequestAndLoad from '../pages/request-and-load.vue';
import NotFoundPage from '../pages/404.vue';
import loginPage from '../pages/Login.vue';
import vipPage from '../pages/vip.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/login',
    component: loginPage,
  },
  {
    path: '/register',
    asyncComponent: import('@/pages/Register.vue'),
  },
  {
    path: '/album_all',// 全部专辑
    asyncComponent: import('@/pages/album-more.vue'),
  },
  {
    path: '/album',// 专辑详情
    component: AlbumInfoPage,
  },
  {
    path: '/buy_confirm_album', // 专辑购买确认页
    asyncComponent: import('@/pages/buy-confirm-album.vue'),
  },
  {
    path: '/my', // 我的个人信息
    asyncComponent: import('@/pages/my.vue'),
  },
  {
    path: '/my_order', // 我的订单
    asyncComponent: import('@/pages/my-order2.vue'),
  },
  {
    path: '/my_coupon', // 我的优惠券
    asyncComponent: import('@/pages/my-coupon.vue'),
  },
  {
    path: '/goods', // 心选好物
    asyncComponent: import('@/pages/goods.vue'),
  },
  {
    path: '/goods_detail', // 心选好物详情
    asyncComponent: import('@/pages/goods-detail.vue'),
  },
  {
    path: '/buy_confirm_goods', // 心选好物购买确认页
    asyncComponent: import('@/pages/buy-confirm-goods.vue'),
  },
  {
    path: '/vip', // VIP会员
    // asyncComponent: import('@/pages/vip.vue'),
    component: vipPage,
  },
  {
    path: '/vip_team', // VIP会员-我的团队
    asyncComponent: import('@/pages/vip-team.vue'),
  },
  {
    path: '/vip_invite', // VIP会员-会员邀请页
    asyncComponent: import('@/pages/vip-invite.vue'),
  },
  {
    path: '/limited_offer', // 限时优惠
    asyncComponent: import('@/pages/limited-offer.vue'),
  },
  {
    path: '/buy_confirm_vip', // VIP会员购买确认页
    asyncComponent: import('@/pages/buy-confirm-vip.vue'),
  },
  {
    path: '/gift_detail', // 礼物邀请领取页
    asyncComponent: import('@/pages/gift-detail.vue'),
  },
  {
    path: '/gift_address', // 礼物邀请常用地址填写页
    asyncComponent: import('@/pages/gift-address.vue'),
  },
  {
    path: '/gift_invite', // 礼物邀请人员列表页
    asyncComponent: import('@/pages/gift-invite.vue'),
  },
  {
    path: '/address_list', // 收货地址列表页
    asyncComponent: import('@/pages/address-list.vue'),
  },
  {
    path: '/my_update', // 个人信息修改页
    asyncComponent: import('@/pages/my-update.vue'),
  },

  // {
  //   path: '/about/',
  //   component: AboutPage,
  // },
  // {
  //   path: '/form/',
  //   component: FormPage,
  // },
  // {
  //   path: '/dynamic-route/blog/:blogId/post/:postId/',
  //   component: DynamicRoutePage,
  // },
  // {
  //   path: '/request-and-load/user/:userId/',
  //   //@ts-ignore
  //   async: function ({router, to, resolve}) {
  //     // App instance
  //     var app = router.app;
  //
  //     // Show Preloader
  //     app.preloader.show()
  //
  //     // User ID from request
  //     var userId = to.params.userId;
  //
  //     // Simulate Ajax Request
  //     setTimeout(function () {
  //       // We got user data from request
  //       var user = {
  //         firstName: 'Vladimir',
  //         lastName: 'Kharlampidi',
  //         about: 'Hello, i am creator of Framework7! Hope you like it!',
  //         links: [
  //           {
  //             title: 'Framework7 Website',
  //             url: 'http://framework7.io',
  //           },
  //           {
  //             title: 'Framework7 Forum',
  //             url: 'http://forum.framework7.io',
  //           },
  //         ]
  //       };
  //       // Hide Preloader
  //       app.preloader.hide();
  //
  //       // Resolve route to load page
  //       resolve(
  //           {
  //             component: RequestAndLoad,
  //           },
  //           {
  //             props: {
  //               user: user,
  //             }
  //           }
  //       );
  //     }, 1000);
  //   },
  // },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;


  import {Options, Vue} from "vue-class-component";
  import {Prop, Watch} from 'vue-property-decorator';
  import GoodsList from "@/components/goods-list.vue";
  import Http from "@/js/utils/http.ts";
  import StorageUtils from "@/js/utils/storage.ts"
  import CommonUtils from "@/js/common/common.ts"
  import Utils from "@/js/utils/utils.ts";

  @Options({
    components: {
      GoodsList,
    },
  })
  export default class Vip extends Vue {
    // 父组件传值接收
    @Prop(String) currentPageName!: string;
    // gifts: any[] = [];
    openid: string = '';
    userInfo: any = {};
    bg_url: any = require('@/assets/img/liwu_bg.png').default;
    libao_url: any = require('@/assets/img/liwu_libao.png').default;
    lingqu_url: any = require('@/assets/img/liwu_lingqu_btn.png').default;

    loading: boolean = true;
    preloader: boolean = true;
    pageSize: number = 10;
    curPage: number = 1;
    totalPage: number = 1;
    totalRow: number = 0;
    gifts: any[] = [];


    @Watch('currentPageName', {immediate: true, deep: true})
    onTabIsShowChanged(val: any, oldVal: any): void {
      if (val == 'vip') {
        this.openid = StorageUtils.getByModule('user.openid') || '';
        CommonUtils.getUserInfo(this, (params: any): void => {
          this.userInfo = params;
          this.getGiftListBy(this.curPage);
        });
      }
    }

    // created(): void {
    //   this.openid = StorageUtils.getByModule('user.openid') || '';
    //   CommonUtils.getUserInfo(this, (params: any): void => {
    //     this.userInfo = params;
    //   });
    //   this.getGiftListBy(this.curPage);
    // }

    changeCurrentPageName(): void {
      this.$emit('changeActivePageName', 'vip');
    }

    // tipOtherPage(url: string): void {
    //   let data: any = {from: 'vip',};
    //   // if ('/invite_friends') {
    //   //   data = {
    //   //     from: 'vip',
    //   //   };
    //   // }
    //   Utils.load(this, url, data);
    // }
    //

    onClickGiftDetail(params: any): void {
      // console.log(params)
      if (params.stock <= 0) {
        window.f7.toast.create({
          text: '活动太火爆了，该商品已抢光啦~~~~',
          closeTimeout: 2000,
          position: "center",
        }).open();
        return;
      }
      let data1: any = {
        user_id: this.userInfo.id || -1,
        gift_id: params.id || -1,
      };
      let successQuery: any = (res: any): void => {
        const resData: any = res.hasOwnProperty('data') && res.data ? res.data : null;
        if (!resData || typeof resData == "string") {
          // 到详情页
          let data: any = {
            id: params.hasOwnProperty('id') && params.id ? params.id : -1,
            from: 'gifts',
          };
          StorageUtils.updateByModule('gift.detail', data);
          Utils.load(this, '/gift_detail', data);
        } else {
          // 已选择过，直接到流水线页面
          const temp: any = {
            id: params.id || -1,
            gift_user_relation_id: resData.hasOwnProperty('id') && resData.id ? resData.id : -1,
            need_invite_user_number: resData.hasOwnProperty('total_num') && resData.total_num ? resData.total_num : 0,
            invited_user_number: resData.hasOwnProperty('finish_num') && resData.finish_num ? resData.finish_num : 0,
          };
          Utils.load(this, '/gift_invite', temp);
        }
      };
      let params1: any = {
        url: Http.kInterfacePaths.gift.checkExchange,
        data: data1,
        warn_info: '查询是否领取请求失败，稍后尝试！',
        success: successQuery,
      };
      Http.ajax(this, params1);
    }

    getNextGiftList(): void {
      if (!this.loading) return;
      this.loading = false;
      setTimeout((): void => {
        if (this.curPage >= this.totalPage) {
          this.preloader = false;
          return;
        }
        let curPageNumber = this.curPage + 1;
        this.getGiftListBy(curPageNumber);
        this.loading = true;
      }, 1000);
    }

    getGiftListBy(cur_page: number): void {
      let data: any = {
        page: cur_page,
        size: this.pageSize,
        // wid: this.userInfo.id || -1,
        user_id: this.userInfo.id || -1,
        is_soldout: 0,//上架的
      };
      let successList: any = (res: any): void => {
        this.totalPage = res.hasOwnProperty('total') ? res.total : 1;
        this.curPage = res.hasOwnProperty('page') ? res.page : 1;
        this.totalRow = res.hasOwnProperty('rows') ? res.rows : 0;

        let curResData = res.hasOwnProperty('data') ? res.data : [];

        if (this.totalPage <= 1) {
          this.preloader = false;
        }

        if (this.curPage <= 1) {
          this.gifts = curResData;
        } else {
          let afterArr = this.gifts;
          for (let i in curResData) {
            afterArr.push(curResData[i]);
          }
          this.gifts = afterArr;
        }
      };
      let params: any = {
        url: Http.kInterfacePaths.gift.list,
        data: data,
        warn_info: '获取礼品记录失败！',
        success: successList,
      };
      Http.ajax(this, params);
    }

  }

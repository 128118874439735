
  import {Options, Vue} from "vue-class-component";
  import {Prop} from "vue-property-decorator";
  import StorageUtils from "@/js/utils/storage";
  import Utils from "@/js/utils/utils";

  @Options({})
  export default class AlbumList extends Vue {
    @Prop(String) title!: string;
    @Prop(Object) albums!: any[];
    name: string = "album-list";
    // limitedOfferIcon: string = './static/img/icon_limited_offer1.png';
    limitedOfferIcon: any = require('@/assets/img/icon_limited_offer1.png').default;

    tipAlbumPage(item: any): void {
      const data: any = {
        id: (item.id || -1),
        title: item.title || "",
        from: 'home',
      };
      StorageUtils.updateByModule('album.query', data);
      Utils.load(this, '/album');
    }
  }

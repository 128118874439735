<template>
  <f7-view>
    <f7-page name="playerAudiosPopup" :no-toolbar="true"
             :ptr="ptrPreloader" :ptr-distance="56"
             @ptr:refresh="getPreviousAudioList"
             infinite :infinite-distance="50"
             :infinite-preloader="preloader" @infinite="getNextAlbumList"
    >
      <f7-navbar>
        <f7-nav-title style="margin-left:0px;">
          <f7-link @click="toggleAudioMode"
                   class="color-theme-black album-player-popup-title">
            <!--循环播放-->
            <f7-icon v-if="mode=='circulation'" f7="repeat"></f7-icon>
            <!--单曲循环播放-->
            <f7-icon v-if="mode=='single'" f7="repeat_1"></f7-icon>
            <!--顺序播放-->
            <f7-icon v-if="mode=='order'" f7="menu"></f7-icon>
            {{mode=='circulation'?'循环播放':(mode=='single'?'单曲循环':'顺序播放')}}
          </f7-link>
        </f7-nav-title>
        <f7-nav-right>
          <f7-link class="color-theme-orange" @click="onPlayListPopupClosed">
            <f7-icon f7="multiply"></f7-icon>
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-list>
        <f7-list-item v-for="(el,index) in albums" :key="el.id"
                      @click="changePlayingAudio(el,index)">
          <div slot="media" class="album-image-36 image-tblr-center"
               style="position:relative;top:-2px;"
               :style="{'backgroundImage':((el.cover&&el.cover.length>0)||(parentAlbum.cover&&parentAlbum.cover.length>0)?'url(\''+(el.cover&&el.cover.length>0?el.cover[0].url:parentAlbum.cover[0].url)+'\')':'unset'),
             'filter':((el.cover&&el.cover.length>0)||(parentAlbum.cover&&parentAlbum.cover.length>0)?'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\''+(el.cover&&el.cover.length>0?el.cover[0].url:parentAlbum.cover[0].url)+'\',sizingMethod=scale)':'unset'),
             '-ms-filter':((el.cover&&el.cover.length>0)||(parentAlbum.cover&&parentAlbum.cover.length>0)?'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\''+(el.cover&&el.cover.length>0?el.cover[0].url:parentAlbum.cover[0].url)+'\',sizingMethod=scale)':'unset')}"
          ></div>
          <div slot="title"
               :style="{'color':(el.id==audioId)?'#ff9500':'#333333'}">
            {{el.title||''}}
          </div>
        </f7-list-item>
      </f7-list>
    </f7-page>
  </f7-view>
</template>
<script lang="ts">
  import {Options, Vue} from "vue-class-component";
  import {Prop, Watch} from 'vue-property-decorator';
  import Http from '@/js/utils/http.ts';

  @Options({
    components: {},
  })
  export default class Album extends Vue {
    @Prop({default: -1}) audioId!: number;// 正在播放的视频的id
    @Prop({default: -1}) ind!: number;// 播放第几个视频
    @Prop(Boolean) isTry!: boolean;// 是否试听
    @Prop(Boolean) isSwitchParentAlbum!: boolean;// 是否切换了专辑
    // 播放模式，random:随机模式 single:单曲循环 circulation:列表循环 order:顺序播放（列表播放完即停止）
    @Prop({default: 'circulation'}) mode!: string;
    @Prop(Object) parentAlbum!: object | any;// 播放的专辑详情
    @Prop(Boolean) isRefreshToFirstPage!: boolean;// 是否请求第一页数据暂时保存
    @Prop(Boolean) isChangeAlbumFirstAudio!: boolean;// 是否清空当前播放列表单，播放第一个视频
    @Prop(Boolean) isAutoGetNextPage!: boolean;// 是否自动获取下一页列表
    @Prop(Boolean) isAutoGetPreviousPage!: boolean;// 是否自动获取下一页列表
    @Prop(Boolean) isOpened!: boolean;// 当前音频是否处于开启状态

    previousCurPage: number = 1;
    ptrPreloader: boolean = true;
    loading: boolean = true;
    preloader: boolean = true;
    pageSize: number = 10;
    curPage: number = 1;
    totalPage: number = 1;
    totalRow: number = 0;
    albums: any [] = [];

    firstPageAlbums: any[] = [];


    @Watch('isSwitchParentAlbum', {immediate: true, deep: true})
    onChangeIsSwitchAlbum(val: any, oldVal: any): void {
      // console.log('is switch parent album', val, this.ind, this.parentAlbum.id);
      if (val && this.isOpened) {
        this.albums.splice(0, this.albums.length);
        if (this.parentAlbum && this.parentAlbum.id != -1) {
          let curPage: number = Math.ceil((this.ind + 1) / this.pageSize) || 1;
          this.curPage = curPage;
          this.previousCurPage = curPage;
          this.getAlbumAudioBy(this.curPage, 'next');
        }
      }
    }

    @Watch('audioId', {immediate: true, deep: true})
    onChangePlayingAudioId(val: any, oldVal: any): void {
      console.log('====播放音频修改了====', this.audioId);
      // console.log(val,oldVal,this.isSwitchParentAlbum);
      // console.log(this.albums);
      if (val && val != -1 && !this.isSwitchParentAlbum) {
        let lastInd: number = -1;
        for (let i: number = 0; i < this.albums.length; i++) {
          if (this.albums[i].id == this.audioId) {
            lastInd = i;
          }
        }
        if (lastInd == -1) {
          this.albums.splice(0, this.albums.length);
          let curPage: number = Math.ceil((this.ind + 1) / this.pageSize);
          this.curPage = curPage;
          this.previousCurPage = curPage;
          this.getAlbumAudioBy(this.curPage, 'next');
        } else {
          this.$emit('changePlayedAudio', {
            ind: lastInd,
            audioInfo: this.albums[lastInd],
          });
        }
      }
    }

    @Watch('isRefreshToFirstPage', {immediate: true, deep: true})
    onChangeToFirstPage(val: any, oldVal: any): void {
      if (val) {
        console.log('isRefreshToFirstPage', val)
        this.getAlbumAudioBy(1, 'next', (res: any): void => {
          this.firstPageAlbums = res;
        });
      }
    }

    @Watch('isChangeAlbumFirstAudio', {immediate: true, deep: true})
    onChangeAlbumFirstAudioPlaying(val: any, oldVal: any): void {
      console.log('isChangeAlbumFirstAudio', val);
      if (val && this.isOpened) {
        this.curPage = 1;
        this.previousCurPage = 1;
        this.albums = this.firstPageAlbums;
        this.$emit('changeAlbumAudios', {
          totalRow: this.totalRow,
          curPage: this.curPage,
          pageSize: this.pageSize,
          list: this.firstPageAlbums,
        });
        if (this.firstPageAlbums.length > 0) {
          this.$emit('changePlayedAudio', {
            ind: 0,
            audioInfo: this.firstPageAlbums[0],
          });
        }
      }
    }

    @Watch('isAutoGetNextPage', {immediate: true, deep: true})
    onChangeIsAutoGetNextPage(val: any, oldVal: any): void {
      this.$nextTick((): void => {
        if (val) {
          const curPageNumber: number = this.curPage + 1;
          this.getAlbumAudioBy(curPageNumber, 'next');
        }
      });
    }

    @Watch('isAutoGetPreviousPage', {immediate: true, deep: true})
    onChangeIsAutoGetPreviousPage(val: any, oldVal: any): void {
      this.$nextTick((): void => {
        if (val) {
          this.getAlbumAudioBy(this.previousCurPage - 1, 'previous');
        }
      });
    }

    getNextAlbumList(): void {
      if (!this.loading) return;
      this.loading = false;
      setTimeout((): void => {
        if (this.curPage >= this.totalPage) {
          this.preloader = false;
          return;
        }
        const curPageNumber: number = this.curPage + 1;
        this.getAlbumAudioBy(curPageNumber, 'next');
        this.loading = true;
      }, 1000);
    }

    getPreviousAudioList(done: any): void {
      setTimeout((): void => {
        if (this.previousCurPage <= 1) {
          this.ptrPreloader = false;
          return;
        }
        this.getAlbumAudioBy(this.previousCurPage - 1, 'previous');
        done();
      }, 1000);
    }

    getAlbumAudioBy(cur_page: number, direction: string = 'next', successHandler: any = null): void {
      if (!(this.parentAlbum.hasOwnProperty('id') && this.parentAlbum.id) || cur_page <= 0) {
        return;
      }
      let filterObj: any = {
        pid: this.parentAlbum.id,
      };
      if (this.isTry) {
        Object.assign(filterObj, {
          is_free: true,
        });
      }
      let filter: any [] = [];
      filter.push(filterObj)
      filter.push({
        is_soldout: false,
      });

      let data: any = {
        page: cur_page,
        size: this.pageSize,
        filter: filter,
      };
      let successList: any = (res: any): void => {
        this.totalPage = res.hasOwnProperty('total') ? res.total : 1;
        const page_number: number = res.hasOwnProperty('page') && res.page ? res.page : 1;
        this.totalRow = res.hasOwnProperty('rows') ? res.rows : 0;
        let curResData: any[] = res.hasOwnProperty('data') ? res.data : [];
        for (let i: number = 0; i < curResData.length; i++) {
          // let sortNumber: number = (page_number - 1) * this.pageSize + i + 1;
          curResData[i]['sort'] = (page_number - 1) * this.pageSize + i + 1;
        }

        if (this.totalPage <= 1) {
          this.preloader = false;
        }

        if (successHandler && typeof successHandler == "function") {
          successHandler(curResData);
        } else {
          let afterArr: any[] = (page_number <= 1) && direction == 'next' ? [] : this.albums;
          switch (direction) {
            case 'next':
              this.curPage = page_number;
              if (this.curPage <= 1) {
                afterArr = curResData;
              } else {
                for (let i: number = 0; i < curResData.length; i++) {
                  afterArr.push(curResData[i]);
                }
              }
              break;
            case 'previous':
              this.previousCurPage = page_number;
              for (let i: number = 0; i < curResData.length; i++) {
                afterArr.splice(i, 0, curResData[i]);
              }
              break;
          }
          this.albums = afterArr;
          this.$emit('changeAlbumAudios', {
            totalRow: this.totalRow,
            curPage: this.curPage,
            pageSize: this.pageSize,
            list: afterArr,
          });
        }

      };
      let params: any = {
        url: Http.kInterfacePaths.album.list,
        data: data,
        warn_info: '获取记录失败！',
        success: successList,
      };
      Http.ajax(this, params);
    }

    toggleAudioMode(): void {
      let resMode: string = this.mode;
      switch (this.mode) {
        case 'circulation':
          //当前循环播放--》单曲循环
          resMode = 'single';
          break;
        case 'single':
          //当前单曲循环--》顺序播放
          resMode = 'order';
          break;
        case 'order':
          //当前顺序播放--》循环播放
          resMode = 'circulation';
          break;
      }
      this.$emit('changeModeStatus', resMode);
    }

    onPlayListPopupClosed(): void {
      this.$emit('changeListPopupOpenStatus', false);
    }

    changePlayingAudio(item: any, ind: number): void {
      this.$emit('changePlayedAudio', {
        ind: ind,
        audioInfo: item,
      });
    }
  }
</script>

<template>
  <f7-popup :id="idName" :close-by-backdrop-click="true"
            style="background:transparent;">
    <!--@popup:opened="changeShareWarnShowStatus(true)"
    @popup:closed="changeShareWarnShowStatus(false)"-->
    <!--<f7-page :no-navbar="true" :no-toolbar="true"
             style="background-color: transparent;">-->
    <div class="share-warn-frame" @click="changeShareWarnShowStatus(false)">
      <!-- :style="{'display':(isShareWarn?'block':'none')}">-->
      <img :src="arrowUpUrl" alt="指引箭头" class="share-arrow-up-img">
      <p class="share-warn-info" style="margin-top:120px;">
        <span class="share-warn-step-mask text-center">1</span>
        点击右上角的
        <span class="share-warn-mask">
          <span style="position:relative;top:0px;">…</span>
        </span>
        按钮
      </p>
      <p class="share-warn-info">
        <span class="share-warn-step-mask text-center">2</span>
        选择
        <img :src="wechatFriendsUrl" alt="转发给朋友">
        或
        <img :src="wechatFriendCircleUrl" alt="分享朋友圈">
        <!--<span class="share-warn-mask">
          <span style="position:relative;top:-6px;">…</span>
        </span>-->
        实现分享
      </p>
    </div>
    <!--    </f7-page>-->
  </f7-popup>
</template>

<script lang="ts">
  import {Options, Vue} from "vue-class-component";
  import {Prop, Watch} from "vue-property-decorator";

  @Options({})
  export default class WarnShare extends Vue {
    @Prop({default: 'warnSharePopup'}) idName!: string;
    @Prop({default: false}) isShareWarn!: boolean;
    name: string = "warn-share";
    // arrowUpUrl: string = './static/img/arrow_warning.png';
    // wechatFriendsUrl: string = './static/img/wechat_friends.png';
    // wechatFriendCircleUrl: string = './static/img/wechat_friend_circle.png';
    arrowUpUrl: any = require('@/assets/img/arrow_warning.png').default;
    wechatFriendsUrl: any = require('@/assets/img/wechat_friends.png').default;
    wechatFriendCircleUrl: any = require('@/assets/img/wechat_friend_circle.png').default;

    @Watch('isShareWarn', {immediate: true, deep: true})
    onUpdateIsShareWarn(val: any, oldVal: any): void {
      console.log('====is show warn========', this.isShareWarn);
      if (val) {
        window.f7.popup.open('#' + this.idName);
      } else {
        window.f7.popup.close('#' + this.idName);
      }
    }

    changeShareWarnShowStatus(is_show: boolean): void {
      this.$emit('updateShowStatus', is_show);
    }
  }
</script>

<style scoped>
  .popup-backdrop {
    z-index: 10500 !important;
  }

  .share-warn-frame {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1999;
    overflow: hidden;
    padding: 16px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .share-arrow-up-img {
    width: 80px;
    height: 80px;
    display: inline-block;
    float: right;
    margin-top: 16px;
    margin-right: 16px;
  }

  .share-warn-info {
    font-size: 22px;
    color: #ffffff;
    /*margin-top: 16px;*/
    font-family: "hwxk";
  }

  .share-warn-mask {
    background-color: #ffffff;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    color: #282828;
    padding: 0 8px;
  }

  .share-warn-step-mask {
    width: 30px;
    height: 30px;
    display: inline-block;
    background-color: #ff9500;
    border-radius: 15px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    font-size: 20px;
    color: #ffffff;
  }

  .share-warn-info img {
    display: inline-block;
    width: 50px;
    height: 70px;
    position: relative;
    top: 20px;
  }
</style>

const wechat = {
  appId: '',
  config: (params: any): void => {
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: params.appId || '', // 必填，公众号的唯一标识
      timestamp: params.timestamp || 0, // 必填，生成签名的时间戳
      nonceStr: params.nonceStr || '', // 必填，生成签名的随机串
      signature: params.signature || '',// 必填，签名
      jsApiList: [
        'chooseWXPay',
        // 'closeWindow',
        // 'chooseCard',
        // 'openCard',
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareWeibo',
        'onMenuShareQZone',
        'updateAppMessageShareData',
        'updateTimelineShareData',
      ], // 必填，需要使用的JS接口列表
      openTagList: [
        'wx-open-audio',
      ],
    });
  },
  error: (callback: any = null): void => {
    wx.error((res: any): void => {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      // console.log('++++++++', res);
      // alert(res.errMsg);
      if (typeof callback == "function") {
        callback();
      }
    });
  },
  ready: (callback: any = null): void => {
    wx.ready((): void => {
      // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      // console.log("ready=================");
      if (typeof callback == "function") {
        callback();
      }
    });
  },
  // 获取“分享到朋友圈”按钮点击状态及自定义分享内容接口
  onMenuShareFriendSpace: (params: any, successHandler: any = null): void => {
    // 需在用户可能点击分享按钮前就先调用
    wx.ready((): void => {
      wx.onMenuShareTimeline({
        title: params.title || '', // 分享标题
        link: params.link || '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: params.imgUrl || '', // 分享图标
        success: (): void => {
          // 用户点击了分享后执行的回调函数
          if (successHandler && typeof successHandler == 'function') {
            successHandler();
          }
        },
        cancel: (): void => {
          // 用户点击取消时的回调函数，仅部分有用户取消操作的api才会用到。
        },
      });
    });
  },
  // 获取“分享给朋友”按钮点击状态及自定义分享内容接口
  onMenuShareFriends: (params: any, successHandler: any = null): void => {
    // 需在用户可能点击分享按钮前就先调用
    wx.ready((): void => {
      wx.onMenuShareAppMessage({
        title: params.title || '', // 分享标题
        desc: params.desc || '', // 分享描述
        link: params.link || '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: params.imgUrl || '', // 分享图标
        success: (): void => {
          // 用户点击了分享后执行的回调函数
          if (successHandler && typeof successHandler == 'function') {
            successHandler();
          }
        },
        cancel: (): void => {
          // 用户点击取消时的回调函数，仅部分有用户取消操作的api才会用到。
        },
      });
    });
  },
  // 获取“分享到QQ”按钮点击状态及自定义分享内容接口
  onMenuShareQQFriends: (params: any, successHandler: any = null): void => {
    // 需在用户可能点击分享按钮前就先调用
    wx.ready((): void => {
      wx.onMenuShareQQ({
        title: params.title || '', // 分享标题
        desc: params.desc || '', // 分享描述
        link: params.link || '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: params.imgUrl || '', // 分享图标
        success: (): void => {
          // 用户点击了分享后执行的回调函数
          if (successHandler && typeof successHandler == 'function') {
            successHandler();
          }
        },
        cancel: (): void => {
          // 用户点击取消时的回调函数，仅部分有用户取消操作的api才会用到。
        },
      });
    });
  },
  // 获取“分享到腾讯微博”按钮点击状态及自定义分享内容接口
  onMenuShareTxWeibo: (params: any, successHandler: any = null): void => {
    // 需在用户可能点击分享按钮前就先调用
    wx.ready((): void => {
      wx.onMenuShareWeibo({
        title: params.title || '', // 分享标题
        desc: params.desc || '', // 分享描述
        link: params.link || '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: params.imgUrl || '', // 分享图标
        success: (): void => {
          // 用户点击了分享后执行的回调函数
          if (successHandler && typeof successHandler == 'function') {
            successHandler();
          }
        },
        cancel: (): void => {
          // 用户点击取消时的回调函数，仅部分有用户取消操作的api才会用到。
        },
      });
    });
  },
  // 获取“分享到QQ空间”按钮点击状态及自定义分享内容接口
  onMenuShareQQZone: (params: any, successHandler: any = null): void => {
    // 需在用户可能点击分享按钮前就先调用
    wx.ready((): void => {
      wx.onMenuShareQZone({
        title: params.title || '', // 分享标题
        desc: params.desc || '', // 分享描述
        link: params.link || '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: params.imgUrl || '', // 分享图标
        success: (): void => {
          // 用户点击了分享后执行的回调函数
          if (successHandler && typeof successHandler == 'function') {
            successHandler();
          }
        },
        cancel: (): void => {
          // 用户点击取消时的回调函数，仅部分有用户取消操作的api才会用到。
        },
      });
    });
  },
  // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
  shareFriends: (params: any, successHandler: any = null, errorHandler: any = null): void => {
    // 需在用户可能点击分享按钮前就先调用
    wx.ready((): void => {
      wx.updateAppMessageShareData({
        title: params.title || '', // 分享标题
        desc: params.desc || '', // 分享描述
        link: params.link || '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: params.imgUrl || '', // 分享图标
        success: (): void => {
          // 设置成功
          if (successHandler && typeof successHandler == 'function') {
            successHandler();
          }
        },
        cancel: (): void => {
          // 用户点击取消时的回调函数，仅部分有用户取消操作的api才会用到。
          if (errorHandler && typeof errorHandler == 'function') {
            errorHandler();
          }
        },
      });
    });
  },
  // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
  shareFriendsSpace: (params: any, successHandler: any = null, errorHandler: any = null): void => {
    // 需在用户可能点击分享按钮前就先调用
    wx.ready((): void => {
      wx.updateTimelineShareData({
        title: params.title || '', // 分享标题
        link: params.link || '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: params.imgUrl || '', // 分享图标
        success: (): void => {
          // 设置成功
          if (successHandler && typeof successHandler == 'function') {
            successHandler();
          }
        },
        cancel: (): void => {
          // 用户点击取消时的回调函数，仅部分有用户取消操作的api才会用到。
          if (errorHandler && typeof errorHandler == 'function') {
            errorHandler();
          }
        },
      });
    });
  },
  // // 拍照或从手机相册中选图接口
  // chooseImage: (params: any, successHandler: any = null): void => {
  //   wx.chooseImage({
  //     count: params.count || 9, // 默认9
  //     sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
  //     sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
  //     success: (res: any): void => {
  //       const localIds: any[] = res.localIds || []; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
  //       if (successHandler && typeof successHandler == "function") successHandler();
  //     },
  //     cancel: (): void => {
  //     },
  //   });
  // },
  // // 预览图片接口
  // previewImage: (params: any): void => {
  //   wx.previewImage({
  //     current: params.current || '', // 当前显示图片的http链接
  //     urls: params.urls || [] // 需要预览的图片http链接列表
  //   });
  // },
  // // 上传图片接口
  // // 备注：上传图片有效期3天，可用微信多媒体接口下载图片到自己的服务器，此处获得的 serverId 即 media_id。
  // uploadImage: (params: any, successHandler: any = null): void => {
  //   wx.uploadImage({
  //     localId: params.localId || '', // 需要上传的图片的本地ID，由chooseImage接口获得
  //     isShowProgressTips: params.isShowProgressTips || 1, // 默认为1，显示进度提示
  //     success: (res: any): void => {
  //       const serverId: any = res.serverId; // 返回图片的服务器端ID
  //       if (successHandler && typeof successHandler == "function") successHandler(serverId);
  //     }
  //   });
  // },
  // 获取网络状态接口
  getNetworkType: (successHandler: any = null): void => {
    wx.ready((): void => {
      wx.getNetworkType({
        success: (res: any): void => {
          const networkType: string = res.networkType; // 返回网络类型2g，3g，4g，wifi
          if (successHandler && typeof successHandler == 'function') {
            successHandler(networkType);
          }
        },
      });
    });
  },
  // 关闭当前网页窗口接口
  windowClose: (): void => {
    wx.ready((): void => {
      wx.closeWindow();
    });
  },
  // 微信卡券
  // 微信卡券接口中使用的签名凭证api_ticket，与步骤三中config使用的签名凭证jsapi_ticket不同，开发者在调用微信卡券JS-SDK的过程中需依次完成两次不同的签名，并确保凭证的缓存。
  getCardList: (params: any, successHandler: any = null): void => {
    wx.ready((): void => {
      wx.chooseCard({
        shopId: params.shopId || '', // 门店Id，非必填
        cardType: params.cardType || '', // 卡券类型，非必填
        cardId: params.cardId || '', // 卡券Id，非必填
        timestamp: params.timestamp || 0, // 卡券签名时间戳
        nonceStr: params.nonceStr || '', // 卡券签名随机串
        signType: params.signType || 'SHA1', // 签名方式，默认'SHA1'
        cardSign: params.cardSign || '', // 卡券签名
        success: (res: any): void => {
          const cardList: any[] = res.cardList || []; // 用户选中的卡券列表信息
          if (successHandler && typeof successHandler == 'function') {
            successHandler(cardList);
          }
        },
      });
    });
  },
  // 批量添加卡券
  addCard: (params: any, successHandler: any = null): void => {
    wx.ready((): void => {
      // wx.addCard({
      //   // 需要添加的卡券列表
      //   cardList: [{
      //     cardId: '',
      //     cardExt: ''
      //   }],
      //   success: (res: any): void => {
      //     const cardList = res.cardList || []; // 添加的卡券列表信息
      //   },
      // });
    });
  },
  // 查看微信卡包中的卡券接口
  openCard: (params: any, successHandler: any = null): void => {
    wx.ready((): void => {
      wx.openCard({
        cardList: params.cardList || [],
        //   [{
        //   cardId: '',
        //   code: ''
        // }]// 需要打开的卡券列表
      });
    });
  },
  // 发起一个微信支付请求
  pay: (params: any, successHandler: any = null): void => {
    wx.ready((): void => {
      wx.chooseWXPay({
        // @ts-ignore
        appId: params.appId || '',
        timestamp: params.timestamp || 0, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: params.nonceStr || '', // 支付签名随机串，不长于 32 位
        package: params.package || '', // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: params.signType || 'MD5', // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: params.paySign || '', // 支付签名
        success: (res: any): void => {
          // 支付成功后的回调函数
          if (successHandler && typeof successHandler == 'function') {
            successHandler(res);
          }
        },
      });
    });
  },
  // addCard:(params:any,successHandler:any=null):void=>{wx.ready(():void=>{});},
  // addCard:(params:any,successHandler:any=null):void=>{wx.ready(():void=>{});},
  // 判断是否微信环境
  isWeiXin: (): boolean => {
    const ua: any = navigator.userAgent.toLowerCase()
    // if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    //   return true;
    // } else {
    //   return false;
    // }
    return (ua.match(/MicroMessenger/i) == 'micromessenger');
  },
  // 判断当前微信版本号是否支持
  isVersionToOpenTag: (): boolean => {
    let client: boolean = false; // 当前版本号是否支持 (默认不支持)
    const wxInfo: any = navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i); // 微信浏览器信息
    console.log(wxInfo)
    if (wxInfo != null) {
      // 微信版本号 wxInfo[1] = "7.0.18.1740" (示例)
      // 进行split转成数组进行判断 [7,0,18,1740] (示例)
      let version: any = wxInfo[1].split(".").splice(0, 3).join('');
      // 判断版本在7.0.12及以上的版本
      if (version.length < 4) version += '0';
      if (Number(version) >= 7012) client = true;
      // if (version[0] >= 7) {
      //   if (version[1] >= 0) {
      //     if (version[2] >= 12) {
      //       client = true; // 当前版本支持
      //     }
      //   }
      // }
    }
    return client;
  },
};

export default wechat;

<template>
  <div>
    <f7-block-title v-if="title!=''&&list&&list.length>0">
      {{ title }}
    </f7-block-title>
    <f7-list media-list style="margin-top:0;padding-bottom: 24px;">
      <f7-list-item
          v-for="item in list" :key="item.id"
          @click="tipAlbumPage(item)"
      >
        <template #media v-if="isMedia">
          <div class="album-image-65 image-tblr-center" :style="{'backgroundImage':((item.hasOwnProperty('cover')&&item.cover.length>0&&(item.cover[0].thumb!=null||item.cover[0].url!=null))?'url(\''+(item.cover[0].thumb||item.cover[0].url)+'\')':'unset'),
      'filter':((item.hasOwnProperty('cover')&&item.cover.length>0&&(item.cover[0].thumb!=null||item.cover[0].url!=null))?'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\''+(item.cover[0].thumb||item.cover[0].url)+'\',sizingMethod=scale)':'unset'),
      '-ms-filter':((item.hasOwnProperty('cover')&&item.cover.length>0&&(item.cover[0].thumb!=null||item.cover[0].url!=null))?'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\''+(item.cover[0].thumb||item.cover[0].url)+'\',sizingMethod=scale)':'unset')}"
          >
          </div>
        </template>
        <template #title>
          {{item.title}}
          <span v-if="item.hasOwnProperty('episodes_number')"
                style="font-size:12px;color: #999999;position:relative;top:-2px;">
            共{{item.episodes_number||0}}集
          </span>
        </template>
        <template #subtitle>
          <div class="history-item-subtitle">
            &nbsp;
            <span class="history-delete-icon"
                  :class="item.is_soldout == true?'is_soldout':''"
                  @click.prevent.stop="sureDeleteHistoryItem(item)">
            <f7-icon f7="trash" size="16"></f7-icon>
          </span>
          </div>
        </template>
        <template #text>
          <div class="history-item-text">
            <p style="margin: 0px;font-size:12px;" class="text-ellipsis-1">
              <f7-icon f7="play_fill" color="orange" size="16"
                       style="position:relative;top:-2px;"></f7-icon>&nbsp;继续听&nbsp;
              {{item&&item.audio&&item.audio.title?item.audio.title:''}}
            </p>
            <!--<span class="history-progress">已播{{item&&item.audio&&item.audio.currentTime&&item.audio.duration?(parseFloat(item.audio.currentTime/item.audio.duration).toFixed(2)*100):0}}%</span>-->
            <span class="history-progress">已播{{item&&item.audio&&item.audio.currentTime&&item.audio.duration?(Math.round((item.audio.currentTime/item.audio.duration)*100)):0}}%</span>
          </div>
        </template>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script lang="ts">
  import {Options, Vue} from "vue-class-component";
  import {Prop} from "vue-property-decorator";
  import StorageUtils from "@/js/utils/storage.ts";
  import Utils from "@/js/utils/utils.ts";
  import Http from "@/js/utils/http.ts";

  @Options({})
  export default class HistoryList extends Vue {
    // 父组件传值接收
    @Prop(String) title!: string;
    @Prop(Boolean) isMedia!: boolean;
    @Prop(Array) list!: object[];
    @Prop(Object) userInfo!: any;

    // 子组件的data
    name: string = 'historyList';

    created(): void {
    }

    tipAlbumPage(item: any): void {
      if (item.type == 'sold_out') {
        let contactDialog = window.f7.dialog.confirm('该专辑已下架，是否删除该条历史记录？', (): void => {
          this.deleteHistoryItem(item);
        }, (): void => {
        });
        Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
          opened: true,
          dialog: contactDialog,
        });
      } else {
        // 收费的且不是试听的 没买 且不是会员了
        if (item.audio && item.audio.is_free == false && !item.is_buy && !(this.userInfo.member_code && this.userInfo.is_expire == 0)) {
          let contactDialog = window.f7.dialog.confirm('您的vip会员已到期，待您续费或购买专辑后，方能继续收听', (): void => {
            const data: any = {
              id: (item.apid || -1),
              title: item.title || "",
              from: 'home',
            };
            StorageUtils.updateByModule('album.query', data);
            Utils.load(this, '/album');
          }, (): void => {
          });
          Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
            opened: true,
            dialog: contactDialog,
          });
        } else {
          const data: any = {
            from: 'history',
            is_buy: item.is_buy,
            is_member: this.userInfo.member_code != null,
            startPlayTime: item.audio.currentTime ? parseFloat(item.audio.currentTime) : 0,
            album: {
              id: item.apid || -1,
              title: item.title || '',
              cover: item.cover || [],
              is_free: item.is_free || false,
              is_cycle: item.is_cycle || false,
              is_buy: item.is_buy,
              money: item.money,
              pid: item.pid || 0,
              type: item.type || '',
              subtitle: item.subtitle || '',
            },
            audio: item.audio,
            id: item.audio.id || -1,
            title: item.audio.title || "",
            ind: item.index - 1,
          };
          StorageUtils.updateByModule('album.play', data);

          StorageUtils.updateByModule('audioDom.audioPlaying', true)
          Utils.fireEvent(Utils.kEvents.changeAlbumPlayer, {
            isFixed: false,
            album: data.album,
            audio: item.audio,
            playData: data,
          });
          Utils.fireEvent(Utils.kEvents.updatePopupShow, true);
        }
      }
    }

    sureDeleteHistoryItem(item: any): void {
      if (item.is_soldout == true) {
        window.f7.toast.create({
          text: '专辑已下架，删除后，您将无法收听了哦~~~',
          closeTimeout: 2000,
          position: "center",
        }).open();
        return;
      }

      let contactDialog = window.f7.dialog.confirm('确定删除该条历史记录？', (): void => {
        this.deleteHistoryItem(item);
      }, (): void => {
      });
      Utils.fireEvent(Utils.kEvents.updateAlertIsShow, {
        opened: true,
        dialog: contactDialog,
      });
    }

    deleteHistoryItem(item: any): void {
      if (!item || !item.hasOwnProperty('id')) return;
      const data: any = {
        ids: [item.id]
      };
      const successDelete: any = (res: any): void => {
        this.$emit('updateHistoryList', true);
      };
      const params: any = {
        url: Http.kInterfacePaths.history.del,
        data: data,
        warn_info: '删除历史记录失败！请稍后尝试',
        success: successDelete,
      };
      Http.ajax(this, params);
    }
  }
</script>

<style scoped>

</style>

<template>
  <div>
    <f7-row class="home-album-recommend-list vip-gift-list"
            v-if="gifts.length>0">
      <f7-col width="50" v-for="item in gifts"
              :key="item.id" @click="tipClickGoods(item)"
              :class="{'gray':(type=='gifts'&&item.hasOwnProperty('receive')&&item.receive==true) ||
              (type=='goods'&&item.hasOwnProperty('stock')&&item.stock<=0) ||
              (type=='gifts'&&item.hasOwnProperty('stock')&&item.stock<=0)}">
        <!--class="gray">-->
        <!--:class="{'gray':(item.hasOwnProperty('receive')&&item.receive&&item.receive==2)}">-->
        <img :src="receiveIconUrl" alt="已领取" class="gray-receive-img"
             v-if="(type=='gifts'&&item.hasOwnProperty('receive')&&item.receive==true)">
        <img :src="qiangguangIconUrl" alt="已抢光" class="gray-receive-img"
             v-if="(type=='gifts'&&item.hasOwnProperty('stock')&&item.stock<=0&&item.hasOwnProperty('receive')&&item.receive!=true)">
        <img :src="shouqingIconUrl" alt="已售罄" class="gray-receive-img"
             v-if="(type=='goods'&&item.hasOwnProperty('stock')&&item.stock<=0)">
        <div class="home-album-item-image image-tblr-center"
             :style="{'backgroundImage':((item.cover&&item.cover.length>0)?'url(\''+(item.cover[0].url)+'\')':'unset'),
             'filter':((item.cover&&item.cover.length>0)?'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\''+(item.cover[0].url)+'\',sizingMethod=scale)':'unset'),
             '-ms-filter':((item.cover&&item.cover.length>0)?'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\''+(item.cover[0].url)+'\',sizingMethod=scale)':'unset')}"
        ></div>
        <h4 class="text-ellipsis-2">{{item.name||''}}</h4>
        <p class="vip-gift-description">
          <span>{{item.price?'￥'+item.price:'免费'}}</span>
          <f7-link>推广</f7-link>
        </p>
      </f7-col>
      <f7-col width="50" v-for="(el,ind) in (gifts.length%2)"
              :key="'goods_ind_'+ind"></f7-col>
    </f7-row>
  </div>
</template>

<script lang="ts">
  import {Options, Vue} from "vue-class-component";
  import {Prop} from "vue-property-decorator";

  @Options({})
  export default class GoodsList extends Vue {
    @Prop(Array) gifts!: any[];
    @Prop(String) type!: string;// gifts goods
    name: string = "goods-list";

    receiveIconUrl: any = require('@/assets/img/icon_gift_receive.png').default;
    shouqingIconUrl: any = require('@/assets/img/icon_gift_shouqing.png').default;
    qiangguangIconUrl: any = require('@/assets/img/icon_gift_qiangguang.png').default;

    tipClickGoods(item: any): void {
      if (item.hasOwnProperty('receive') && item.receive == true) {
        window.f7.toast.create({
          text: '您已经领取该礼物了哦！',
          closeTimeout: 2000,
          position: "center",
        }).open();
      } else {
        this.$emit('clickGoodsDetail', item);
      }
    }

  }
</script>

<style scoped>

</style>


  import {Options, Vue} from "vue-class-component";
  import {Prop, Watch} from "vue-property-decorator";
  import BannerList from "@/components/banner-list.vue";

  @Options({
    components: {
      BannerList,
    },
  })
  export default class AlbumHeader extends Vue {
    name: string = "album-header";
    @Prop(Object) albumInfo!: any; // 专辑信息
    @Prop(String) currentTab!: string; // 当前激活的tab
    @Prop(Object) introduceImages!: any[]; // 介绍图列表
    @Prop(String) title!: string; // 专辑标题
    @Prop(String) subtitle!: string; // 专辑副标题
    @Prop(String) updatedData!: string; // 专辑更新期数/集数
    @Prop(String) subscription!: string; // 订阅数
    @Prop(Number) totalRow!: number; // 共多少集
    @Prop(Boolean) isShowDiversity!: boolean; // 是否显示分集展示
    @Prop({default: false}) isLimitedOffer!: boolean; // 是否限时特惠专辑
    @Prop({default: 0}) limitedOfferPrice!: number; // 限时优惠价格
    @Prop({
      default: {
        // day: '',
        // hour: '',
        // minute: '',
        // second: ''
      }
    }) limitedOfferInfo!: any; // 限时优惠倒计时信息
    // @Prop(Boolean) isSortReverse!: boolean; // 是否倒序排列

    changeTabActive(active: string): void {
      this.$emit('updateTabActive', active);
    }

    changeDiversityDisplay(): void {
      if (this.currentTab == 'catalog') {
        this.$emit('updateDiversityShow', !this.isShowDiversity);
      }
    }

    // changeDiversitySort(): void {
    //   if (this.currentTab == 'catalog') {
    //     this.$emit('updateDiversitySort', !this.isSortReverse);
    //   }
    // }
  }
